import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { camelCase } from 'tiny-compose-fns';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Flex } from 'system';
import { getRichText, notEmpty } from 'helpers';
import { propPairsEq } from 'src/helpers';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
const colorSchemes = {
  white: {
    bg: 'white'
  },
  lightGray: {
    bg: 'gray.90'
  }
};
export const PageBodyText = _ref => {
  let {
    backgroundColor,
    leftColumnText,
    rightColumnText
  } = _ref,
      props = _objectWithoutProperties(_ref, ["backgroundColor", "leftColumnText", "rightColumnText"]);

  const color = colorSchemes[backgroundColor];
  return React.createElement(BoundedBox, Object.assign({
    maxWidth: ARTICLE_MAX_WIDTH,
    as: "section",
    bg: color.bg
  }, props), React.createElement(Flex, {
    flexDirection: ['column', 'row']
  }, leftColumnText && React.createElement(HTMLContent, {
    html: leftColumnText,
    mrScale: [0, 'm'],
    mbScale: ['m', 0],
    flex: "1 1 0px",
    boxStyle: "lastNoMargin"
  }), rightColumnText && React.createElement(HTMLContent, {
    html: rightColumnText,
    flex: "1 1 0px"
  })));
};

PageBodyText.mapDataToProps = ({
  previousContext,
  nextContext,
  context,
  previousType,
  data
}) => {
  var _data$primary, _data$primary2, _data$primary3;

  return {
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    previousIsHeader: previousType === 'PageBodyHeader',
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    backgroundColor: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.background_color),
    rightColumnText: getRichText(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.right_column_text),
    leftColumnText: getRichText(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.left_column_text)
  };
};

PageBodyText.mapDataToContext = ({
  data
}) => {
  var _data$primary4;

  const backgroundColor = notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.background_color));
  const colors = colorSchemes[backgroundColor];
  return {
    backgroundColor: colors.bg,
    isArticleType: true,
    headerLogoColor: 'white',
    headerBg: 'teal.75'
  };
};

export const fragment = "3610469439";