import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { motion } from 'framer-motion';
import { getRichText, notEmpty } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { useSlideUp } from 'src/hooks/useSlideUp';
import { Box, Flex, AspectRatio, Image, ImageContainer } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Card } from 'src/components/Card';
import { HTMLContent } from 'src/components/HTMLContent';
const colorSchemes = {
  white: {
    bg: 'white'
  },
  lightGray: {
    bg: 'gray.90'
  }
};
export const PageBodyImageCallout = _ref => {
  let {
    buttonHref,
    buttonTarget,
    buttonText,
    children,
    imageSide,
    colorScheme = 'lightGray',
    imageAlt,
    imageFluid,
    imageURL,
    textHTML
  } = _ref,
      props = _objectWithoutProperties(_ref, ["buttonHref", "buttonTarget", "buttonText", "children", "imageSide", "colorScheme", "imageAlt", "imageFluid", "imageURL", "textHTML"]);

  const [ref, animate] = useSlideUp();
  const isImageOnLeft = imageSide === 'left';
  const colors = colorSchemes === null || colorSchemes === void 0 ? void 0 : colorSchemes[colorScheme];
  return React.createElement(BoundedBox, Object.assign({
    ref: ref,
    as: "section",
    ptScale: ['0', 'xxl'],
    bg: colors.bg
  }, props), React.createElement(Flex, {
    flexDirection: ['column', isImageOnLeft ? 'row' : 'row-reverse'],
    alignItems: [null, 'center']
  }, React.createElement(ImageContainer, {
    width: ['auto', 4 / 6],
    mxScale: ['-l', 0],
    flex: "0 0 auto",
    alignSelf: "stretch",
    bg: "gray.20"
  }, React.createElement(AspectRatio, {
    x: [4, 8],
    y: [3, 5],
    height: "100%"
  }, (imageURL || imageFluid) && React.createElement(Image, {
    src: imageURL,
    fluid: imageFluid,
    alt: imageAlt
  }))), React.createElement(Box, {
    as: motion.div,
    animate: animate,
    mbScale: [0, 'xl'],
    ml: [null, isImageOnLeft ? 'calc(-1 * 100% / 6)' : null],
    mr: [null, isImageOnLeft ? null : 'calc(-1 * 100% / 6)'],
    mtScale: ['-xxl', 'xl'],
    position: "relative",
    width: ['auto', 1 / 2]
  }, React.createElement(Card, {
    buttonHref: buttonHref,
    buttonText: buttonText,
    buttonTarget: buttonTarget,
    innerProps: {
      pbScale: colors.bg === 'white' ? [0, 'xl'] : 'xl'
    }
  }, children || textHTML && React.createElement(HTMLContent, {
    html: textHTML
  })))));
};

PageBodyImageCallout.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$primary2, _data$primary3, _data$primary4, _data$primary5, _data$primary5$button, _data$primary6, _data$primary6$button, _data$primary7, _data$primary7$image, _data$primary8, _data$primary8$image, _data$primary9, _data$primary9$image;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    imageSide: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.image_side),
    colorScheme: notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.color_scheme)),
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.text),
    buttonText: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.button_text,
    buttonHref: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$button = _data$primary5.button_link) === null || _data$primary5$button === void 0 ? void 0 : _data$primary5$button.url,
    buttonTarget: data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : (_data$primary6$button = _data$primary6.button_link) === null || _data$primary6$button === void 0 ? void 0 : _data$primary6$button.target,
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : (_data$primary7$image = _data$primary7.image) === null || _data$primary7$image === void 0 ? void 0 : _data$primary7$image.fluid,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary8 = data.primary) === null || _data$primary8 === void 0 ? void 0 : (_data$primary8$image = _data$primary8.image) === null || _data$primary8$image === void 0 ? void 0 : _data$primary8$image.url,
    imageAlt: notEmpty(data === null || data === void 0 ? void 0 : (_data$primary9 = data.primary) === null || _data$primary9 === void 0 ? void 0 : (_data$primary9$image = _data$primary9.image) === null || _data$primary9$image === void 0 ? void 0 : _data$primary9$image.alt)
  };
};

PageBodyImageCallout.mapDataToContext = ({
  data
}) => {
  var _data$primary10, _colorSchemes$colorSc;

  const colorScheme = notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary10 = data.primary) === null || _data$primary10 === void 0 ? void 0 : _data$primary10.color_scheme));
  return {
    backgroundColor: [Symbol('Not tracked'), ((_colorSchemes$colorSc = colorSchemes[colorScheme]) === null || _colorSchemes$colorSc === void 0 ? void 0 : _colorSchemes$colorSc.bg) || 'gray.90']
  };
};

export const fragment = "950390205";