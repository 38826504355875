import React, { useContext } from 'react';
import { useMediaLayout } from 'use-media';
import { theme as themeBase } from 'system';
import { theme } from 'src/theme';
export const MediaContext = React.createContext();
export const MediaProvider = ({
  children
}) => {
  const breakpoints = theme.breakpoints || themeBase.breakpoints;
  const isLevel1 = useMediaLayout("(min-width: " + breakpoints[0] + ")");
  const isLevel2 = useMediaLayout("(min-width: " + breakpoints[1] + ")");
  const isLevel3 = useMediaLayout("(min-width: " + breakpoints[2] + ")");
  const isLevel4 = useMediaLayout("(min-width: " + breakpoints[3] + ")");
  const value = [!isLevel1 && !isLevel2 && !isLevel3 && !isLevel4, isLevel1 && !isLevel2 && !isLevel3 && !isLevel4, isLevel2 && !isLevel3 && !isLevel4, isLevel3 && !isLevel4, isLevel4].indexOf(true);
  return React.createElement(MediaContext.Provider, {
    value: value
  }, children);
};
export const useMediaIndex = () => useContext(MediaContext);