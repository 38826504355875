import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { linearScale } from 'styled-system-scale';
import { themeGet } from '@styled-system/theme-get';
import { Flex, Text, SVG } from 'system';
import { Anchor } from 'src/components/Anchor';
import { ReactComponent as AssetIconDocumentSVG } from 'src/assets/icon-document.svg';
import { ReactComponent as AssetIconJaliFlakeSVG } from 'src/assets/icon-jali-flake.svg';
import { ReactComponent as AssetIconJobMagnifyingGlassSVG } from 'src/assets/icon-job-magnifying-glass.svg';
import { ReactComponent as AssetIconMediaEyeSVG } from 'src/assets/icon-media-eye.svg';
const icons = {
  document: {
    svg: AssetIconDocumentSVG,
    x: 109,
    y: 131,
    xScale: 0.7
  },
  jaliFlake: {
    svg: AssetIconJaliFlakeSVG,
    x: 133,
    y: 126,
    xScale: 0.86
  },
  jobMagnifyingGlass: {
    svg: AssetIconJobMagnifyingGlassSVG,
    x: 136,
    y: 139,
    xScale: 0.88
  },
  mediaEye: {
    svg: AssetIconMediaEyeSVG,
    x: 154,
    y: 155,
    xScale: 1
  }
};

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "DocumentCard___StyledAnchor",
  componentId: "sc-18xzv6l-0"
})(["&:hover,&:focus{outline:none;.content{background-color:", ";}}"], p => p._css);

export const DocumentCard = _ref => {
  let {
    href,
    target,
    icon: iconName = 'document',
    name,
    description,
    withOutline = false
  } = _ref,
      props = _objectWithoutProperties(_ref, ["href", "target", "icon", "name", "description", "withOutline"]);

  const icon = icons[iconName];
  return React.createElement(ConditionalWrap, {
    condition: href,
    wrap: children => React.createElement(_StyledAnchor, {
      href: href,
      target: target,
      display: "block",
      width: "100%",
      _css: themeGet('colors.gray.95')
    }, children)
  }, React.createElement(Flex, Object.assign({
    className: "content",
    alignItems: "flex-start",
    bg: "white",
    border: withOutline ? '2px solid' : null,
    borderColor: "gray.90",
    borderRadius: ['8px', null, '12px'],
    pScale: "s+",
    transitionProperty: "background-color",
    height: "100%"
  }, props), React.createElement(Flex, {
    width: linearScale('2.5rem', '3.5rem', {
      count: 5
    }),
    height: linearScale('2.5rem', '3.5rem', {
      count: 5
    }),
    alignItems: "center",
    justifyContent: "center",
    flex: "0 0 auto",
    mrScale: "t+"
  }, React.createElement(SVG, {
    svg: icon.svg,
    x: icon.x,
    y: icon.y,
    width: icon.xScale * 100 + "%",
    display: "flex"
  })), React.createElement(Text, {
    fontSizeScale: "b"
  }, React.createElement(Text, {
    as: "p",
    fontWeight: "bold"
  }, name), React.createElement(Text, {
    as: "p",
    flex: "1 1 auto"
  }, description))));
};