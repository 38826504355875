import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { map } from 'tiny-compose-fns';
import { SlidingPhotoCards } from 'src/components/SlidingPhotoCards';
export const PageBodyFeaturedObjects = _ref => {
  let {
    objects
  } = _ref,
      props = _objectWithoutProperties(_ref, ["objects"]);

  return React.createElement(SlidingPhotoCards, Object.assign({
    items: objects,
    withSubheading: false
  }, props));
};

PageBodyFeaturedObjects.mapDataToProps = ({
  data,
  previousType,
  previousContext,
  nextContext
}) => {
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    objects: map(item => {
      var _item$collection_obje, _item$collection_obje2, _doc$title, _doc$description, _doc$image, _doc$image2, _doc$image3;

      const doc = item === null || item === void 0 ? void 0 : (_item$collection_obje = item.collection_object.document) === null || _item$collection_obje === void 0 ? void 0 : _item$collection_obje.data;
      return {
        uid: item === null || item === void 0 ? void 0 : (_item$collection_obje2 = item.collection_object) === null || _item$collection_obje2 === void 0 ? void 0 : _item$collection_obje2.uid,
        objectNumber: doc === null || doc === void 0 ? void 0 : doc.object_number,
        title: doc === null || doc === void 0 ? void 0 : (_doc$title = doc.title) === null || _doc$title === void 0 ? void 0 : _doc$title.text,
        description: doc === null || doc === void 0 ? void 0 : (_doc$description = doc.description) === null || _doc$description === void 0 ? void 0 : _doc$description.text,
        metadata: doc === null || doc === void 0 ? void 0 : doc.metadata,
        linkURL: doc === null || doc === void 0 ? void 0 : doc.emuseum_link.url,
        linkTarget: doc === null || doc === void 0 ? void 0 : doc.emuseum_link.target,
        imageURL: doc === null || doc === void 0 ? void 0 : (_doc$image = doc.image) === null || _doc$image === void 0 ? void 0 : _doc$image.url,
        imageAlt: doc === null || doc === void 0 ? void 0 : (_doc$image2 = doc.image) === null || _doc$image2 === void 0 ? void 0 : _doc$image2.alt,
        imageFluid: doc === null || doc === void 0 ? void 0 : (_doc$image3 = doc.image) === null || _doc$image3 === void 0 ? void 0 : _doc$image3.fluid
      };
    }, data === null || data === void 0 ? void 0 : data.items)
  };
};

PageBodyFeaturedObjects.mapDataToContext = () => ({
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});

export const fragment = "1957000250";