import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useMemo, useRef, useState } from 'react';
import { metadataBasename, getPrimaryMetadataType } from 'helpers';
import { camelCase, chunk, compose, filter, isEmpty, map, startCase, sortByDesc } from 'tiny-compose-fns';
import dayjs from 'dayjs';
import { getPrimaryMetadata, propPairsEq } from 'src/helpers';
import { useExperiencesData } from 'src/hooks/useExperiencesData';
import { usePagination } from 'src/hooks/usePagination';
import { Box, Text, Flex, Grid } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { GridVerticalRhythm } from 'src/components/GridVerticalRhythm';
import { Heading } from 'src/components/Heading';
import { PhotoCard } from 'src/components/PhotoCard';
import { SimplePhotoCard } from 'src/components/SimplePhotoCard';
import { Tabs } from 'src/components/Tabs';
import { Pagination } from 'src/components/Pagination';
const CARD_POSITIONS = {
  normal: 'bottomRightXOverhang',
  tall: 'bottomRightYOverhangWide'
};
const TODAY = Date.now();
const EXPERIENCES_PER_PAGE = 3;
const EXPERIENCES_PER_PAST_PAGE = 6;
const ExperienceCardWithLotsOfLogic = React.forwardRef(({
  experience: doc,
  activeKey,
  as
}, ref) => {
  var _metadata$primary, _metadata$primary2, _metadata$primary3, _doc$data, _doc$data$title, _metadata$primary4, _metadata$primary5, _metadata$primary5$fe, _metadata$primary6, _metadata$primary6$fe, _metadata$primary7, _metadata$primary7$fe, _doc$data2, _doc$data2$title, _metadata$primary8, _metadata$primary9, _metadata$primary9$fe, _metadata$primary10, _metadata$primary10$f, _metadata$primary11, _metadata$primary11$f;

  const metadata = getPrimaryMetadata(doc);
  const type = compose(startCase, metadataBasename, getPrimaryMetadataType)(doc);
  const imageSize = camelCase(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : _metadata$primary.featured_image_size);
  const cardPosition = CARD_POSITIONS[imageSize];
  const dates = [metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.start_date, metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : _metadata$primary3.end_date].filter(Boolean).join(' - ');
  return React.createElement(Box, {
    key: doc === null || doc === void 0 ? void 0 : doc.uid,
    ref: ref,
    pbScale: "m",
    as: as
  }, activeKey === 'past' ? React.createElement(SimplePhotoCard, {
    direction: "row",
    href: doc === null || doc === void 0 ? void 0 : doc.url,
    heading: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : (_doc$data$title = _doc$data.title) === null || _doc$data$title === void 0 ? void 0 : _doc$data$title.text,
    description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : _metadata$primary4.description,
    detail: dates,
    imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$fe = _metadata$primary5.featured_image) === null || _metadata$primary5$fe === void 0 ? void 0 : _metadata$primary5$fe.url,
    imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$fe = _metadata$primary6.featured_image) === null || _metadata$primary6$fe === void 0 ? void 0 : _metadata$primary6$fe.fluid,
    imageAlt: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary7 = metadata.primary) === null || _metadata$primary7 === void 0 ? void 0 : (_metadata$primary7$fe = _metadata$primary7.featured_image) === null || _metadata$primary7$fe === void 0 ? void 0 : _metadata$primary7$fe.alt
  }) : React.createElement(PhotoCard, {
    href: doc === null || doc === void 0 ? void 0 : doc.url,
    subheading: type,
    heading: doc === null || doc === void 0 ? void 0 : (_doc$data2 = doc.data) === null || _doc$data2 === void 0 ? void 0 : (_doc$data2$title = _doc$data2.title) === null || _doc$data2$title === void 0 ? void 0 : _doc$data2$title.text,
    description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary8 = metadata.primary) === null || _metadata$primary8 === void 0 ? void 0 : _metadata$primary8.description,
    detail: dates,
    imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary9 = metadata.primary) === null || _metadata$primary9 === void 0 ? void 0 : (_metadata$primary9$fe = _metadata$primary9.featured_image) === null || _metadata$primary9$fe === void 0 ? void 0 : _metadata$primary9$fe.url,
    imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary10 = metadata.primary) === null || _metadata$primary10 === void 0 ? void 0 : (_metadata$primary10$f = _metadata$primary10.featured_image) === null || _metadata$primary10$f === void 0 ? void 0 : _metadata$primary10$f.fluid,
    imageAlt: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary11 = metadata.primary) === null || _metadata$primary11 === void 0 ? void 0 : (_metadata$primary11$f = _metadata$primary11.featured_image) === null || _metadata$primary11$f === void 0 ? void 0 : _metadata$primary11$f.alt,
    cardPosition: cardPosition
  }));
});
export const PageBodyFilterableExperiences = _ref => {
  var _experiencesGrouped$a, _experiencesGrouped$a2, _experiencesGrouped$a3;

  let {
    type
  } = _ref,
      props = _objectWithoutProperties(_ref, ["type"]);

  const [activeKey, setActiveKey] = useState(type === 'event' ? 'upcoming' : 'current');
  const sectionRef = useRef();
  const [page, setPage] = usePagination(0, sectionRef);
  const experiences = useExperiencesData(type);
  const displayKey = startCase(activeKey);
  const displayType = type + 's';
  const experiencesWithDates = useMemo(() => compose(sortByDesc('startDate'), map(experience => {
    var _metadata$primary12, _metadata$primary13, _metadata$primary14, _metadata$primary15, _metadata$primary16;

    const metadata = getPrimaryMetadata(experience); // If start date present: use it.
    // If start date not present: negative Infinity.

    const startDate = (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary12 = metadata.primary) === null || _metadata$primary12 === void 0 ? void 0 : _metadata$primary12.start_date) ? dayjs(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary13 = metadata.primary) === null || _metadata$primary13 === void 0 ? void 0 : _metadata$primary13.start_date).valueOf() : -Infinity; // If end date present: use it.
    // If end date not present: use the start date if present, Infinity otherwise.

    const endDate = (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary14 = metadata.primary) === null || _metadata$primary14 === void 0 ? void 0 : _metadata$primary14.end_date) ? dayjs(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary15 = metadata.primary) === null || _metadata$primary15 === void 0 ? void 0 : _metadata$primary15.end_date).valueOf() : (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary16 = metadata.primary) === null || _metadata$primary16 === void 0 ? void 0 : _metadata$primary16.start_date) ? startDate : Infinity;
    return Object.assign(experience, {
      startDate,
      endDate
    });
  }))(experiences), [experiences]);
  const experiencesGrouped = useMemo(() => ({
    current: compose(chunk(EXPERIENCES_PER_PAGE), filter(e => (e === null || e === void 0 ? void 0 : e.startDate) <= TODAY && (e === null || e === void 0 ? void 0 : e.startDate) !== -Infinity && (e === null || e === void 0 ? void 0 : e.endDate) >= TODAY && (e === null || e === void 0 ? void 0 : e.endDate) !== Infinity))(experiencesWithDates),
    upcoming: compose(chunk(EXPERIENCES_PER_PAGE), filter(e => (e === null || e === void 0 ? void 0 : e.startDate) >= TODAY && (e === null || e === void 0 ? void 0 : e.startDate) !== -Infinity && (e === null || e === void 0 ? void 0 : e.endDate) >= TODAY && (e === null || e === void 0 ? void 0 : e.endDate) !== Infinity))(experiencesWithDates),
    permanent: compose(chunk(EXPERIENCES_PER_PAGE), filter(e => (e === null || e === void 0 ? void 0 : e.startDate) === -Infinity && (e === null || e === void 0 ? void 0 : e.endDate) === Infinity))(experiencesWithDates),
    past: compose(chunk(EXPERIENCES_PER_PAST_PAGE), filter(e => (e === null || e === void 0 ? void 0 : e.endDate) <= TODAY))(experiencesWithDates)
  }), [experiencesWithDates]);
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    ref: sectionRef
  }, props), React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(Tabs, {
    label: "View:",
    activeKey: activeKey,
    onTabClick: key => {
      setActiveKey(key);
      setPage(0);
    },
    maxWidth: "large+",
    alignSelf: [null, 'center'],
    display: "inline-grid",
    mbScale: "xl"
  }, type !== 'event' && React.createElement(Tabs.Tab, {
    key: "current"
  }, "Current"), React.createElement(Tabs.Tab, {
    key: "upcoming"
  }, "Upcoming"), type === 'exhibition' && React.createElement(Tabs.Tab, {
    key: "permanent"
  }, "Permanent"), React.createElement(Tabs.Tab, {
    key: "past"
  }, "Past")), React.createElement(Heading, {
    fontSizeScale: "xl",
    textAlign: [null, 'center'],
    mbScale: "l",
    color: "gray.20"
  }, displayKey, " ", displayType), isEmpty(experiencesGrouped === null || experiencesGrouped === void 0 ? void 0 : experiencesGrouped[activeKey]) ? React.createElement(Text, {
    fontSizeScale: "m",
    textAlign: [null, 'center']
  }, "No ", activeKey, " ", displayType, " at this time.") : React.createElement(React.Fragment, null, activeKey === 'past' ? React.createElement(Grid, {
    key: activeKey,
    as: "ul",
    gridTemplateColumns: [null, 'repeat(2, 1fr)'],
    gridColumnGapScale: "l"
  }, map(experience => React.createElement(ExperienceCardWithLotsOfLogic, {
    as: "li",
    key: experience === null || experience === void 0 ? void 0 : experience.uid,
    experience: experience,
    activeKey: activeKey
  }), experiencesGrouped === null || experiencesGrouped === void 0 ? void 0 : (_experiencesGrouped$a = experiencesGrouped[activeKey]) === null || _experiencesGrouped$a === void 0 ? void 0 : _experiencesGrouped$a[page])) : React.createElement(GridVerticalRhythm, {
    key: activeKey,
    as: "ul",
    gridTemplateColumns: [null, 'repeat(2, 1fr)'],
    gridColumnGapScale: "l",
    childrenAs: "li"
  }, map(experience => React.createElement(ExperienceCardWithLotsOfLogic, {
    key: experience === null || experience === void 0 ? void 0 : experience.uid,
    experience: experience,
    activeKey: activeKey
  }), experiencesGrouped === null || experiencesGrouped === void 0 ? void 0 : (_experiencesGrouped$a2 = experiencesGrouped[activeKey]) === null || _experiencesGrouped$a2 === void 0 ? void 0 : _experiencesGrouped$a2[page])), React.createElement(Pagination, {
    total: experiencesGrouped === null || experiencesGrouped === void 0 ? void 0 : (_experiencesGrouped$a3 = experiencesGrouped[activeKey]) === null || _experiencesGrouped$a3 === void 0 ? void 0 : _experiencesGrouped$a3.length,
    current: page,
    handleSet: setPage
  }))));
};

PageBodyFilterableExperiences.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext,
  index
}) => {
  var _data$primary;

  return {
    isFirst: index === 1,
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    type: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.experience_type)
  };
};

PageBodyFilterableExperiences.mapDataToContext = () => ({
  backgroundColor: 'white'
});

export const fragment = "3296681875";