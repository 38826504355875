import React from 'react';
export class ErrorBoundary extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  render() {
    const {
      children,
      fallback
    } = this.props;
    if (this.state.hasError) return fallback;
    return children;
  }

}