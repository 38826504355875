import { useState, useEffect, useCallback, useRef } from 'react'
import { globalHistory } from '@reach/router'

/**
 * Returns the current location from @reach/router.
 */

export const useLocation = () => {
  const [state, setState] = useState(globalHistory.location)

  useEffect(() => {
    const removeListener = globalHistory.listen(params => {
      const { location } = params
      setState(location)
    })

    return () => [removeListener()]
  }, [])

  return state
}

/**
 * Returns an object representing the current scroll direction with
 * a customizeable threshold.
 */

const UP = -1
const STATIC = 0
const DOWN = 1
const IS_BROWSER = typeof window !== 'undefined'

const _determineDirection = (current, previous) => {
  if (current === previous) return STATIC

  return current > previous ? DOWN : UP
}

export const useScrollDirection = (threshold = 0) => {
  const prevY = useRef(IS_BROWSER ? window.scrollY : 0)
  const currY = useRef(IS_BROWSER ? window.scrollY : 0)
  const [direction, setDirection] = useState(STATIC)

  const handleScroll = () => {
    if (Math.abs(window.scrollY - currY.current) < threshold) return

    prevY.current = currY.current
    currY.current = window.scrollY

    const scrollDirection = _determineDirection(currY.current, prevY.current)

    if (scrollDirection !== STATIC && scrollDirection !== direction)
      setDirection(scrollDirection)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () =>
      window.removeEventListener('scroll', handleScroll, { passive: true })
  }, [])

  return {
    direction,
    isScrollingDown: direction === DOWN,
    isScrollingUp: direction === UP,
  }
}

/**
 * Allows for runtime loading of an external script. Useful for libraries
 * such as Stripe checkout and google maps.
 */

const _loadDynamicScript = (id, src, callback) => {
  const isLoaded = Boolean(document.getElementById(id))

  if (!isLoaded) {
    const script = document.createElement('script')
    script.src = src
    script.id = id

    document.body.appendChild(script)

    if (callback) script.onload = callback
  }

  if (isLoaded && callback) callback()
}

export const useDynamicScript = async (id, src, callback) => {
  const [isLoaded, setIsLoaded] = useState(false)

  await useEffect(() => {
    if (!isLoaded) {
      _loadDynamicScript(id, src, callback)
      setIsLoaded(true)
    }
  }, [callback, id, isLoaded, src])

  return isLoaded
}

export const useKeyPress = (targetKey, callback) => {
  const keyHandler = useCallback(
    event => {
      if (event.key === targetKey) callback()
    },
    [callback, targetKey],
  )

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)

    return () => void window.removeEventListener('keydown', keyHandler)
  }, [keyHandler])
}

export const useInterval = (callback, interval) => {
  const savedCallback = useRef()
  const isRunning = useRef(true)
  const id = useRef()
  const startTimestamp = useRef()
  const delta = useRef()

  const tick = useCallback(
    timestamp => {
      startTimestamp.current = startTimestamp.current ?? timestamp
      delta.current = timestamp - startTimestamp.current

      if (isRunning.current) {
        if (delta.current >= interval) {
          startTimestamp.current = timestamp
          savedCallback.current(timestamp)
        }
      }

      id.current = requestAnimationFrame(tick)
    },
    [interval],
  )

  const stop = () => {
    isRunning.current = false
    cancelAnimationFrame(id.current)
  }

  const start = () => {
    isRunning.current = true
    startTimestamp.current = null
    id.current = requestAnimationFrame(tick)
  }

  const pause = () => {
    isRunning.current = false
  }

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    id.current = requestAnimationFrame(tick)

    return () => cancelAnimationFrame(id.current)
  }, [interval, tick])

  return {
    start,
    stop,
    pause,
  }
}
