import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, notEmpty } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { Box, Flex, Image } from 'system';
import { HTMLContent } from 'src/components/HTMLContent';
import { Heading } from 'src/components/Heading';
import { BoundedBox } from 'src/components/BoundedBox';
import { BackButton } from 'src/components/BackButton';
import AssetMediaFlake from 'src/assets/media-flake.png';
const colorSchemes = {
  teal: {
    bg: 'teal.70',
    color: 'gray.20'
  },
  darkTeal: {
    bg: 'teal.40',
    color: 'white'
  }
};
export const PageBodySectionHeader = _ref => {
  let {
    heading,
    children,
    textHTML,
    colorScheme = 'teal',
    backHref,
    backTitle
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "children", "textHTML", "colorScheme", "backHref", "backTitle"]);

  const colors = colorSchemes[colorScheme];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: colors === null || colors === void 0 ? void 0 : colors.bg,
    color: colors === null || colors === void 0 ? void 0 : colors.color,
    overflow: "hidden",
    pyScale: "xl",
    "aria-label": heading ? heading : 'Section Header'
  }, props), React.createElement(Flex, {
    flexDirection: ['column', 'row'],
    position: "relative"
  }, React.createElement(Image, {
    src: AssetMediaFlake,
    height: "auto",
    opacity: 0.1,
    position: "absolute",
    top: "50%",
    transform: "translate(-30%, -50%)",
    left: 0,
    width: [1, 3 / 4, 1 / 3],
    ml: [null, null, null, 'calc(-1 * (100vw - 100%) / 2)']
  }), React.createElement(Box, {
    width: [1, 2 / 6],
    mbScale: ['s', 0],
    position: "relative",
    boxStyle: "lastNoMargin"
  }, backHref && React.createElement(BackButton, {
    href: backHref,
    colorScheme: "yellow",
    color: "white",
    mbScale: "t"
  }, "Back", backTitle && " to " + backTitle), heading && React.createElement(Heading, {
    boxStyle: "lastNoMargin",
    color: "white",
    fontSizeScale: "h",
    lineHeight: "solid"
  }, heading)), (children || textHTML) && React.createElement(HTMLContent, {
    children: children,
    html: textHTML,
    fontFamily: "serif",
    fontSizeScale: "l",
    position: "relative",
    width: [1, 4 / 6]
  })));
};

PageBodySectionHeader.mapDataToProps = ({
  data,
  meta,
  previousType,
  previousContext,
  nextContext
}) => {
  var _meta$page, _meta$page$data, _meta$page$data$paren, _data$primary, _data$primary2, _data$primary2$headin, _data$primary3, _parent$data, _parent$data$title;

  const parent = meta === null || meta === void 0 ? void 0 : (_meta$page = meta.page) === null || _meta$page === void 0 ? void 0 : (_meta$page$data = _meta$page.data) === null || _meta$page$data === void 0 ? void 0 : (_meta$page$data$paren = _meta$page$data.parent) === null || _meta$page$data$paren === void 0 ? void 0 : _meta$page$data$paren.document;
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    colorScheme: notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.color_scheme)),
    heading: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$headin = _data$primary2.heading) === null || _data$primary2$headin === void 0 ? void 0 : _data$primary2$headin.text,
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.text),
    backHref: parent === null || parent === void 0 ? void 0 : parent.url,
    backTitle: parent === null || parent === void 0 ? void 0 : (_parent$data = parent.data) === null || _parent$data === void 0 ? void 0 : (_parent$data$title = _parent$data.title) === null || _parent$data$title === void 0 ? void 0 : _parent$data$title.text
  };
};

PageBodySectionHeader.mapDataToContext = () => ({
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});

export const fragment = "1439705433";