import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState, useRef } from 'react';
import { compose, map, max } from 'tiny-compose-fns';
import { motion } from 'framer-motion';
import { themeGet } from '@styled-system/theme-get';
import { useResizeObserver } from '@asyarb/use-resize-observer';
import { Box, Grid, Image, ImageContainer, Text, Flex } from 'system';
import { useMediaIndex } from 'src/hooks/useMediaIndex';
import { OVERHANG_SPACE_POSITIVE, transition } from 'src/constants';
import { Anchor } from 'src/components/Anchor';
import { Button } from 'src/components/Button';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { Subheading } from 'src/components/Subheading';
import { ArrowButton } from 'src/components/ArrowButton';

var _StyledBox = _styled(Box).withConfig({
  displayName: "SlidingPhotoCards___StyledBox",
  componentId: "sc-1quuetn-0"
})(["height:", "px ", ";"], p => p._css, p => p._css2);

var _StyledBox2 = _styled(Box).withConfig({
  displayName: "SlidingPhotoCards___StyledBox2",
  componentId: "sc-1quuetn-1"
})(["pointer-events:", ";"], p => p._css3);

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "SlidingPhotoCards___StyledAnchor",
  componentId: "sc-1quuetn-2"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css4);

export const SlidingPhotoCards = _ref => {
  let {
    items,
    withSubheading = true
  } = _ref,
      props = _objectWithoutProperties(_ref, ["items", "withSubheading"]);

  const [state, setState] = useState({
    index: 0,
    direction: undefined,
    boxContainerHeight: 0,
    transparentBoxHeight: 0
  });
  const sliceRef = useRef();
  const imageRefs = useRef([]);
  const textBoxRefs = useRef([]);
  const isResizing = useRef(false);
  const mediaIndex = useMediaIndex();
  const {
    index,
    direction,
    boxContainerHeight,
    transparentBoxHeight
  } = state;
  const isHydrating = boxContainerHeight === 0 || transparentBoxHeight === 0;

  const increment = () => {
    setState(state => {
      var _boxes$newIndex;

      const boxes = textBoxRefs.current;
      const newIndex = items.length - 1 === index ? 0 : state.index + 1;
      return _objectSpread({}, state, {
        index: newIndex,
        direction: 'right',
        boxContainerHeight: (_boxes$newIndex = boxes[newIndex]) === null || _boxes$newIndex === void 0 ? void 0 : _boxes$newIndex.clientHeight
      });
    });
  };

  const decrement = () => {
    setState(state => {
      var _boxes$newIndex2;

      const boxes = textBoxRefs.current;
      const newIndex = state.index === 0 ? items.length - 1 : state.index - 1;
      return _objectSpread({}, state, {
        index: newIndex,
        direction: 'left',
        boxContainerHeight: (_boxes$newIndex2 = boxes[newIndex]) === null || _boxes$newIndex2 === void 0 ? void 0 : _boxes$newIndex2.clientHeight
      });
    });
  };

  const imageVariants = {
    entered: {
      x: [direction === 'right' ? 150 : direction === 'left' ? -150 : 0, 0],
      opacity: 1,
      transition: {
        delay: 0.6,
        duration: 0.6,
        ease: [0.23, 1, 0.32, 1]
      }
    },
    incrementing: {
      x: -150,
      opacity: 0,
      transition
    },
    decrementing: {
      x: 150,
      opacity: 0,
      transition
    }
  };
  const itemVariants = {
    entered: i => ({
      x: [direction === 'right' ? 50 : direction === 'left' ? -50 : 0, 0],
      opacity: 1,
      zIndex: 1,
      transition: {
        delay: i * 0.1 + 0.6,
        duration: 0.6,
        ease: [0.23, 1, 0.32, 1]
      }
    }),
    incrementing: i => ({
      x: -50,
      opacity: 0,
      transition: _objectSpread({
        delay: i * 0.1
      }, transition)
    }),
    decrementing: i => ({
      x: 50,
      opacity: 0,
      transition: _objectSpread({
        delay: i * 0.1
      }, transition)
    })
  };

  const getActiveVariantKey = i => {
    if (i === index) return 'entered';
    if (direction === 'right') return 'incrementing';
    return 'decrementing';
  };

  useResizeObserver({
    ref: sliceRef,
    callback: () => {
      isResizing.current = true;
      const textBoxes = textBoxRefs.current;
      const boxes = textBoxRefs.current;
      const maxTextBoxHeight = compose(max, map(box => box === null || box === void 0 ? void 0 : box.clientHeight))(textBoxes);
      setState(state => {
        var _boxes$state$index;

        return _objectSpread({}, state, {
          transparentBoxHeight: maxTextBoxHeight + 100 + 36,
          boxContainerHeight: (_boxes$state$index = boxes[state.index]) === null || _boxes$state$index === void 0 ? void 0 : _boxes$state$index.clientHeight
        });
      });
      isResizing.current = false;
    }
  });
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    pyScale: 0
  }, props), React.createElement(Flex, {
    alignItems: [null, 'center'],
    flexDirection: ['column', 'row'],
    position: "relative",
    ref: sliceRef,
    mx: ['-30px', 0]
  }, !isHydrating ? React.createElement(React.Fragment, null, React.createElement(Box, {
    bottom: 0,
    height: ['300px', 'auto'],
    left: 0,
    position: ['relative', 'absolute'],
    top: 0,
    width: [1, 'calc((100vw - 100%) / 2 + 4 / 6 * 100%)'],
    ml: [null, 'calc(-1 * (100vw - 100%) / 2)'],
    mr: [null, 0]
  }, items.map((item, i) => ((item === null || item === void 0 ? void 0 : item.imageURL) || (item === null || item === void 0 ? void 0 : item.imageFluid)) && React.createElement(ImageContainer, {
    animate: getActiveVariantKey(i),
    as: motion.div,
    bottom: [null, 0],
    bg: "gray.20",
    height: ['300px', 'auto'],
    initial: false,
    key: i,
    left: 0,
    position: "absolute",
    ref: Comp => imageRefs.current[i] = Comp,
    right: 0,
    top: 0,
    variants: imageVariants,
    style: {
      pointerEvents: i === index ? 'auto' : 'none'
    }
  }, React.createElement(Anchor, {
    href: item === null || item === void 0 ? void 0 : item.url
  }, React.createElement(Image, {
    src: item === null || item === void 0 ? void 0 : item.imageURL,
    alt: item === null || item === void 0 ? void 0 : item.imageAlt,
    fluid: item === null || item === void 0 ? void 0 : item.imageFluid
  })))), React.createElement(Box, {
    bg: "white",
    bottom: 0,
    display: ['block', 'none'],
    height: OVERHANG_SPACE_POSITIVE[0] / 2 + "rem",
    left: 0,
    position: "absolute",
    right: 0,
    width: 3 / 4
  })), React.createElement(Box, {
    bg: "transparent",
    height: ['auto', transparentBoxHeight],
    position: "relative",
    width: [1, 3 / 6],
    ml: [null, 'auto'],
    myScale: [0, 'xxl']
  }, React.createElement(Box, {
    pb: ['36px', '50px'],
    pt: [0, '50px'],
    px: ['36px', '50px', null, '64px'],
    bg: "white"
  }, React.createElement(Grid, {
    gridTemplateColumns: "min-content min-content",
    gridColumnGapScale: "m"
  }, React.createElement(ArrowButton, {
    onClick: decrement,
    size: "large"
  }), React.createElement(ArrowButton, {
    direction: "right",
    onClick: increment,
    size: "large"
  })), React.createElement(_StyledBox, {
    as: motion.div,
    animate: mediaIndex !== 0 && boxContainerHeight !== 0 && !isResizing.current && {
      height: boxContainerHeight
    },
    transition: _objectSpread({}, transition, {
      delay: 0.25
    }),
    position: "relative",
    _css: boxContainerHeight,
    _css2: mediaIndex === 0 ? '!important' : ''
  }, items.map((item, i) => React.createElement(_StyledBox2, {
    key: item.heading,
    position: "absolute",
    ptScale: "m-",
    pbScale: "t",
    ref: Comp => textBoxRefs.current[i] = Comp,
    _css3: i === index ? 'auto' : 'none'
  }, withSubheading && React.createElement(Subheading, {
    animate: getActiveVariantKey(i),
    as: motion.h4,
    custom: 0,
    initial: false,
    mbScale: "t",
    variants: itemVariants
  }, "Featured ", item.type), (item === null || item === void 0 ? void 0 : item.objectNumber) && React.createElement(Text, {
    animate: getActiveVariantKey(i),
    as: motion.p,
    custom: 0,
    fontSizeScale: "b",
    initial: false,
    mbScale: "t",
    variants: itemVariants
  }, "Object No. ", item === null || item === void 0 ? void 0 : item.objectNumber), (item === null || item === void 0 ? void 0 : item.title) && React.createElement(Heading, {
    animate: getActiveVariantKey(i),
    as: motion.h3,
    custom: 1,
    fontSizeScale: "xl",
    initial: false,
    mbScale: "s",
    variants: itemVariants,
    color: "gray.20"
  }, React.createElement(_StyledAnchor, {
    href: item === null || item === void 0 ? void 0 : item.url,
    transitionProperty: "color",
    _css4: themeGet('colors.teal.70')
  }, item === null || item === void 0 ? void 0 : item.title)), (item === null || item === void 0 ? void 0 : item.description) && React.createElement(Text, {
    animate: getActiveVariantKey(i),
    as: motion.p,
    custom: 2,
    fontSizeScale: "b",
    initial: false,
    variants: itemVariants
  }, item === null || item === void 0 ? void 0 : item.description), (item === null || item === void 0 ? void 0 : item.dates) && React.createElement(Text, {
    animate: getActiveVariantKey(i),
    as: motion.p,
    color: "teal.70",
    custom: 3,
    fontSizeScale: "b",
    fontWeight: "semibold",
    initial: false,
    mtScale: "s",
    variants: itemVariants
  }, item === null || item === void 0 ? void 0 : item.dates), (item === null || item === void 0 ? void 0 : item.metadata) && React.createElement(Grid, {
    animate: getActiveVariantKey(i),
    mtScale: "m",
    as: motion.dl,
    custom: 4,
    initial: false,
    variants: itemVariants,
    gridTemplateColumns: "auto 1fr",
    gridColumnGapScale: "m",
    gridRowGapScale: "t-",
    alignItems: "baseline"
  }, item.metadata.map(data => React.createElement(React.Fragment, {
    key: data.name
  }, React.createElement(Subheading, {
    as: "dt"
  }, data.name), React.createElement(Text, {
    as: "dd",
    fontSizeScale: "b"
  }, data.content.text)))), (item === null || item === void 0 ? void 0 : item.linkURL) && React.createElement(Box, {
    animate: getActiveVariantKey(i),
    mtScale: "m",
    as: motion.div,
    custom: 5,
    initial: false,
    variants: itemVariants
  }, React.createElement(Button, {
    as: Anchor,
    href: item === null || item === void 0 ? void 0 : item.linkURL,
    target: item === null || item === void 0 ? void 0 : item.linkTarget
  }, "More Details")))))))) : React.createElement(Box, {
    bg: "white",
    height: ['300px', '70vh']
  })));
};