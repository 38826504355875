import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Box, Flex, Text } from 'system';
import { HTMLContent } from 'src/components/HTMLContent';
import { Heading } from 'src/components/Heading';
export const OutlinedBoxes = _ref => {
  let {
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children"]);

  return React.createElement(Flex, props, children, React.createElement(Box, {
    flex: "1 1 auto"
  }));
};

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "OutlinedBoxes___StyledFlex",
  componentId: "c7p7n-0"
})(["&:first-child{margin-right:-2px;}"]);

var _StyledText = _styled(Text).withConfig({
  displayName: "OutlinedBoxes___StyledText",
  componentId: "c7p7n-1"
})(["text-transform:none;letter-spacing:0;"]);

OutlinedBoxes.Box = _ref2 => {
  let {
    children,
    textHTML
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["children", "textHTML"]);

  return React.createElement(_StyledFlex, Object.assign({
    border: ['2px solid', null, null, '3px solid'],
    borderColor: ['yellow.80', null, null, 'yellow.80'],
    flex: "0 1 0px",
    justifyContent: "center",
    pScale: "s"
  }, props), children || textHTML && React.createElement(HTMLContent, {
    html: textHTML,
    map: {
      h1: props => React.createElement(Heading, Object.assign({
        fontWeight: "light",
        color: "yellow.80",
        fontSize: ['xxlarge', 'xlarge', 'xxxlarge', '5rem', '6rem'],
        lineHeight: "solid",
        mb: 1
      }, props)),
      em: props => React.createElement(_StyledText, Object.assign({
        as: "span",
        fontFamily: "serif",
        fontSize: ['small', null, 'normal', 'medium', 'large'],
        fontStyle: "italic"
      }, props))
    },
    fontSize: ['tiny', null, 'normal', 'medium'],
    fontWeight: "semibold",
    lineHeight: "title",
    textAlign: "center",
    textStyle: "trackedCaps"
  }));
};