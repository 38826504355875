import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, getPrimaryMetadataType, metadataBasename } from 'helpers';
import { compose, startCase } from 'tiny-compose-fns';
import { theme } from 'src/theme';
import { getPrimaryMetadata, propPairsEq } from 'src/helpers';
import { Box, Flex, Text, ImageContainer, Image } from 'system';
import { Anchor } from 'src/components/Anchor';
import { AttributeList } from 'src/components/AttributeList';
import { AvatarCard } from 'src/components/AvatarCard';
import { BackButton } from 'src/components/BackButton';
import { BoundedBox } from 'src/components/BoundedBox';
import { Button } from 'src/components/Button';
import { DocumentCard } from 'src/components/DocumentCard';
import { HTMLContent } from 'src/components/HTMLContent';
import { Heading } from 'src/components/Heading';
import { Subheading } from 'src/components/Subheading';
export const PageBodyExperience = _ref => {
  let {
    nextSharesBackground,
    children,
    aboutHTML,
    admission,
    backHref,
    backTitle,
    description,
    endDate,
    imageAlt,
    imageFluid,
    imageURL,
    location,
    relatedLinks,
    residentDescription,
    residentHref,
    residentImageAlt,
    residentImageFluid,
    residentImageURL,
    residentName,
    startDate,
    ticketsHref,
    time,
    title,
    type
  } = _ref,
      props = _objectWithoutProperties(_ref, ["nextSharesBackground", "children", "aboutHTML", "admission", "backHref", "backTitle", "description", "endDate", "imageAlt", "imageFluid", "imageURL", "location", "relatedLinks", "residentDescription", "residentHref", "residentImageAlt", "residentImageFluid", "residentImageURL", "residentName", "startDate", "ticketsHref", "time", "title", "type"]);

  console.log(aboutHTML);
  const dates = [startDate, endDate].filter(Boolean).join(' - ');
  const pluralTypes = {
    Residency: 'Residencies',
    Event: 'Events',
    Exhibition: 'Exhibitions'
  };
  const pluralType = pluralTypes[type];
  return React.createElement(Box, Object.assign({
    as: "section"
  }, props), React.createElement(ImageContainer, {
    bg: "gray.20",
    height: "75vh",
    maxHeight: ['30rem', '40rem', '50rem', '55rem', '65rem'],
    minHeight: [null, '30rem', '40rem', '45rem', '55rem'],
    overflow: "hidden"
  }, React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  })), React.createElement(BoundedBox, {
    maxWidth: "xlarge",
    mbScale: "l",
    mtScale: ['-l', '-xxl'],
    position: "relative",
    pyScale: 0
  }, React.createElement(Box, {
    bg: "white",
    bottom: 0,
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: 1 / 2
  }), React.createElement(Box, {
    bg: "white",
    display: [null, 'none'],
    height: theme.spaceScales.l[0],
    position: "relative",
    width: 3 / 4
  }), React.createElement(Box, {
    bg: "white",
    position: "relative",
    ptScale: [0, 'l'],
    prScale: [0, 'l'],
    width: [null, 1 / 2]
  }, type && React.createElement(Subheading, {
    mbScale: "s"
  }, type), title && React.createElement(Heading, {
    fontSizeScale: "xl"
  }, title), (startDate || endDate) && React.createElement(Text, {
    color: "teal.70",
    fontWeight: "semibold",
    fontSizeScale: "m",
    mtScale: "s"
  }, dates))), React.createElement(BoundedBox, {
    nextSharesBackground: nextSharesBackground,
    maxWidth: "xlarge",
    pt: 0
  }, React.createElement(Flex, {
    flexDirection: ['column', 'row'],
    alignItems: "flex-start"
  }, React.createElement(Box, {
    width: [null, 1 / 2],
    mbScale: ['l', 0],
    mrScale: [0, 'l']
  }, description && React.createElement(Text, {
    fontFamily: "serif",
    fontSizeScale: "l",
    lineHeight: "copy",
    mbScale: "l"
  }, description), (location || time || admission) && React.createElement(Text, {
    fontSizeScale: "m",
    color: "teal.70",
    fontWeight: "semibold",
    mbScale: "l"
  }, React.createElement(AttributeList, {
    nameColor: "teal.40"
  }, location && React.createElement(AttributeList.Attribute, {
    name: "Location"
  }, location), time && React.createElement(AttributeList.Attribute, {
    name: "Time"
  }, time), admission && React.createElement(AttributeList.Attribute, {
    name: "Admission"
  }, admission))), ticketsHref && React.createElement(Box, null, React.createElement(Button, {
    as: Anchor,
    href: ticketsHref,
    target: "_blank"
  }, "Get Tickets")), residentName && React.createElement(AvatarCard, {
    name: residentName,
    descriptionHTML: residentDescription,
    imageFluid: residentImageFluid,
    imageURL: residentImageURL,
    imageAlt: residentImageAlt,
    href: residentHref,
    mtScale: "l",
    width: [1, null, 3 / 4]
  }), backHref && React.createElement(Box, {
    mtScale: "l"
  }, React.createElement(BackButton, {
    href: backHref,
    colorScheme: "yellow"
  }, "Back", backTitle && " to all " + pluralType))), React.createElement(Box, {
    width: [1, 1 / 2]
  }, children || aboutHTML && React.createElement(HTMLContent, {
    html: aboutHTML,
    mbScale: "l",
    boxStyle: "lastNoMargin"
  }), relatedLinks && relatedLinks.length !== 0 && React.createElement(Box, {
    maxWidth: "30rem"
  }, React.createElement(Subheading, {
    color: "teal.40",
    mbScale: "s"
  }, "Links"), React.createElement(Box, {
    as: "ul"
  }, relatedLinks.map(link => React.createElement(Box, {
    as: "li",
    mbScale: "s",
    boxStyle: "lastNoMargin",
    key: link === null || link === void 0 ? void 0 : link.name
  }, React.createElement(DocumentCard, {
    href: link === null || link === void 0 ? void 0 : link.href,
    target: link === null || link === void 0 ? void 0 : link.target,
    name: link === null || link === void 0 ? void 0 : link.name,
    description: link === null || link === void 0 ? void 0 : link.description,
    withOutline: true
  })))))))));
};

PageBodyExperience.mapDataToProps = ({
  data,
  meta,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$primary$experie, _meta$page, _meta$page$data, _meta$page$data$paren, _metadata$primary, _metadata$primary$by_, _data$items, _data$items$map, _metadata$primary2, _metadata$primary3, _metadata$primary4, _metadata$primary5, _metadata$primary5$lo, _metadata$primary6, _metadata$primary7, _metadata$primary8, _metadata$primary8$ti, _residentDoc$data, _residentDoc$data$tit, _residentMetadata$pri, _residentMetadata$pri2, _residentMetadata$pri3, _residentMetadata$pri4, _residentMetadata$pri5, _residentMetadata$pri6, _residentMetadata$pri7, _residentMetadata$pri8, _residentMetadata$pri9, _residentMetadata$pri10, _residentMetadata$pri11, _residentMetadata$pri12, _residentMetadata$pri13, _data$primary2, _data$primary2$image, _data$primary3, _data$primary3$image, _data$primary4, _data$primary4$image, _data$primary5, _data$primary6, _data$primary6$about, _parent$data, _parent$data$title;

  const rootData = meta === null || meta === void 0 ? void 0 : meta.rootData;
  const selfDoc = rootData === null || rootData === void 0 ? void 0 : rootData.prismicPage;
  const linkedDoc = data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$experie = _data$primary.experience) === null || _data$primary$experie === void 0 ? void 0 : _data$primary$experie.document;
  const isLinked = Boolean(linkedDoc);
  const doc = isLinked ? linkedDoc : selfDoc;
  const metadata = getPrimaryMetadata(isLinked ? linkedDoc : doc);
  const parent = meta === null || meta === void 0 ? void 0 : (_meta$page = meta.page) === null || _meta$page === void 0 ? void 0 : (_meta$page$data = _meta$page.data) === null || _meta$page$data === void 0 ? void 0 : (_meta$page$data$paren = _meta$page$data.parent) === null || _meta$page$data$paren === void 0 ? void 0 : _meta$page$data$paren.document;
  const residentDoc = metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$by_ = _metadata$primary.by_resident) === null || _metadata$primary$by_ === void 0 ? void 0 : _metadata$primary$by_.document;
  const residentMetadata = getPrimaryMetadata(residentDoc);
  const relatedLinks = data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : (_data$items$map = _data$items.map(item => {
    var _item$related_link, _item$related_link2, _item$name, _item$description;

    return {
      href: item === null || item === void 0 ? void 0 : (_item$related_link = item.related_link) === null || _item$related_link === void 0 ? void 0 : _item$related_link.url,
      target: item === null || item === void 0 ? void 0 : (_item$related_link2 = item.related_link) === null || _item$related_link2 === void 0 ? void 0 : _item$related_link2.target,
      name: item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name.text,
      description: item === null || item === void 0 ? void 0 : (_item$description = item.description) === null || _item$description === void 0 ? void 0 : _item$description.text
    };
  })) === null || _data$items$map === void 0 ? void 0 : _data$items$map.filter(item => item === null || item === void 0 ? void 0 : item.href);
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    title: doc.data.title.text,
    type: compose(startCase, metadataBasename, getPrimaryMetadataType)(doc),
    description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.description,
    startDate: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : _metadata$primary3.start_date,
    endDate: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : _metadata$primary4.end_date,
    location: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$lo = _metadata$primary5.location) === null || _metadata$primary5$lo === void 0 ? void 0 : _metadata$primary5$lo.text,
    time: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : _metadata$primary6.time,
    admission: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary7 = metadata.primary) === null || _metadata$primary7 === void 0 ? void 0 : _metadata$primary7.admission,
    ticketsHref: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary8 = metadata.primary) === null || _metadata$primary8 === void 0 ? void 0 : (_metadata$primary8$ti = _metadata$primary8.tickets_link) === null || _metadata$primary8$ti === void 0 ? void 0 : _metadata$primary8$ti.url,
    residentName: residentDoc === null || residentDoc === void 0 ? void 0 : (_residentDoc$data = residentDoc.data) === null || _residentDoc$data === void 0 ? void 0 : (_residentDoc$data$tit = _residentDoc$data.title) === null || _residentDoc$data$tit === void 0 ? void 0 : _residentDoc$data$tit.text,
    residentDescription: residentMetadata === null || residentMetadata === void 0 ? void 0 : (_residentMetadata$pri = residentMetadata.primary) === null || _residentMetadata$pri === void 0 ? void 0 : _residentMetadata$pri.description,
    residentImageFluid: (residentMetadata === null || residentMetadata === void 0 ? void 0 : (_residentMetadata$pri2 = residentMetadata.primary) === null || _residentMetadata$pri2 === void 0 ? void 0 : (_residentMetadata$pri3 = _residentMetadata$pri2.profile_picture) === null || _residentMetadata$pri3 === void 0 ? void 0 : _residentMetadata$pri3.fluid) || (residentMetadata === null || residentMetadata === void 0 ? void 0 : (_residentMetadata$pri4 = residentMetadata.primary) === null || _residentMetadata$pri4 === void 0 ? void 0 : (_residentMetadata$pri5 = _residentMetadata$pri4.featured_image) === null || _residentMetadata$pri5 === void 0 ? void 0 : _residentMetadata$pri5.fluid),
    residentImageURL: (residentMetadata === null || residentMetadata === void 0 ? void 0 : (_residentMetadata$pri6 = residentMetadata.primary) === null || _residentMetadata$pri6 === void 0 ? void 0 : (_residentMetadata$pri7 = _residentMetadata$pri6.profile_picture) === null || _residentMetadata$pri7 === void 0 ? void 0 : _residentMetadata$pri7.url) || (residentMetadata === null || residentMetadata === void 0 ? void 0 : (_residentMetadata$pri8 = residentMetadata.primary) === null || _residentMetadata$pri8 === void 0 ? void 0 : (_residentMetadata$pri9 = _residentMetadata$pri8.featured_image) === null || _residentMetadata$pri9 === void 0 ? void 0 : _residentMetadata$pri9.url),
    residentImageAlt: (residentMetadata === null || residentMetadata === void 0 ? void 0 : (_residentMetadata$pri10 = residentMetadata.primary) === null || _residentMetadata$pri10 === void 0 ? void 0 : (_residentMetadata$pri11 = _residentMetadata$pri10.profile_picture) === null || _residentMetadata$pri11 === void 0 ? void 0 : _residentMetadata$pri11.alt) || (residentMetadata === null || residentMetadata === void 0 ? void 0 : (_residentMetadata$pri12 = residentMetadata.primary) === null || _residentMetadata$pri12 === void 0 ? void 0 : (_residentMetadata$pri13 = _residentMetadata$pri12.featured_image) === null || _residentMetadata$pri13 === void 0 ? void 0 : _residentMetadata$pri13.alt),
    residentHref: residentDoc === null || residentDoc === void 0 ? void 0 : residentDoc.url,
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$image = _data$primary2.image) === null || _data$primary2$image === void 0 ? void 0 : _data$primary2$image.fluid,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$image = _data$primary3.image) === null || _data$primary3$image === void 0 ? void 0 : _data$primary3$image.url,
    imageAlt: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$image = _data$primary4.image) === null || _data$primary4$image === void 0 ? void 0 : _data$primary4$image.alt,
    aboutHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : _data$primary5.about) || (data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : (_data$primary6$about = _data$primary6.about) === null || _data$primary6$about === void 0 ? void 0 : _data$primary6$about.html),
    relatedLinks,
    backHref: parent === null || parent === void 0 ? void 0 : parent.url,
    backTitle: parent === null || parent === void 0 ? void 0 : (_parent$data = parent.data) === null || _parent$data === void 0 ? void 0 : (_parent$data$title = _parent$data.title) === null || _parent$data$title === void 0 ? void 0 : _parent$data$title.text
  };
};

PageBodyExperience.mapDataToContext = () => ({
  headerLogoColor: 'white',
  headerBg: 'teal.75',
  backgroundColor: 'white'
});

export const fragment = "1191906903";