import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState } from 'react';
import { Example } from 'reference';
import { StyleSheetManager } from 'styled-components';
import { Layout } from '@walltowall/gatsby-theme-ww-prismic/src/components/Layout';
import { PageWrapper } from 'src/components/PageWrapper';
export const ProjectExample = _ref => {
  let {
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children"]);

  const [head, setHead] = useState();
  return React.createElement(Example, Object.assign({
    maxScale: 1,
    setHeadFn: setHead
  }, props), React.createElement(StyleSheetManager, {
    target: head
  }, React.createElement(PageWrapper, null, React.createElement(Layout, {
    headless: true
  }, children))));
};