import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import { Box, Flex, Text } from 'system';
import { ArrowButton } from 'src/components/ArrowButton';
import { Anchor } from 'src/components/Anchor';
import { Button } from 'src/components/Button';
import { Heading } from 'src/components/Heading';
import { Subheading } from 'src/components/Subheading';
import { OVERHANG_SPACE_POSITIVE } from 'src/constants';

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "Card___StyledAnchor",
  componentId: "xqwcnk-0"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css);

export const Card = _ref => {
  let {
    color = 'gray.20',
    subheading,
    heading,
    headingHref,
    headingTarget,
    children,
    buttonText = 'Learn More',
    buttonHref,
    buttonTarget,
    buttonColorScheme = 'orange',
    detail,
    leftArrowOnClick,
    rightArrowOnClick,
    withTopOverhang = false,
    innerProps,
    bg = 'white'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["color", "subheading", "heading", "headingHref", "headingTarget", "children", "buttonText", "buttonHref", "buttonTarget", "buttonColorScheme", "detail", "leftArrowOnClick", "rightArrowOnClick", "withTopOverhang", "innerProps", "bg"]);

  return React.createElement(Box, Object.assign({
    position: "relative"
  }, props), React.createElement(Flex, {
    flexDirection: "column",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  }, withTopOverhang && React.createElement(Box, {
    bg: bg,
    height: [OVERHANG_SPACE_POSITIVE[0] / 2 + "rem", 0],
    position: "relative",
    width: 3 / 4
  }), React.createElement(Box, {
    bg: bg,
    flex: "1 1 auto"
  })), React.createElement(Box, Object.assign({
    position: "relative",
    pScale: "xl"
  }, innerProps), (leftArrowOnClick || rightArrowOnClick) && React.createElement(Flex, {
    alignItems: "center",
    mb: [3, null, 6]
  }, leftArrowOnClick && React.createElement(ArrowButton, {
    onClick: leftArrowOnClick,
    direction: "left",
    mr: [2, null, 4]
  }), rightArrowOnClick && React.createElement(ArrowButton, {
    onClick: rightArrowOnClick,
    direction: "right"
  })), subheading && React.createElement(Subheading, {
    mbScale: "t"
  }, subheading), heading && React.createElement(ConditionalWrap, {
    condition: headingHref,
    wrap: children => React.createElement(_StyledAnchor, {
      href: headingHref,
      target: headingTarget,
      transitionProperty: "color",
      _css: themeGet('colors.teal.70')
    }, children)
  }, React.createElement(Heading, {
    mbScale: "s"
  }, heading)), React.createElement(Box, {
    color: color
  }, children), detail && React.createElement(Text, {
    as: "p",
    fontSizeScale: "b",
    fontWeight: "semibold",
    color: "teal.70",
    mtScale: "s"
  }, detail), buttonHref && React.createElement(Button, {
    as: Anchor,
    href: buttonHref,
    target: buttonTarget,
    colorScheme: buttonColorScheme,
    mtScale: "m-"
  }, buttonText)));
};