import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import staticQueryData from "../../public/static/d/888340779.json";
import React, { useContext, useState, useMemo } from 'react';
export const useSpeedBumpSettings = () => {
  const data = staticQueryData.data;
  const allowList = useMemo(() => {
    var _ref, _data$prismicSettings;

    return (_ref = (_data$prismicSettings = data.prismicSettings.data.allow_list) === null || _data$prismicSettings === void 0 ? void 0 : _data$prismicSettings.map(entry => entry.link_url)) !== null && _ref !== void 0 ? _ref : [];
  }, []);
  const blockList = useMemo(() => {
    var _ref2, _data$prismicSettings2;

    return (_ref2 = (_data$prismicSettings2 = data.prismicSettings.data.block_list) === null || _data$prismicSettings2 === void 0 ? void 0 : _data$prismicSettings2.map(entry => entry.link_url)) !== null && _ref2 !== void 0 ? _ref2 : [];
  }, []);
  return {
    allowList,
    blockList
  };
};
export const SpeedBumpContext = React.createContext();
export const SpeedBumpProvider = ({
  children
}) => {
  const [state, setState] = useState({
    isSpeedBumpVisible: false,
    href: null
  });
  const {
    isSpeedBumpVisible,
    href
  } = state;

  const hideSpeedBump = () => setState(state => _objectSpread({}, state, {
    isSpeedBumpVisible: false
  }));

  const setHref = newHref => setState(state => _objectSpread({}, state, {
    href: newHref
  }));

  const showSpeedBump = () => setState(state => _objectSpread({}, state, {
    isSpeedBumpVisible: true
  }));

  const host = useMemo(() => {
    if (!href) return;
    return new URL(href).host;
  }, [href]);
  return React.createElement(SpeedBumpContext.Provider, {
    value: {
      isSpeedBumpVisible,
      host,
      href,
      hideSpeedBump,
      setHref,
      showSpeedBump
    }
  }, children);
};
export const useSpeedBump = () => useContext(SpeedBumpContext);