import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, notEmpty } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { Box, Grid, Image, ImageContainer } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
const colorSchemes = {
  white: {
    bgColor: 'white'
  },
  lightGray: {
    bgColor: 'gray.90'
  }
};
export const PageBodyImages = _ref => {
  let {
    children,
    backgroundColor = 'white'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "backgroundColor"]);

  const colors = colorSchemes[backgroundColor];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    pxScale: [0, null, 'l'],
    bg: colors.bgColor,
    maxWidth: "xlarge"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', "repeat(" + React.Children.count(children) + ", 1fr)"],
    justifyItems: "center",
    alignItems: [null, 'center'],
    gridColumnGapScale: "l",
    gridRowGapScale: "xl",
    boxStyle: "lastNoMargin"
  }, React.Children.map(children, child => child && React.cloneElement(child, {
    bg: colors.bg
  }))));
};

PageBodyImages.Image = _ref2 => {
  let {
    imageFluid,
    imageURL,
    imageAlt,
    captionHTML,
    bg = 'white'
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["imageFluid", "imageURL", "imageAlt", "captionHTML", "bg"]);

  return React.createElement(Box, Object.assign({
    as: "figure",
    boxStyle: "lastNoMargin",
    width: "100%"
  }, props), React.createElement(ImageContainer, null, (imageFluid || imageURL) && React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt,
    maxHeight: ['30rem', '50rem'],
    objectFit: "contain"
  })), captionHTML && React.createElement(HTMLContent, {
    as: "figcaption",
    bg: bg,
    html: captionHTML,
    fontSizeScale: "s",
    color: "gray.60",
    textAlign: "center",
    mtScale: "t",
    mxScale: "m"
  }));
};

PageBodyImages.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$items;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    backgroundColor: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.background_color),
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.map(item => {
      var _item$image, _item$image2, _item$image3;

      return React.createElement(PageBodyImages.Image, {
        imageFluid: item === null || item === void 0 ? void 0 : (_item$image = item.image) === null || _item$image === void 0 ? void 0 : _item$image.fluid,
        imageURL: item === null || item === void 0 ? void 0 : (_item$image2 = item.image) === null || _item$image2 === void 0 ? void 0 : _item$image2.url,
        imageAlt: notEmpty(item === null || item === void 0 ? void 0 : (_item$image3 = item.image) === null || _item$image3 === void 0 ? void 0 : _item$image3.alt),
        captionHTML: getRichText(item === null || item === void 0 ? void 0 : item.caption)
      });
    })
  };
};

PageBodyImages.mapDataToContext = ({
  data
}) => {
  var _data$primary2;

  const colorScheme = camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.background_color);
  const colors = colorSchemes[colorScheme];
  return {
    backgroundColor: colors.bgColor,
    isArticleType: true
  };
};

export const fragment = "4082572105";