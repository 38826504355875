import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { times } from 'tiny-compose-fns';
import { Box, Flex, Button, SVG } from 'system';
import { Subheading } from 'src/components/Subheading';
import { ReactComponent as AssetIconChevronLeftSVG } from 'src/assets/icon-chevron-left.svg';
import { ReactComponent as AssetIconChevronRightSVG } from 'src/assets/icon-chevron-right.svg'; // TODO: Change "total" to "totalPages"

var _StyledButton = _styled(Button).withConfig({
  displayName: "Pagination___StyledButton",
  componentId: "sc-1iq3w28-0"
})(["&:hover,&:focus{color:", ";}"], p => p._css);

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "Pagination___StyledFlex",
  componentId: "sc-1iq3w28-1"
})(["&:hover,&:focus{color:", ";}"], p => p._css2);

var _StyledFlex2 = _styled(Flex).withConfig({
  displayName: "Pagination___StyledFlex2",
  componentId: "sc-1iq3w28-2"
})(["&:hover,&:focus{color:", ";}"], p => p._css3);

export const Pagination = ({
  total = 0,
  current = 0,
  handleSet = () => {}
}) => {
  const hasNext = current < total - 1;
  const hasPrevious = current >= 1;
  const hasAny = hasNext || hasPrevious;
  if (!hasAny) return null;
  return React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    mtScale: ['s', 0]
  }, React.createElement(Box, null, React.createElement(Subheading, {
    fontSizeScale: "b",
    color: "gray.20",
    display: "inline-block",
    mrScale: "s"
  }, "Page:"), times(idx => React.createElement(_StyledButton, {
    key: idx,
    mrScale: "s",
    boxStyle: "lastNoMargin",
    onClick: () => handleSet(idx),
    color: current === idx ? 'gray.20' : 'gray.60',
    transitionProperty: "color",
    outline: "none",
    _css: current !== idx ? themeGet('colors.teal.75') : themeGet('colors.gray.20')
  }, React.createElement(Subheading, {
    as: "div",
    fontSizeScale: "b",
    color: "inherit"
  }, idx + 1)), total)), React.createElement(Flex, {
    mtScale: "s"
  }, React.createElement(_StyledFlex, {
    as: Button,
    alignItems: "center",
    onClick: () => hasPrevious && handleSet(current - 1),
    cursor: hasPrevious ? 'pointer' : 'not-allowed',
    opacity: current === 0 && '0.6',
    outline: "none",
    color: "gray.20",
    transitionProperty: "color",
    _css2: hasPrevious ? themeGet('colors.teal.75') : themeGet('colors.gray.20')
  }, React.createElement(SVG, {
    svg: AssetIconChevronLeftSVG,
    x: 2,
    y: 3,
    display: "inline-block",
    width: "0.7rem"
  }), React.createElement(Subheading, {
    as: "span",
    display: "inline-block",
    fontSizeScale: "b",
    lineHeight: "solid",
    mxScale: "t",
    color: "inherit"
  }, "Previous")), React.createElement(_StyledFlex2, {
    as: Button,
    alignItems: "center",
    opacity: current === total - 1 && '0.5',
    cursor: hasNext ? 'pointer' : 'not-allowed',
    onClick: () => hasNext && handleSet(current + 1),
    outline: "none",
    color: "gray.20",
    transitionProperty: "color",
    _css3: hasNext ? themeGet('colors.teal.75') : themeGet('colors.gray.20')
  }, React.createElement(Subheading, {
    as: "span",
    color: "inherit",
    display: "inline-block",
    fontSizeScale: "b",
    lineHeight: "solid",
    mxScale: "t"
  }, "Next"), React.createElement(SVG, {
    svg: AssetIconChevronRightSVG,
    x: 2,
    y: 3,
    display: "inline-block",
    width: "0.7rem"
  }))));
};