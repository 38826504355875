import { LinkCard, Field, FieldsTable, Callout, SliceExamples, Example } from 'reference';
import React from 'react';
export default {
  LinkCard,
  Field,
  FieldsTable,
  Callout,
  SliceExamples,
  Example,
  React
};