import { negateScale } from 'styled-system-scale'; // Amount to overhang elements in slices. All values are negative.

export const OVERHANG_SPACE_NEGATIVE = [-6, -10, -16, -20, -20]; // Amount to compensate for overhanging elements in slices. All values are positive.

export const OVERHANG_SPACE_POSITIVE = negateScale(OVERHANG_SPACE_NEGATIVE); // Amount to compensate for the floating header in slices. All values are positive.

export const HEADER_SPACE = [7, 10, 15, 17, 17];
export const HEADER_HEIGHT_SCALE = [69, 87, 99, 113, 125]; // Amount of space used on y-axis in standard elements.

export const STANDARD_Y_SPACE = [6, 10, 16, 20, 20];
export const STANDARD_Y_SPACE_NEGATIVE = negateScale(STANDARD_Y_SPACE); // Amount of space used on x-axis in standard elements. All values are positive.

export const STANDARD_X_SPACE = [4, 4, 8, 12, 12];
export const STANDARD_X_SPACE_NEGATIVE = negateScale(STANDARD_X_SPACE); // Max width for article slices.

export const ARTICLE_MAX_WIDTH = ['medium+', null, null, 'large']; // Good default config for framer-motion

export const transition = {
  type: 'spring',
  damping: 30,
  stiffness: 200
};