import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Box } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
import { Subheading } from 'src/components/Subheading';
const colorSchemes = {
  white: {
    bg: 'white',
    color: 'teal.70'
  },
  lightGray: {
    bg: 'gray.90',
    color: 'teal.70'
  }
};
export const PageBodyNote = _ref => {
  let {
    children,
    backgroundColor,
    textHTML
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "backgroundColor", "textHTML"]);

  const colors = colorSchemes[backgroundColor];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: colors.bg,
    maxWidth: ARTICLE_MAX_WIDTH
  }, props), React.createElement(Box, {
    borderLeft: ['0.5rem solid', '0.75rem solid'],
    borderColor: [colors.color, colors.color],
    mlScale: "m",
    plScale: "m"
  }, children || textHTML && React.createElement(HTMLContent, {
    html: textHTML,
    map: {
      h1: props => React.createElement(Subheading, Object.assign({
        mtScale: "m",
        mbScale: "t",
        color: "inherit",
        fontSizeScale: "m",
        boxStyle: "firstLastNoMargin"
      }, props))
    },
    color: colors.color,
    fontWeight: "semibold",
    fontSizeScale: ['s', null, 'm']
  })));
};

PageBodyNote.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$primary2;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    backgroundColor: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.background_color),
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.text)
  };
};

PageBodyNote.mapDataToContext = ({
  data
}) => {
  var _data$primary3;

  const backgroundColor = camelCase(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.background_color);
  const colors = colorSchemes[backgroundColor];
  return {
    backgroundColor: colors.bg,
    isArticleType: true
  };
};

export const fragment = "307458810";