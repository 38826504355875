import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { Box, Flex } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { InfoCard } from 'src/components/InfoCard';
import { useTrailSlideUp } from 'src/hooks/useTrailSlideUp';
export const PageBodyInfoCards = _ref => {
  let {
    children,
    id
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "id"]);

  const childrenCount = React.Children.count(children);
  const [ref, animateObjs] = useTrailSlideUp(childrenCount, {
    threshold: 0.25
  });
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    id: id !== null && id !== void 0 ? id : undefined,
    ref: ref,
    bg: ['transparent', 'gray.90'],
    ptScale: ['0', 'xxl'],
    pbScale: ['0', 'xxl'],
    position: "relative",
    zIndex: "infoCards"
  }, props), React.createElement(Flex, {
    as: "ul",
    flexDirection: ['column', 'row'],
    mxScale: ['-l', '0'],
    myScale: ['-xxl', '0']
  }, React.Children.map(children, (child, index) => React.createElement(Box, {
    mrScale: [0, 'l'],
    flex: "1 1 0px",
    boxStyle: "lastNoMargin"
  }, React.cloneElement(child, {
    height: '100%',
    contentAnimate: animateObjs[index],
    withTab: index === 0 || index === childrenCount - 1,
    tabPosition: [index === childrenCount - 1 ? 'bottom' : 'top', 'top']
  })))));
};

PageBodyInfoCards.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$items;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.map(item => {
      var _item$info_card, _doc$data, _doc$data2, _doc$data2$button_lin, _doc$data3, _doc$data3$button_lin, _doc$data4, _doc$data5, _doc$data6;

      const doc = item === null || item === void 0 ? void 0 : (_item$info_card = item.info_card) === null || _item$info_card === void 0 ? void 0 : _item$info_card.document;
      return React.createElement(InfoCard, {
        key: doc === null || doc === void 0 ? void 0 : doc.uid,
        colorScheme: camelCase(item === null || item === void 0 ? void 0 : item.color_scheme),
        buttonText: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : _doc$data.button_text,
        buttonHref: doc === null || doc === void 0 ? void 0 : (_doc$data2 = doc.data) === null || _doc$data2 === void 0 ? void 0 : (_doc$data2$button_lin = _doc$data2.button_link) === null || _doc$data2$button_lin === void 0 ? void 0 : _doc$data2$button_lin.url,
        buttonTarget: doc === null || doc === void 0 ? void 0 : (_doc$data3 = doc.data) === null || _doc$data3 === void 0 ? void 0 : (_doc$data3$button_lin = _doc$data3.button_link) === null || _doc$data3$button_lin === void 0 ? void 0 : _doc$data3$button_lin.target,
        textHTML: getRichText(doc === null || doc === void 0 ? void 0 : (_doc$data4 = doc.data) === null || _doc$data4 === void 0 ? void 0 : _doc$data4.text),
        highlights: doc === null || doc === void 0 ? void 0 : (_doc$data5 = doc.data) === null || _doc$data5 === void 0 ? void 0 : _doc$data5.highlights,
        footnoteHTML: getRichText(doc === null || doc === void 0 ? void 0 : (_doc$data6 = doc.data) === null || _doc$data6 === void 0 ? void 0 : _doc$data6.footnote)
      });
    })
  };
};

PageBodyInfoCards.mapDataToContext = () => ({
  headerLogoColor: 'gray.20',
  backgroundColor: [Symbol('Not tracked'), 'gray.90'],
  overhangsPrevious: [true, false],
  overhangsNext: [true, false]
});

export const fragment = "1826338250";