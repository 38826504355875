import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { Button } from 'system';
import { ArrowCircle } from 'src/components/ArrowCircle';
const colorSchemes = {
  yellow: {
    hover: 'red.40',
    bg: 'yellow.80'
  },
  orange: {
    hover: 'yellow.80',
    bg: 'orange.60'
  }
};

var _StyledButton = _styled(Button).withConfig({
  displayName: "ArrowButton___StyledButton",
  componentId: "yz9mjo-0"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css);

export const ArrowButton = _ref => {
  let {
    direction = 'left',
    colorScheme = 'yellow',
    size = 'normal'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["direction", "colorScheme", "size"]);

  const colors = colorSchemes[colorScheme];
  return React.createElement(_StyledButton, Object.assign({
    color: colors.bg,
    transitionProperty: "color"
  }, props, {
    _css: themeGet("colors." + colors.hover)
  }), React.createElement(ArrowCircle, {
    direction: direction,
    size: size
  }));
};