import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { camelCase } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Flex } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { DocumentCard } from 'src/components/DocumentCard';
export const PageBodyDocumentsList = _ref => {
  let {
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "gray.90",
    maxWidth: ARTICLE_MAX_WIDTH
  }, props), React.createElement(Flex, {
    as: "ul",
    mrScale: [0, '-s'],
    mbScale: "-s",
    flexWrap: "wrap"
  }, React.Children.map(children, child => child && React.createElement(Flex, {
    as: "li",
    mbScale: "s",
    prScale: [0, 's'],
    width: [1, 1 / 2]
  }, child))));
};

PageBodyDocumentsList.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$items;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.map(item => {
      var _item$document_link, _item$name, _item$description;

      return React.createElement(DocumentCard, {
        href: item === null || item === void 0 ? void 0 : (_item$document_link = item.document_link) === null || _item$document_link === void 0 ? void 0 : _item$document_link.url,
        target: "_blank",
        icon: camelCase(item === null || item === void 0 ? void 0 : item.icon) || 'document',
        name: item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name.text,
        description: item === null || item === void 0 ? void 0 : (_item$description = item.description) === null || _item$description === void 0 ? void 0 : _item$description.text
      });
    })
  };
};

PageBodyDocumentsList.mapDataToContext = () => ({
  backgroundColor: 'gray.90',
  isArticleType: true
});

export const fragment = "2536387091";