import staticQueryData from "../../public/static/d/3188982934.json";
import { useMemo } from 'react';
import { castArray, lowercase, compose, map, some, filter, includes } from 'tiny-compose-fns';
export const useExperiencesData = types => {
  const queryData = staticQueryData.data;
  return useMemo(() => {
    var _queryData$allPrismic;

    const pages = queryData === null || queryData === void 0 ? void 0 : (_queryData$allPrismic = queryData.allPrismicPage) === null || _queryData$allPrismic === void 0 ? void 0 : _queryData$allPrismic.nodes;
    if (!types) return pages;
    const normalizedTypes = compose(map(lowercase), castArray)(types);
    return filter(page => some(tag => includes(lowercase(tag), normalizedTypes), page.tags), pages);
  }, [queryData, types]);
};