import { FieldsTable, Field, LinkCard, SliceExamples, Example } from 'reference';
import { Layout } from '@walltowall/gatsby-theme-ww-prismic/src/components/Layout';
import { PageBodyForm } from 'src/slices/PageBodyForm';
import React from 'react';
export default {
  FieldsTable,
  Field,
  LinkCard,
  SliceExamples,
  Example,
  Layout,
  PageBodyForm,
  React
};