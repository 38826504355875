import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Card } from 'src/components/Card';
import { Heading } from 'src/components/Heading';
import { HTMLContent } from 'src/components/HTMLContent';
export const EmptyCard = _ref => {
  let {
    children,
    textHTML,
    buttonHref,
    buttonTarget,
    buttonText
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "textHTML", "buttonHref", "buttonTarget", "buttonText"]);

  return React.createElement(Card, Object.assign({
    bg: "teal.75",
    buttonColorScheme: "white",
    buttonHref: buttonHref,
    buttonTarget: buttonTarget,
    buttonText: buttonText,
    color: "white"
  }, props), children || textHTML && React.createElement(HTMLContent, {
    html: textHTML,
    map: {
      h1: props => React.createElement(Heading, Object.assign({
        boxStyle: "firstLastNoMargin",
        fontSizeScale: "l",
        mbScale: "s",
        mt: 3
      }, props)),
      h2: props => React.createElement(Heading, Object.assign({
        boxStyle: "firstLastNoMargin",
        fontSizeScale: "l",
        mbScale: "s",
        mt: 3
      }, props))
    }
  }));
};