import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty, metadataBasename, getPrimaryMetadataType } from 'helpers';
import { startCase, compose } from 'tiny-compose-fns';
import { getPrimaryMetadata } from 'src/helpers';
import { Box, Flex, AspectRatio, ImageContainer, Image } from 'system';
import { Anchor } from 'src/components/Anchor';
import { Card } from 'src/components/Card';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
export const PageBodyFeaturedExperience = _ref => {
  let {
    type,
    title,
    descriptionHTML,
    startDate,
    endDate,
    linkURL,
    imageFluid,
    imageURL,
    imageAlt
  } = _ref,
      props = _objectWithoutProperties(_ref, ["type", "title", "descriptionHTML", "startDate", "endDate", "linkURL", "imageFluid", "imageURL", "imageAlt"]);

  const dates = [startDate, endDate].filter(Boolean).join(' - ');
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    pyScale: "0"
  }, props), React.createElement(Flex, {
    flexDirection: ['column', 'row'],
    alignItems: [null, 'center']
  }, React.createElement(ImageContainer, {
    width: [null, 'calc((100vw - 100%) / 2 + 4 / 6 * 100%)'],
    ml: ['-30px', 'calc(-1 * (100vw - 100%) / 2)'],
    mr: ['-30px', 0],
    alignSelf: "stretch",
    bg: "gray.20"
  }, React.createElement(AspectRatio, {
    x: [4, 8],
    y: [3, 5],
    height: "100%"
  }, React.createElement(Anchor, {
    href: linkURL
  }, React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  })))), React.createElement(Box, {
    width: [1, 3 / 6],
    ml: [null, 'calc(-1 * 100% / 6)'],
    myScale: [0, 'xxl']
  }, React.createElement(Box, {
    display: [null, 'none'],
    height: 40,
    bg: "white",
    width: 3 / 4,
    mtScale: "-xl",
    mxScale: "-l",
    position: "relative"
  }), React.createElement(Card, {
    subheading: type && "Featured " + type,
    heading: title,
    headingHref: linkURL,
    detail: dates,
    innerProps: {
      prScale: ['l', 's'],
      ptScale: [0, 'xl'],
      plScale: ['l', 'xl']
    },
    mxScale: ['-l', 0]
  }, React.createElement(HTMLContent, {
    html: descriptionHTML,
    lineHeight: "wide"
  })))));
};

PageBodyFeaturedExperience.mapDataToProps = ({
  data
}) => {
  var _doc$data, _doc$data$title, _metadata$primary, _metadata$primary2, _metadata$primary3, _metadata$primary4, _metadata$primary4$fe, _metadata$primary5, _metadata$primary5$fe, _metadata$primary6, _metadata$primary6$fe;

  const doc = data.primary.experience.document;
  const metadata = getPrimaryMetadata(doc);
  const type = compose(startCase, metadataBasename, getPrimaryMetadataType)(doc);
  return {
    type,
    title: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : (_doc$data$title = _doc$data.title) === null || _doc$data$title === void 0 ? void 0 : _doc$data$title.text,
    linkURL: doc === null || doc === void 0 ? void 0 : doc.url,
    descriptionHTML: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : _metadata$primary.description,
    startDate: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.start_date,
    endDate: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : _metadata$primary3.end_date,
    imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$fe = _metadata$primary4.featured_image) === null || _metadata$primary4$fe === void 0 ? void 0 : _metadata$primary4$fe.fluid,
    imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$fe = _metadata$primary5.featured_image) === null || _metadata$primary5$fe === void 0 ? void 0 : _metadata$primary5$fe.url,
    imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$fe = _metadata$primary6.featured_image) === null || _metadata$primary6$fe === void 0 ? void 0 : _metadata$primary6$fe.alt)
  };
};

export const fragment = "459985723";