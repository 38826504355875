import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useState, useEffect } from 'react';
import css from '@styled-system/css';
import { getRichText } from 'helpers';
import { mixed as yupMixed } from 'yup';
import { boolean } from 'boolean';
import { camelCase } from 'tiny-compose-fns';
import { Box, Input, SVG, Text, Flex, Button, Grid } from 'system';
import { HTMLContent } from 'src/components/HTMLContent';
import { Subheading } from 'src/components/Subheading';
import { FormikError } from 'src/components/FormikError';
import { ReactComponent as AssetIconPaperclipSVG } from 'src/assets/paperclip.svg';
import { ReactComponent as AssetIconFileTextSVG } from 'src/assets/file-text.svg';
import { ReactComponent as AssetIconXSquareSVG } from 'src/assets/x-square.svg';

var _StyledGrid = _styled(Grid).withConfig({
  displayName: "FormBodyFileField___StyledGrid",
  componentId: "sc-11yv06s-0"
})(["justify-content:start;"]);

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "FormBodyFileField___StyledFlex",
  componentId: "sc-11yv06s-1"
})(["", ""], p => p._css);

export const FormBodyFileField = ({
  label,
  name,
  helpTextHTML,
  setFieldValue,
  setFieldTouched,
  validateForm
}) => {
  const [file, setFile] = useState(null);

  const handleFileUpload = event => {
    if (!event.target.files.length) return;
    const newFile = event.target.files[0];
    setFieldValue(name, newFile);
    setFieldTouched(name, true);
    setFile(newFile);
  };

  const handleFileCancel = event => {
    event.preventDefault();
    setFieldValue(name, null);
    setFile(null);
  };

  const promptText = file ? 'Upload a Different Attachment' : 'Upload Attachment';
  useEffect(() => {
    if (file) validateForm();
  }, [file, name, validateForm]);
  return React.createElement(Box, {
    gridColumn: [1, 'span 2'],
    boxStyle: "lastNoMargin"
  }, label && React.createElement(Subheading, {
    as: "h3",
    color: "teal.40",
    mbScale: "s",
    htmlFor: name
  }, label), helpTextHTML && React.createElement(HTMLContent, {
    html: helpTextHTML,
    mbScale: "s"
  }), React.createElement(Box, {
    as: "label"
  }, React.createElement(Input, {
    display: "none",
    onChange: handleFileUpload,
    type: "file",
    "aria-hidden": "true",
    visibility: "hidden",
    name: name,
    id: name,
    pyScale: "t+",
    pxScale: "s",
    borderRadius: "8px",
    fontSizeScale: "m",
    width: "100%",
    readonly: true
  }), React.createElement(Text, {
    fontWeight: "semibold"
  }, file && React.createElement(_StyledGrid, {
    mbScale: "t",
    alignItems: "center",
    gridTemplateColumns: "repeat(3, auto)"
  }, React.createElement(SVG, {
    x: 1,
    y: 1,
    svg: AssetIconFileTextSVG,
    width: "1.5rem",
    mrScale: "t-"
  }), React.createElement(Text, {
    as: "p",
    mrScale: "l",
    color: "gray.20"
  }, file.name), React.createElement(Button, {
    as: SVG,
    color: "red.40",
    type: "button",
    x: 1,
    y: 1,
    svg: AssetIconXSquareSVG,
    width: "1.25rem",
    onClick: event => handleFileCancel(event, file.name)
  })), React.createElement(_StyledFlex, {
    alignItems: "center",
    transitionProperty: "color",
    mbScale: "t",
    _css: css({
      cursor: 'pointer',
      color: 'gray.60',
      '&:hover, &:focus': {
        color: 'teal.70'
      }
    })
  }, React.createElement(SVG, {
    x: 1,
    y: 1,
    svg: AssetIconPaperclipSVG,
    width: "1.5rem",
    mrScale: "t-"
  }), React.createElement(Text, {
    as: "p"
  }, promptText)))), React.createElement(Box, {
    mtScale: "s"
  }, React.createElement(FormikError, {
    name: name
  })));
};

FormBodyFileField.mapDataToProps = ({
  data,
  meta
}) => {
  var _data$primary, _data$primary2, _data$primary2$label, _data$primary3, _data$primary3$label, _data$primary4;

  return {
    isRequired: boolean(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.required),
    label: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$label = _data$primary2.label) === null || _data$primary2$label === void 0 ? void 0 : _data$primary2$label.text,
    name: camelCase(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$label = _data$primary3.label) === null || _data$primary3$label === void 0 ? void 0 : _data$primary3$label.text),
    helpTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.help_text),
    setFieldValue: meta === null || meta === void 0 ? void 0 : meta.setFieldValue,
    setFieldTouched: meta === null || meta === void 0 ? void 0 : meta.setFieldTouched,
    validateForm: meta === null || meta === void 0 ? void 0 : meta.validateForm
  };
};

FormBodyFileField.validationSchema = ({
  data
}) => {
  var _data$primary5, _data$primary6;

  const isRequired = boolean(data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : _data$primary5.required);
  const maximumKB = (data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : _data$primary6.maximum_file_size) || 250;
  const maximumBytes = maximumKB * 1000;
  let schema = yupMixed();
  if (isRequired) schema = schema.required('Please upload a file.');else schema = schema.notRequired();
  schema = schema.test('value is a file', "We were unable to process this file. Please try again.", file => file instanceof File);
  schema = schema.test('file-size', "Your file exceeds the limit of " + maximumKB + "KB", file => {
    if (!file) return true;
    return file.size < maximumBytes;
  });
  return schema;
};

FormBodyFileField.initialValues = () => null;

export const fragment = "4253535748";