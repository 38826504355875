import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import React from "react";
import loader, { PageResourceStatus } from "./loader";
import shallowCompare from "shallow-compare";

class EnsureResources extends React.Component {
  constructor(props) {
    super();
    const {
      location,
      pageResources
    } = props;
    this.state = {
      location: _objectSpread({}, location),
      pageResources: pageResources || loader.loadPageSync(location.pathname)
    };
  }

  static getDerivedStateFromProps({
    location
  }, prevState) {
    if (prevState.location.href !== location.href) {
      const pageResources = loader.loadPageSync(location.pathname);
      return {
        pageResources,
        location: _objectSpread({}, location)
      };
    }

    return {
      location: _objectSpread({}, location)
    };
  }

  loadResources(rawPath) {
    loader.loadPage(rawPath).then(pageResources => {
      if (pageResources && pageResources.status !== PageResourceStatus.Error) {
        this.setState({
          location: _objectSpread({}, window.location),
          pageResources
        });
      } else {
        window.history.replaceState({}, "", location.href);
        window.location = rawPath;
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Always return false if we're missing resources.
    if (!nextState.pageResources) {
      this.loadResources(nextProps.location.pathname);
      return false;
    } // Check if the component or json have changed.


    if (this.state.pageResources !== nextState.pageResources) {
      return true;
    }

    if (this.state.pageResources.component !== nextState.pageResources.component) {
      return true;
    }

    if (this.state.pageResources.json !== nextState.pageResources.json) {
      return true;
    } // Check if location has changed on a page using internal routing
    // via matchPath configuration.


    if (this.state.location.key !== nextState.location.key && nextState.pageResources.page && (nextState.pageResources.page.matchPath || nextState.pageResources.page.path)) {
      return true;
    }

    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    if (process.env.NODE_ENV !== "production" && !this.state.pageResources) {
      throw new Error("EnsureResources was not able to find resources for path: \"" + this.props.location.pathname + "\"\nThis typically means that an issue occurred building components for that path.\nRun `gatsby clean` to remove any cached elements.");
    }

    return this.props.children(this.state);
  }

}

export default EnsureResources;