import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { Flex } from 'system';
import { ArrowCircle } from 'src/components/ArrowCircle';
import { Subheading } from 'src/components/Subheading';
import { Anchor } from 'src/components/Anchor';
const colorSchemes = {
  yellow: {
    buttonBg: 'yellow.80',
    buttonHover: 'red.40'
  },
  orange: {
    buttonBg: 'orange.60',
    buttonHover: 'yellow.80'
  }
};

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "BackButton___StyledFlex",
  componentId: "sc-14vhhvf-0"
})(["&:hover,&:focus{.arrow-button{background-color:", ";}}"], p => p._css);

export const BackButton = _ref => {
  let {
    children,
    href,
    colorScheme = 'yellow'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "href", "colorScheme"]);

  const colors = colorSchemes[colorScheme];
  return React.createElement(_StyledFlex, Object.assign({
    as: Anchor,
    href: href,
    display: "inline-flex",
    alignItems: "center"
  }, props, {
    _css: themeGet("colors." + colors.buttonHover)
  }), React.createElement(ArrowCircle, {
    mrScale: "t",
    bg: colors.buttonBg,
    className: "arrow-button"
  }), React.createElement(Subheading, {
    as: "p",
    color: "inherit"
  }, children));
};