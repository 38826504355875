import _styled from "styled-components";
import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { getRichText } from 'helpers';
import { string as yupString } from 'yup';
import { boolean } from 'boolean';
import { FastField as FastFormikField } from 'formik';
import { camelCase } from 'tiny-compose-fns';
import { Box, Input } from 'system';
import { HTMLContent } from 'src/components/HTMLContent';
import { FormikError } from 'src/components/FormikError';
import { Subheading } from 'src/components/Subheading';

var _StyledInput = _styled(Input).withConfig({
  displayName: "FormBodyTextarea___StyledInput",
  componentId: "sc-13qykjn-0"
})(["resize:none;&::placeholder{color:", ";}"], p => p._css);

export const FormBodyTextarea = ({
  isRequired,
  placeholderText,
  label,
  name,
  helpTextHTML
}) => React.createElement(Box, {
  gridColumn: [1, 'span 2'],
  boxStyle: "lastNoMargin"
}, React.createElement(Box, null, label && React.createElement(Subheading, {
  as: "label",
  color: "teal.40",
  mbScale: "s",
  htmlFor: name
}, label), helpTextHTML && React.createElement(HTMLContent, {
  html: helpTextHTML,
  mbScale: "s"
}), React.createElement(_StyledInput, {
  as: FastFormikField,
  component: "textarea",
  name: name,
  id: name,
  placeholder: placeholderText,
  required: isRequired,
  pyScale: "t+",
  pxScale: "s",
  border: ['1px solid', '2px solid'],
  borderColor: ['gray.90', 'gray.90'],
  borderRadius: "8px",
  fontSizeScale: "m",
  width: "100%",
  height: ['8rem', '14rem'],
  _css: themeGet('colors.gray.90')
}), React.createElement(FormikError, {
  name: name
})));

FormBodyTextarea.mapDataToProps = ({
  data
}) => {
  var _data$primary, _data$primary2, _data$primary2$placeh, _data$primary3, _data$primary3$label, _data$primary4, _data$primary4$label, _data$primary5;

  return {
    isRequired: boolean(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.required),
    placeholderText: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$placeh = _data$primary2.placeholder_text) === null || _data$primary2$placeh === void 0 ? void 0 : _data$primary2$placeh.text,
    label: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$label = _data$primary3.label) === null || _data$primary3$label === void 0 ? void 0 : _data$primary3$label.text,
    name: camelCase(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$label = _data$primary4.label) === null || _data$primary4$label === void 0 ? void 0 : _data$primary4$label.text),
    helpTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : _data$primary5.help_text)
  };
};

FormBodyTextarea.validationSchema = ({
  data
}) => {
  var _data$primary6;

  const isRequired = boolean(data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : _data$primary6.required);
  let schema = yupString();
  if (isRequired) schema = schema.required('Please fill out this field.');else schema = schema.notRequired();
  return schema;
};

FormBodyTextarea.initialValues = () => '';

export const fragment = "507290339";