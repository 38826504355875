import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.match";
import { apiRunner, apiRunnerAsync } from "./api-runner-browser";
import React from "react";
import ReactDOM from "react-dom";
import { Router, navigate, Location, BaseContext } from "@reach/router";
import { ScrollContext } from "gatsby-react-router-scroll";
import domReady from "@mikaelkristiansson/domready";
import { shouldUpdateScroll, init as navigationInit, RouteUpdates } from "./navigation";
import emitter from "./emitter";
import PageRenderer from "./page-renderer";
import asyncRequires from "./async-requires";
import { setLoader, ProdLoader, publicLoader, PageResourceStatus } from "./loader";
import EnsureResources from "./ensure-resources";
import stripPrefix from "./strip-prefix"; // Generated during bootstrap

import matchPaths from "./match-paths.json";
const loader = new ProdLoader(asyncRequires, matchPaths);
setLoader(loader);
loader.setApiRunner(apiRunner);
window.asyncRequires = asyncRequires;
window.___emitter = emitter;
window.___loader = publicLoader;
navigationInit();
apiRunnerAsync("onClientEntry").then(() => {
  // Let plugins register a service worker. The plugin just needs
  // to return true.
  if (apiRunner("registerServiceWorker").length > 0) {
    require("./register-service-worker");
  } // In gatsby v2 if Router is used in page using matchPaths
  // paths need to contain full path.
  // For example:
  //   - page have `/app/*` matchPath
  //   - inside template user needs to use `/app/xyz` as path
  // Resetting `basepath`/`baseuri` keeps current behaviour
  // to not introduce breaking change.
  // Remove this in v3


  const RouteHandler = props => React.createElement(BaseContext.Provider, {
    value: {
      baseuri: "/",
      basepath: "/"
    }
  }, React.createElement(PageRenderer, props));

  class LocationHandler extends React.Component {
    render() {
      const {
        location
      } = this.props;
      return React.createElement(EnsureResources, {
        location: location
      }, ({
        pageResources,
        location
      }) => React.createElement(RouteUpdates, {
        location: location
      }, React.createElement(ScrollContext, {
        location: location,
        shouldUpdateScroll: shouldUpdateScroll
      }, React.createElement(Router, {
        basepath: __BASE_PATH__,
        location: location,
        id: "gatsby-focus-wrapper"
      }, React.createElement(RouteHandler, Object.assign({
        path: pageResources.page.path === "/404.html" ? stripPrefix(location.pathname, __BASE_PATH__) : encodeURI(pageResources.page.matchPath || pageResources.page.path)
      }, this.props, {
        location: location,
        pageResources: pageResources
      }, pageResources.json))))));
    }

  }

  const {
    pagePath,
    location: browserLoc
  } = window; // Explicitly call navigate if the canonical path (window.pagePath)
  // is different to the browser path (window.location.pathname). But
  // only if NONE of the following conditions hold:
  //
  // - The url matches a client side route (page.matchPath)
  // - it's a 404 page
  // - it's the offline plugin shell (/offline-plugin-app-shell-fallback/)

  if (pagePath && __BASE_PATH__ + pagePath !== browserLoc.pathname && !(loader.findMatchPath(stripPrefix(browserLoc.pathname, __BASE_PATH__)) || pagePath === "/404.html" || pagePath.match(/^\/404\/?$/) || pagePath.match(/^\/offline-plugin-app-shell-fallback\/?$/))) {
    navigate(__BASE_PATH__ + pagePath + browserLoc.search + browserLoc.hash, {
      replace: true
    });
  }

  publicLoader.loadPage(browserLoc.pathname).then(page => {
    if (!page || page.status === PageResourceStatus.Error) {
      throw new Error("page resources for " + browserLoc.pathname + " not found. Not rendering React");
    }

    window.___webpackCompilationHash = page.page.webpackCompilationHash;

    const Root = () => React.createElement(Location, null, locationContext => React.createElement(LocationHandler, locationContext));

    const WrappedRoot = apiRunner("wrapRootElement", {
      element: React.createElement(Root, null)
    }, React.createElement(Root, null), ({
      result
    }) => {
      return {
        element: result
      };
    }).pop();

    const NewRoot = () => WrappedRoot;

    const renderer = apiRunner("replaceHydrateFunction", undefined, ReactDOM.hydrate)[0];
    domReady(() => {
      renderer(React.createElement(NewRoot, null), typeof window !== "undefined" ? document.getElementById("___gatsby") : void 0, () => {
        apiRunner("onInitialClientRender");
      });
    });
  });
});