import React from 'react';
import { getRichText } from 'helpers';
import { string as yupString } from 'yup';
import { FastField as FastFormikField } from 'formik';
import { camelCase, map, isEmpty } from 'tiny-compose-fns';
import { Box, Flex } from 'system';
import { RadioField } from 'src/components/RadioField';
import { Subheading } from 'src/components/Subheading';
import { HTMLContent } from 'src/components/HTMLContent';
export const FormBodyRadioField = ({
  label,
  name,
  helpTextHTML,
  options,
  defaultOption
}) => {
  return React.createElement(Box, {
    gridColumn: [1, 'span 2'],
    boxStyle: "lastNoMargin"
  }, label && React.createElement(Subheading, {
    color: "teal.40",
    mbScale: "t"
  }, label), helpTextHTML && React.createElement(HTMLContent, {
    html: helpTextHTML,
    mbScale: "s"
  }), React.createElement(Flex, null, map(option => React.createElement(RadioField, {
    as: FastFormikField,
    name: name,
    value: option,
    label: option,
    defaultChecked: option === defaultOption,
    mrScale: "l",
    boxStyle: "lastNoMargin"
  }), options)));
};

FormBodyRadioField.mapDataToProps = ({
  data
}) => {
  var _data$primary, _data$primary$default, _data$primary2, _data$primary2$label, _data$primary3, _data$primary3$label, _data$primary4;

  return {
    defaultOption: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$default = _data$primary.default_option) === null || _data$primary$default === void 0 ? void 0 : _data$primary$default.text,
    label: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$label = _data$primary2.label) === null || _data$primary2$label === void 0 ? void 0 : _data$primary2$label.text,
    name: camelCase(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$label = _data$primary3.label) === null || _data$primary3$label === void 0 ? void 0 : _data$primary3$label.text),
    helpTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.help_text),
    options: map(item => {
      var _item$option;

      return item === null || item === void 0 ? void 0 : (_item$option = item.option) === null || _item$option === void 0 ? void 0 : _item$option.text;
    }, data === null || data === void 0 ? void 0 : data.items)
  };
};

FormBodyRadioField.validationSchema = ({
  data
}) => {
  var _data$primary5, _data$primary5$defaul;

  const defaultOption = data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$defaul = _data$primary5.default_option) === null || _data$primary5$defaul === void 0 ? void 0 : _data$primary5$defaul.text;
  let schema = yupString().notRequired();
  if (!isEmpty(defaultOption)) schema = schema.default(defaultOption);
  return schema;
};

FormBodyRadioField.initialValues = ({
  data
}) => {
  var _data$primary6, _data$primary6$defaul;

  return data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : (_data$primary6$defaul = _data$primary6.default_option) === null || _data$primary6$defaul === void 0 ? void 0 : _data$primary6$defaul.text;
};

export const fragment = "377681249";