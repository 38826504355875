import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty, getRichText } from 'helpers';
import { addScales, negateScale, mergeScalesLeft } from 'styled-system-scale';
import { camelCase, isEmpty, map } from 'tiny-compose-fns';
import { theme } from 'src/theme';
import { nodesPathFor } from 'src/helpers';
import { Box, Flex, AspectRatio, ImageContainer, Image } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { HTMLContent } from 'src/components/HTMLContent';
import AssetMediaJali from 'src/assets/media-jali.png';
const colorSchemes = {
  teal: {
    bg: 'teal.75',
    color: 'white',
    jaliOpacity: 0.15
  },
  darkTeal: {
    bg: 'teal.40',
    color: 'white',
    jaliOpacity: 0.08
  }
};
export const PageBodyHeroImage = _ref => {
  let {
    colorScheme = 'teal',
    textHTML,
    imageURL,
    imageFluid,
    imageAlt,
    previousIsHeader = false,
    nodesPath
  } = _ref,
      props = _objectWithoutProperties(_ref, ["colorScheme", "textHTML", "imageURL", "imageFluid", "imageAlt", "previousIsHeader", "nodesPath"]);

  const colors = colorSchemes[colorScheme];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: colors.bg,
    color: colors.color,
    position: "relative",
    ptAdd: previousIsHeader && negateScale(theme.spaceScales.xxl),
    previousIsHeader: previousIsHeader
  }, props), React.createElement(Box, {
    bottom: 0,
    left: 0,
    overflow: "hidden",
    position: "absolute",
    top: ['15%', null, null, '20%'],
    width: ['200%', '100%', null, '85%']
  }, React.createElement(Image, {
    src: AssetMediaJali,
    height: "auto",
    opacity: colors.jaliOpacity,
    transform: "translateX(-25%)"
  })), React.createElement(Flex, {
    flexDirection: ['column', 'row'],
    position: "relative"
  }, React.createElement(Box, {
    mtScale: ['s', 'm'],
    mbScale: ['xl', 0],
    prScale: [0, 'l'],
    width: [1, 2 / 6],
    flex: "0 0 auto",
    alignSelf: "center"
  }, !isEmpty(nodesPath) && React.createElement(Breadcrumbs, {
    mbScale: "s+",
    color: "white"
  }, map(node => {
    var _node$data, _node$data$title;

    return React.createElement(Breadcrumbs.Item, {
      href: node === null || node === void 0 ? void 0 : node.url,
      key: node === null || node === void 0 ? void 0 : node.url
    }, node === null || node === void 0 ? void 0 : (_node$data = node.data) === null || _node$data === void 0 ? void 0 : (_node$data$title = _node$data.title) === null || _node$data$title === void 0 ? void 0 : _node$data$title.text);
  }, nodesPath)), React.createElement(HTMLContent, {
    html: textHTML,
    fontWeight: "semibold",
    fontSizeScale: "xl"
  })), React.createElement(ImageContainer // Merging scales here in a pretty wonky way to accomodate for the missing PageBodyLargeText
  // slice. This is probably really fragile, and will result in junk styles if the
  // PageBodyLargeText slice is ever put back in.
  , {
    mb: mergeScalesLeft(addScales(negateScale(theme.spaceScales.xxl), negateScale(theme.spaceScales.xxl)), ['-4.375rem']),
    mr: mergeScalesLeft(negateScale(theme.spaceScales.l), [undefined, 'calc(-1 * (100vw - 100%) / 2)', 'calc(-1 * (100vw - 100%) / 2)', 'calc(-1 * (100vw - 100%) / 2)']),
    width: ['auto', 'calc((100vw - 100%) / 2 + 4 / 6 * 100%)'],
    flex: "1 0 auto",
    position: "relative",
    bg: "gray.20",
    zIndex: "heroSliceImage"
  }, React.createElement(AspectRatio, {
    x: 16,
    y: 10,
    minHeight: "100%"
  }, React.createElement(Image, {
    src: imageURL,
    fluid: imageFluid,
    alt: imageAlt,
    loading: "eager",
    fadeIn: false
  })))));
};

PageBodyHeroImage.mapDataToProps = ({
  data,
  previousType,
  previousContext,
  nextContext,
  meta
}) => {
  var _data$primary, _data$primary2, _data$primary3, _data$primary3$image, _data$primary4, _data$primary4$image, _data$primary5, _data$primary5$image;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    colorScheme: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.color_scheme),
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.text),
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$image = _data$primary3.image) === null || _data$primary3$image === void 0 ? void 0 : _data$primary3$image.fluid,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$image = _data$primary4.image) === null || _data$primary4$image === void 0 ? void 0 : _data$primary4$image.url,
    imageAlt: notEmpty(data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$image = _data$primary5.image) === null || _data$primary5$image === void 0 ? void 0 : _data$primary5$image.alt),
    nodesPath: nodesPathFor(meta === null || meta === void 0 ? void 0 : meta.page)
  };
};

PageBodyHeroImage.mapDataToContext = () => ({
  overhangsNext: true
});

export const fragment = "3756456116";