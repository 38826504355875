import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Box, Grid, Image, AspectRatio } from 'system';
import { Heading } from 'src/components/Heading';
export const StaffMemberCard = _ref => {
  let {
    name,
    imageFluid,
    imageURL,
    positionTitle
  } = _ref,
      props = _objectWithoutProperties(_ref, ["name", "imageFluid", "imageURL", "positionTitle"]);

  return React.createElement(Grid, Object.assign({
    gridRowGap: '1rem',
    gridTemplateRows: "auto 1fr"
  }, props), React.createElement(AspectRatio, {
    x: 1,
    y: 1
  }, imageFluid || imageURL ? React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: name,
    borderRadius: "50%",
    backgroundColor: "gray.90"
  }) : React.createElement(Box, {
    borderRadius: "50%",
    backgroundColor: "gray.90",
    height: "100%"
  })), React.createElement(Box, null, name && React.createElement(Heading, {
    as: "h3",
    color: "gray.20",
    textAlign: "center"
  }, name), positionTitle && React.createElement(Heading, {
    as: "h6",
    color: "gray.20",
    fontSizeScale: "m",
    lineHeight: "copy",
    maxWidth: "28ch",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  }, positionTitle)));
};