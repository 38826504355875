import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { motion } from 'framer-motion';
import { themeGet } from '@styled-system/theme-get';
import { Box, Flex, AspectRatio, Image, ImageContainer } from 'system';
import { OVERHANG_SPACE_NEGATIVE } from 'src/constants';
import { useSlideUp } from 'src/hooks/useSlideUp';
import { Anchor } from 'src/components/Anchor';
import { Card } from 'src/components/Card';
import { HTMLContent } from 'src/components/HTMLContent';
const positions = {
  bottomLeftYOverhang: {
    main: {
      flexDirection: 'column',
      alignItems: null
    },
    image: {
      width: 1
    },
    imageAspectRatio: {
      x: 4,
      y: 3
    },
    cardWrapper: {
      width: [3 / 4],
      mt: OVERHANG_SPACE_NEGATIVE
    },
    card: {
      innerProps: {
        pScale: 'm',
        plScale: [0, null, 'm'],
        pbScale: [0, null, 'm']
      }
    }
  },
  bottomLeftYOverhangWide: {
    main: {
      flexDirection: 'column',
      alignItems: null
    },
    image: {
      width: 1
    },
    imageAspectRatio: {
      x: 4,
      y: 3
    },
    cardWrapper: {
      mtScale: ['-l', '-xxl'],
      width: [3 / 4, 1 / 2]
    },
    card: {
      innerProps: {
        pScale: 'm',
        plScale: [0, null, 'm'],
        pbScale: [0, null, 'm']
      }
    }
  },
  bottomRightYOverhangWide: {
    main: {
      flexDirection: 'column',
      alignItems: [null, null, null, 'flex-end']
    },
    image: {
      width: 1
    },
    imageAspectRatio: {
      x: 1,
      y: 1
    },
    cardWrapper: {
      width: 3 / 4,
      mtScale: ['-l', '-xxl']
    },
    card: {
      innerProps: {
        pScale: 'm',
        plScale: [0, null, 'm'],
        pbScale: [0, null, 'm']
      }
    }
  },
  bottomRightXOverhang: {
    main: {
      flexDirection: ['column', null, null, 'row'],
      alignItems: [null, null, null, 'flex-end']
    },
    image: {
      width: [1, null, null, 4 / 6]
    },
    imageAspectRatio: {
      x: 4,
      y: 3
    },
    cardWrapper: {
      width: [3 / 4, null, null, 3 / 6],
      mtScale: ['-l', null, null, 'l'],
      ml: [null, null, null, "calc(-1 * 100% / 6)"]
    },
    card: {
      innerProps: {
        pScale: 'm',
        plScale: [0, null, 'm'],
        pbScale: [0, null, 'm']
      }
    }
  }
};

var _StyledBox = _styled(Box).withConfig({
  displayName: "PhotoCard___StyledBox",
  componentId: "sc-1kb39hp-0"
})(["&:hover{& + .card-wrapper{color:", ";}}"], p => p._css);

export const PhotoCard = _ref => {
  let {
    href,
    target,
    imageFluid,
    imageURL,
    imageAlt,
    subheading,
    heading,
    description,
    detail,
    cardPosition = 'bottomRightXOverhang'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["href", "target", "imageFluid", "imageURL", "imageAlt", "subheading", "heading", "description", "detail", "cardPosition"]);

  const [ref, animate] = useSlideUp();
  const pos = positions[cardPosition];
  return React.createElement(Flex, Object.assign({
    ref: ref
  }, pos.main, props), (imageURL || imageFluid) && React.createElement(_StyledBox, Object.assign({
    alignSelf: "stretch",
    overflow: "hidden"
  }, pos.image, {
    _css: themeGet(href ? 'colors.teal.70' : 'colors.gray.20')
  }), React.createElement(ConditionalWrap, {
    condition: href,
    wrap: children => React.createElement(Anchor, {
      href: href,
      target: target,
      height: "100%",
      tabIndex: "-1"
    }, children)
  }, React.createElement(ImageContainer, {
    height: "100%",
    maxHeight: [null, '30rem', '40rem', '45rem'],
    bg: "gray.20"
  }, React.createElement(AspectRatio, Object.assign({
    height: "100%"
  }, pos.imageAspectRatio), React.createElement(Image, {
    src: imageURL,
    fluid: imageFluid,
    alt: imageAlt
  }))))), React.createElement(Box, Object.assign({
    as: motion.div,
    initial: false,
    animate: animate,
    className: "card-wrapper",
    position: "relative",
    flex: "0 1 auto"
  }, pos.cardWrapper), React.createElement(Card, Object.assign({
    detail: detail,
    heading: heading,
    headingHref: href,
    headingTarget: target,
    subheading: subheading
  }, pos.card), React.createElement(HTMLContent, {
    html: description
  }))));
};