import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import HTMLRenderer from 'react-html-renderer';
import { themeGet } from '@styled-system/theme-get';
import { Box, Text, AspectRatio } from 'system';
import { Anchor } from 'src/components/Anchor';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { Heading } from 'src/components/Heading';
import { Subheading } from 'src/components/Subheading';
import { EmptyCard } from 'src/components/EmptyCard';
const baseHeadingProps = {
  mtScale: 's+',
  mbScale: 's',
  boxStyle: 'firstLastNoMargin'
};

var _StyledBox = _styled(Box).withConfig({
  displayName: "HTMLContent___StyledBox",
  componentId: "sc-1ex7zec-0"
})(["li{list-style:decimal;}"]);

var _StyledText = _styled(Text).withConfig({
  displayName: "HTMLContent___StyledText",
  componentId: "sc-1ex7zec-1"
})(["list-style:disc;display:list-item;"]);

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "HTMLContent___StyledAnchor",
  componentId: "sc-1ex7zec-2"
})(["&:hover,&:focus{color:", ";}"], p => p._css);

var _StyledBox2 = _styled(Box).withConfig({
  displayName: "HTMLContent___StyledBox2",
  componentId: "sc-1ex7zec-3"
})(["width:100% !important;height:100% !important;"]);

const defaultMap = {
  h1: props => React.createElement(Heading, Object.assign({
    as: "h3",
    fontSizeScale: "l"
  }, baseHeadingProps, props)),
  h2: props => React.createElement(Heading, Object.assign({
    as: "h4"
  }, baseHeadingProps, {
    mbScale: "t-",
    fontSizeScale: "b"
  }, props)),
  h3: props => React.createElement(Subheading, Object.assign({
    as: "h5"
  }, baseHeadingProps, props)),
  h4: props => React.createElement(Heading, Object.assign({
    as: "h6"
  }, baseHeadingProps, {
    fontSize: ['normal', null, 'medium', 'large'],
    fontWeight: "normal"
  }, props)),
  h5: props => React.createElement(Subheading, Object.assign({
    as: "h6"
  }, baseHeadingProps, props)),
  h6: props => React.createElement(Subheading, Object.assign({
    as: "h6"
  }, baseHeadingProps, props)),
  p: props => React.createElement(Text, Object.assign({
    as: "p",
    mbScale: "s",
    boxStyle: "lastNoMargin"
  }, props)),
  ul: props => React.createElement(Box, Object.assign({
    as: "ul",
    plScale: "s+",
    mbScale: "s",
    boxStyle: "lastNoMargin"
  }, props)),
  ol: props => React.createElement(_StyledBox, Object.assign({
    as: "ol",
    plScale: "s+",
    mbScale: "s",
    boxStyle: "lastNoMargin"
  }, props)),
  li: props => React.createElement(_StyledText, Object.assign({
    as: "li",
    mbScale: "t-",
    boxStyle: "lastNoMargin"
  }, props)),
  a: props => React.createElement(_StyledAnchor, Object.assign({
    transitionProperty: "color",
    color: "orange.60"
  }, props, {
    _css: themeGet('colors.teal.70')
  })),
  strong: props => React.createElement(Text, Object.assign({
    as: "strong",
    display: "inline",
    fontWeight: "semibold"
  }, props)),
  div: props => {
    if (props['data-oembed-type'] === 'video') return React.createElement(AspectRatio, Object.assign({
      x: 16,
      y: 9
    }, props));
    return React.createElement(Box, props);
  },
  iframe: props => React.createElement(ErrorBoundary, {
    fallback: React.createElement(EmptyCard, {
      myScale: "m",
      textHTML: '<h1>Sorry! We had trouble loading some embedded content.</h1>'
    })
  }, React.createElement(_StyledBox2, Object.assign({
    as: "iframe"
  }, props)))
};
export const HTMLContent = _ref => {
  let {
    html,
    map
  } = _ref,
      props = _objectWithoutProperties(_ref, ["html", "map"]);

  return React.createElement(Text, Object.assign({
    lineHeight: "copy",
    fontSizeScale: "b"
  }, props), html && React.createElement(HTMLRenderer, {
    html: html,
    components: _objectSpread({}, defaultMap, {}, map)
  }));
};