import React from 'react';
import { getRichText } from 'helpers';
import { Box } from 'system';
import { Heading } from 'src/components/Heading';
import { HTMLContent } from 'src/components/HTMLContent';
import { Subheading } from 'src/components/Subheading';
export const FormBodyHelpText = ({
  label,
  textHTML
}) => React.createElement(Box, {
  gridColumn: [1, 'span 2'],
  boxStyle: "lastNoMargin"
}, label && React.createElement(Subheading, {
  color: "teal.40",
  mbScale: "t"
}, label), textHTML && React.createElement(HTMLContent, {
  html: textHTML,
  map: {
    h1: props => React.createElement(Heading, Object.assign({
      fontSizeScale: "m",
      mbScale: "s",
      fontWeight: "semibold"
    }, props))
  }
}));

FormBodyHelpText.mapDataToProps = ({
  data
}) => {
  var _data$primary, _data$primary$label, _data$primary2;

  return {
    label: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$label = _data$primary.label) === null || _data$primary$label === void 0 ? void 0 : _data$primary$label.text,
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.text)
  };
};

export const fragment = "2864178531";