import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { getPrimaryMetadataType, getRichText, notEmpty, metadataBasename } from 'helpers';
import { compose, camelCase } from 'tiny-compose-fns';
import { getPrimaryMetadata, propPairsEq } from 'src/helpers';
import { Box, Flex } from 'system';
import { Anchor } from 'src/components/Anchor';
import { BoundedBox } from 'src/components/BoundedBox';
import { EmptyCard } from 'src/components/EmptyCard';
import { Heading } from 'src/components/Heading';
import { PhotoCard } from 'src/components/PhotoCard';
import { Subheading } from 'src/components/Subheading';

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "PageBodyGroupedExperiences___StyledAnchor",
  componentId: "sc-13bm3bx-0"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css);

const ItemsGroup = _ref => {
  let {
    buttonHref,
    buttonTarget,
    buttonText,
    children,
    emptyButtonHref,
    emptyButtonTarget,
    emptyButtonText,
    emptyTextHTML,
    heading
  } = _ref,
      props = _objectWithoutProperties(_ref, ["buttonHref", "buttonTarget", "buttonText", "children", "emptyButtonHref", "emptyButtonTarget", "emptyButtonText", "emptyTextHTML", "heading"]);

  return React.createElement(Box, Object.assign({
    width: [1, 1 / 2],
    mrScale: "l",
    boxStyle: "lastNoMargin"
  }, props), heading && React.createElement(Flex, {
    alignItems: "baseline",
    mbScale: "s"
  }, React.createElement(Heading, {
    mrScale: "s"
  }, heading), buttonHref && buttonText && React.createElement(Subheading, {
    as: "p",
    color: "orange.60"
  }, React.createElement(_StyledAnchor, {
    href: buttonHref,
    target: buttonTarget,
    transitionProperty: "color",
    _css: themeGet('colors.teal.70')
  }, buttonText))), React.Children.count(children) > 0 ? React.createElement(Box, {
    as: "ul"
  }, children) : emptyTextHTML && React.createElement(EmptyCard, {
    buttonHref: emptyButtonHref,
    buttonTarget: emptyButtonTarget,
    buttonText: emptyButtonText,
    textHTML: emptyTextHTML
  }));
};

export const PageBodyGroupedExperiences = _ref2 => {
  let {
    heading,
    column1Heading,
    column1ButtonText,
    column1ButtonHref,
    column1ButtonTarget,
    column1Experiences,
    column1EmptyTextHTML,
    column1EmptyButtonText,
    column1EmptyButtonHref,
    column1EmptyButtonTarget,
    column2Heading,
    column2ButtonText,
    column2ButtonHref,
    column2ButtonTarget,
    column2Experiences,
    column2EmptyTextHTML,
    column2EmptyButtonText,
    column2EmptyButtonHref,
    column2EmptyButtonTarget
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["heading", "column1Heading", "column1ButtonText", "column1ButtonHref", "column1ButtonTarget", "column1Experiences", "column1EmptyTextHTML", "column1EmptyButtonText", "column1EmptyButtonHref", "column1EmptyButtonTarget", "column2Heading", "column2ButtonText", "column2ButtonHref", "column2ButtonTarget", "column2Experiences", "column2EmptyTextHTML", "column2EmptyButtonText", "column2EmptyButtonHref", "column2EmptyButtonTarget"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white"
  }, props), heading && React.createElement(Heading, {
    fontSizeScale: "xl",
    textAlign: [null, 'center'],
    mbScale: "l"
  }, heading), React.createElement(Flex, {
    flexDirection: ['column', 'row']
  }, React.createElement(ItemsGroup, {
    heading: column1Heading,
    buttonText: column1ButtonText,
    buttonHref: column1ButtonHref,
    buttonTarget: column1ButtonTarget,
    emptyTextHTML: column1EmptyTextHTML,
    emptyButtonText: column1EmptyButtonText,
    emptyButtonHref: column1EmptyButtonHref,
    emptyButtonTarget: column1EmptyButtonTarget
  }, column1Experiences === null || column1Experiences === void 0 ? void 0 : column1Experiences.map(item => React.createElement(Box, {
    as: "li",
    key: item === null || item === void 0 ? void 0 : item.key,
    mbScale: "m",
    boxStyle: "lastNoMargin"
  }, item))), React.createElement(Box, {
    as: "hr",
    display: ['block', 'none'],
    border: "0",
    height: "1px",
    bg: "gray.90",
    my: 5
  }), React.createElement(ItemsGroup, {
    heading: column2Heading,
    buttonText: column2ButtonText,
    buttonHref: column2ButtonHref,
    buttonTarget: column2ButtonTarget,
    emptyTextHTML: column2EmptyTextHTML,
    emptyButtonText: column2EmptyButtonText,
    emptyButtonHref: column2EmptyButtonHref,
    emptyButtonTarget: column2EmptyButtonTarget
  }, column2Experiences === null || column2Experiences === void 0 ? void 0 : column2Experiences.map(item => React.createElement(Box, {
    as: "li",
    key: item === null || item === void 0 ? void 0 : item.key,
    mbScale: "m",
    boxStyle: "lastNoMargin"
  }, item)))));
};

PageBodyGroupedExperiences.mapDataToProps = ({
  index,
  data,
  context,
  previousContext,
  nextContext
}) => {
  var _data$items, _data$primary, _data$primary$heading, _data$primary2, _data$primary2$column, _data$primary3, _data$primary4, _data$primary4$column, _data$primary5, _data$primary5$column, _data$primary6, _data$primary7, _data$primary8, _data$primary8$column, _data$primary9, _data$primary9$column, _data$primary10, _data$primary10$colum, _data$primary11, _data$primary12, _data$primary12$colum, _data$primary13, _data$primary13$colum, _data$primary14, _data$primary15, _data$primary16, _data$primary16$colum, _data$primary17, _data$primary17$colum, _filterByColumn, _filterByColumn2;

  const experiences = data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.map(item => {
    var _item$experience, _doc$data, _doc$data$title, _metadata$primary, _metadata$primary2, _metadata$primary3, _metadata$primary4, _metadata$primary5, _metadata$primary6, _metadata$primary6$fe, _metadata$primary7, _metadata$primary7$fe, _metadata$primary8, _metadata$primary8$fe;

    const doc = item === null || item === void 0 ? void 0 : (_item$experience = item.experience) === null || _item$experience === void 0 ? void 0 : _item$experience.document;
    const metadata = getPrimaryMetadata(doc);
    return {
      uid: doc === null || doc === void 0 ? void 0 : doc.uid,
      href: doc === null || doc === void 0 ? void 0 : doc.url,
      type: compose(metadataBasename, getPrimaryMetadataType)(doc),
      column: camelCase(item === null || item === void 0 ? void 0 : item.column),
      size: camelCase(item === null || item === void 0 ? void 0 : item.display_size),
      heading: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : (_doc$data$title = _doc$data.title) === null || _doc$data$title === void 0 ? void 0 : _doc$data$title.text,
      description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : _metadata$primary.description,
      startDate: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.start_date,
      endDate: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : _metadata$primary3.end_date,
      dates: [metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : _metadata$primary4.start_date, metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : _metadata$primary5.end_date].filter(Boolean).join(' - '),
      imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$fe = _metadata$primary6.featured_image) === null || _metadata$primary6$fe === void 0 ? void 0 : _metadata$primary6$fe.url,
      imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary7 = metadata.primary) === null || _metadata$primary7 === void 0 ? void 0 : (_metadata$primary7$fe = _metadata$primary7.featured_image) === null || _metadata$primary7$fe === void 0 ? void 0 : _metadata$primary7$fe.fluid,
      imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary8 = metadata.primary) === null || _metadata$primary8 === void 0 ? void 0 : (_metadata$primary8$fe = _metadata$primary8.featured_image) === null || _metadata$primary8$fe === void 0 ? void 0 : _metadata$primary8$fe.alt)
    };
  });

  const toPhotoCard = item => React.createElement(PhotoCard, {
    key: item.uid,
    description: item.description,
    detail: item.dates,
    heading: item.heading,
    href: item.href,
    imageAlt: item.imageAlt,
    imageFluid: item.imageFluid,
    imageURL: item.imageURL,
    subheading: item.type,
    cardPosition: (item === null || item === void 0 ? void 0 : item.size) === 'large' ? 'bottomRightYOverhangWide' : 'bottomRightXOverhang'
  });

  const filterByColumn = (column, arr = []) => arr === null || arr === void 0 ? void 0 : arr.filter(x => x.column === column);

  return {
    isFirst: index === 1,
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    heading: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$heading = _data$primary.heading) === null || _data$primary$heading === void 0 ? void 0 : _data$primary$heading.text,
    column1Heading: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$column = _data$primary2.column_1_heading) === null || _data$primary2$column === void 0 ? void 0 : _data$primary2$column.text,
    column1ButtonText: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.column_1_button_text,
    column1ButtonHref: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$column = _data$primary4.column_1_button_link) === null || _data$primary4$column === void 0 ? void 0 : _data$primary4$column.url,
    column1ButtonTarget: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$column = _data$primary5.column_1_button_link) === null || _data$primary5$column === void 0 ? void 0 : _data$primary5$column.target,
    column1EmptyTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : _data$primary6.column_1_empty_text),
    column1EmptyButtonText: data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : _data$primary7.column_1_empty_button_text,
    column1EmptyButtonHref: data === null || data === void 0 ? void 0 : (_data$primary8 = data.primary) === null || _data$primary8 === void 0 ? void 0 : (_data$primary8$column = _data$primary8.column_1_empty_button_link) === null || _data$primary8$column === void 0 ? void 0 : _data$primary8$column.url,
    column1EmptyButtonTarget: data === null || data === void 0 ? void 0 : (_data$primary9 = data.primary) === null || _data$primary9 === void 0 ? void 0 : (_data$primary9$column = _data$primary9.column_1_empty_button_link) === null || _data$primary9$column === void 0 ? void 0 : _data$primary9$column.target,
    column2Heading: data === null || data === void 0 ? void 0 : (_data$primary10 = data.primary) === null || _data$primary10 === void 0 ? void 0 : (_data$primary10$colum = _data$primary10.column_2_heading) === null || _data$primary10$colum === void 0 ? void 0 : _data$primary10$colum.text,
    column2ButtonText: data === null || data === void 0 ? void 0 : (_data$primary11 = data.primary) === null || _data$primary11 === void 0 ? void 0 : _data$primary11.column_2_button_text,
    column2ButtonHref: data === null || data === void 0 ? void 0 : (_data$primary12 = data.primary) === null || _data$primary12 === void 0 ? void 0 : (_data$primary12$colum = _data$primary12.column_2_button_link) === null || _data$primary12$colum === void 0 ? void 0 : _data$primary12$colum.url,
    column2ButtonTarget: data === null || data === void 0 ? void 0 : (_data$primary13 = data.primary) === null || _data$primary13 === void 0 ? void 0 : (_data$primary13$colum = _data$primary13.column_1_button_link) === null || _data$primary13$colum === void 0 ? void 0 : _data$primary13$colum.target,
    column2EmptyTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary14 = data.primary) === null || _data$primary14 === void 0 ? void 0 : _data$primary14.column_2_empty_text),
    column2EmptyButtonText: data === null || data === void 0 ? void 0 : (_data$primary15 = data.primary) === null || _data$primary15 === void 0 ? void 0 : _data$primary15.column_2_empty_button_text,
    column2EmptyButtonHref: data === null || data === void 0 ? void 0 : (_data$primary16 = data.primary) === null || _data$primary16 === void 0 ? void 0 : (_data$primary16$colum = _data$primary16.column_2_empty_button_link) === null || _data$primary16$colum === void 0 ? void 0 : _data$primary16$colum.url,
    column2EmptyButtonTarget: data === null || data === void 0 ? void 0 : (_data$primary17 = data.primary) === null || _data$primary17 === void 0 ? void 0 : (_data$primary17$colum = _data$primary17.column_2_empty_button_link) === null || _data$primary17$colum === void 0 ? void 0 : _data$primary17$colum.target,
    column1Experiences: (_filterByColumn = filterByColumn('column1', experiences)) === null || _filterByColumn === void 0 ? void 0 : _filterByColumn.map(toPhotoCard),
    column2Experiences: (_filterByColumn2 = filterByColumn('column2', experiences)) === null || _filterByColumn2 === void 0 ? void 0 : _filterByColumn2.map(toPhotoCard)
  };
};

PageBodyGroupedExperiences.mapDataToContext = () => ({
  backgroundColor: 'white'
});

export const fragment = "4047712100";