import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import React, { useState, useMemo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { themeGet } from '@styled-system/theme-get';
import { Formik, Form as FormikForm } from 'formik';
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components';
import { getRichText, postNetlifyForm, normalizeFormData } from 'helpers';
import { propPairsEq } from 'src/helpers';
import { transition } from 'src/constants';
import { Box, Grid, Flex, Button as ButtonBase } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { Subheading } from 'src/components/Subheading';
import { Button } from 'src/components/Button';
import { EmptyCard } from 'src/components/EmptyCard';
import { slicesMap as formSlicesMap, mapDataToProps, mapDataToInitialValues, mapDataToValidationSchema } from 'src/form/FormBody';
const variants = {
  initial: {
    height: '0px',
    opacity: 0,
    transition
  },
  visible: {
    opacity: 1,
    height: '100%',
    visibility: 'visible',
    transition
  },
  hidden: {
    opacity: 0,
    height: '0px',
    transition,
    transitionEnd: {
      visibility: 'hidden',
      overflow: 'hidden'
    }
  }
};

const TouchableFormFields = ({
  meta,
  formInputList,
  setFieldValue,
  setFieldTouched,
  validateForm
}) => {
  const memoizedMeta = useMemo(() => _objectSpread({}, meta, {
    setFieldValue,
    setFieldTouched,
    validateForm
  }), [meta, setFieldValue, setFieldTouched, validateForm]);
  return React.createElement(MapSlicesToComponents, {
    meta: memoizedMeta,
    map: formSlicesMap,
    list: formInputList,
    mapDataToProps: mapDataToProps
  });
};

var _StyledButtonBase = _styled(ButtonBase).withConfig({
  displayName: "PageBodyForm___StyledButtonBase",
  componentId: "gjf1uh-0"
})(["outline:none;&:hover,&:focus{color:", ";}"], p => p._css);

export const PageBodyForm = _ref => {
  let {
    name,
    heading,
    formRootData,
    formInputList,
    children,
    postSubmissionTextHTML
  } = _ref,
      props = _objectWithoutProperties(_ref, ["name", "heading", "formRootData", "formInputList", "children", "postSubmissionTextHTML"]);

  const [isVisible, setIsVisible] = useState(false);
  const meta = useMemo(() => ({
    rootData: formRootData
  }), [formRootData]);
  const validationSchema = mapDataToValidationSchema(formInputList);
  const initialValues = mapDataToInitialValues(formInputList);
  const onSubmit = useCallback(async (values, {
    status,
    setSubmitting,
    setStatus
  }) => {
    if (!(status === null || status === void 0 ? void 0 : status.hasSubmitted)) {
      const result = await postNetlifyForm(name, normalizeFormData(values));
      if (result) setStatus({
        hasSubmitted: true
      });
    }

    setSubmitting(false);
  }, [name]);
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    maxWidth: ['medium+', null, null, 'large'],
    pyScale: "l"
  }, props), React.createElement(Flex, {
    justifyContent: "space-between",
    alignItems: "baseline",
    borderTop: ['1px solid', '2px solid'],
    borderColor: ['gray.90', 'gray.90'],
    pyScale: "s+",
    mbScale: "t"
  }, heading && React.createElement(Heading, {
    fontSizeScale: "xl"
  }, heading), React.createElement(_StyledButtonBase, {
    color: "orange.60",
    transitionProperty: "color",
    onClick: () => setIsVisible(isVisible => !isVisible),
    _css: themeGet('colors.teal.75')
  }, React.createElement(Subheading, {
    color: "inherit"
  }, isVisible ? 'Hide' : 'Show', " Form"))), React.createElement(Formik, {
    validationSchema: validationSchema,
    validateOnChange: false,
    initialValues: initialValues,
    onSubmit: onSubmit
  }, ({
    status,
    setFieldValue,
    setFieldTouched,
    validateForm
  }) => React.createElement(Box, {
    as: motion.div,
    initial: "initial",
    animate: isVisible ? 'visible' : 'hidden',
    variants: variants
  }, React.createElement(Grid, {
    as: FormikForm,
    "data-netlify": true,
    "data-netlify-honeypot": "bot-field",
    name: name,
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridColumnGapScale: "xl",
    gridRowGapScale: "m-"
  }, children || React.createElement(TouchableFormFields, {
    meta: meta,
    formInputList: formInputList,
    setFieldValue: setFieldValue,
    setFieldTouched: setFieldTouched,
    validateForm: validateForm
  }), React.createElement(Button, {
    type: "submit",
    justifySelf: "flex-start",
    disabled: status === null || status === void 0 ? void 0 : status.hasSubmitted,
    gridColumn: "span 2"
  }, (status === null || status === void 0 ? void 0 : status.hasSubmitted) ? 'Thank you!' : 'Submit'), (status === null || status === void 0 ? void 0 : status.hasSubmitted) && postSubmissionTextHTML && React.createElement(EmptyCard, {
    textHTML: postSubmissionTextHTML
  })))));
};

PageBodyForm.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext,
  previousType
}) => {
  var _data$primary, _data$primary$form, _data$primary2, _data$primary2$form, _data$primary2$form$d, _data$primary2$form$d2, _data$primary2$form$d3, _data$primary3, _data$primary3$form, _data$primary3$form$d, _data$primary3$form$d2, _data$primary3$form$d3, _formDoc$data, _formDoc$data2;

  const formDoc = data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$form = _data$primary.form) === null || _data$primary$form === void 0 ? void 0 : _data$primary$form.document;
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    name: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$form = _data$primary2.form) === null || _data$primary2$form === void 0 ? void 0 : (_data$primary2$form$d = _data$primary2$form.document) === null || _data$primary2$form$d === void 0 ? void 0 : (_data$primary2$form$d2 = _data$primary2$form$d.data) === null || _data$primary2$form$d2 === void 0 ? void 0 : (_data$primary2$form$d3 = _data$primary2$form$d2.name) === null || _data$primary2$form$d3 === void 0 ? void 0 : _data$primary2$form$d3.text,
    heading: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$form = _data$primary3.form) === null || _data$primary3$form === void 0 ? void 0 : (_data$primary3$form$d = _data$primary3$form.document) === null || _data$primary3$form$d === void 0 ? void 0 : (_data$primary3$form$d2 = _data$primary3$form$d.data) === null || _data$primary3$form$d2 === void 0 ? void 0 : (_data$primary3$form$d3 = _data$primary3$form$d2.name) === null || _data$primary3$form$d3 === void 0 ? void 0 : _data$primary3$form$d3.text,
    formRootData: formDoc === null || formDoc === void 0 ? void 0 : formDoc.data,
    formInputList: formDoc === null || formDoc === void 0 ? void 0 : (_formDoc$data = formDoc.data) === null || _formDoc$data === void 0 ? void 0 : _formDoc$data.body,
    postSubmissionTextHTML: getRichText(formDoc === null || formDoc === void 0 ? void 0 : (_formDoc$data2 = formDoc.data) === null || _formDoc$data2 === void 0 ? void 0 : _formDoc$data2.post_submission_text)
  };
};

PageBodyForm.mapDataToContext = () => ({
  headerLogoColor: 'gray.20',
  headerBg: 'teal.75',
  backgroundColor: 'white'
});

export const fragment = "1277353457";