import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
const colorSchemes = {
  lightGray: {
    color: 'black',
    bg: 'gray.90'
  },
  white: {
    color: 'gray.20',
    bg: 'white'
  }
};
export const PageBodyLargeText = _ref => {
  let {
    children,
    textHTML,
    colorScheme = 'lightGray',
    alignment = 'center'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "textHTML", "colorScheme", "alignment"]);

  const colors = colorSchemes[colorScheme];
  const isLeftAligned = alignment === 'left';
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: colors.bg,
    color: colors.color,
    maxWidth: isLeftAligned ? ARTICLE_MAX_WIDTH : 'large+'
  }, props), (children || textHTML) && React.createElement(HTMLContent, {
    children: children,
    html: textHTML,
    fontFamily: "serif",
    fontSizeScale: "l",
    mx: "auto",
    textAlign: isLeftAligned ? 'left' : 'center'
  }));
};

PageBodyLargeText.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$primary2, _data$primary3;

  return {
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.text),
    colorScheme: camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.color_scheme),
    alignment: camelCase(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.alignment)
  };
};

PageBodyLargeText.mapDataToContext = ({
  data
}) => {
  var _data$primary4, _data$primary5;

  const colorScheme = camelCase(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.color_scheme);
  const colors = colorSchemes[colorScheme];
  const alignment = camelCase(data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : _data$primary5.alignment);
  return {
    headerLogoColor: 'gray.20',
    backgroundColor: colors.bg,
    isArticleType: alignment === 'left'
  };
};

export const fragment = "2389729137";