import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { useInView } from 'react-intersection-observer';
import { negateScale } from 'styled-system-scale';
import { getRichText } from 'helpers';
import { Box, Grid, Text } from 'system';
import { theme } from 'src/theme';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
import { Button } from 'src/components/Button';
import { Heading } from 'src/components/Heading';
import { Anchor } from 'src/components/Anchor';
import { AnimatedSVGPath } from 'src/components/AnimatedSVGPath';

const DesktopSVGPathGroup = _ref => {
  let {
    inView
  } = _ref,
      props = _objectWithoutProperties(_ref, ["inView"]);

  return React.createElement(React.Fragment, null, React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "1",
    gridRow: "1 / 7",
    pathId: "path-1",
    xRatio: 729,
    yRatio: 216,
    transform: "translate(-5.000000, -162.000000)",
    path: "M732,164 L732,261.338502 L381.879737,261.338502 L381.879737,376 L0,376",
    display: ['none', null, 'block'],
    strokeDashArray: "6, 15"
  }, props)), React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "48 / -1",
    gridRow: "2 / 29",
    pathId: "path-2",
    xRatio: 562,
    yRatio: 728,
    transform: "translate(-1998.000000, -257.000000)",
    path: "M2559.62693,259 L2000,259 L2000,359.743145 L2359.76017,359.743145 L2359.76017,612.581941 L2177.09288,612.581941 L2177.09288,734.655525 L2559.62693,734.655525 L2559.62693,858.089131 L2359.76017,858.089131 L2359.76017,983 L2668,983",
    display: ['none', null, 'block'],
    strokeDashArray: "3, 13"
  }, props)), React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "37 / 52",
    gridRow: "1 / 24",
    pathId: "path-3",
    xRatio: 179,
    yRatio: 721,
    transform: "translate(-1638.000000, -161.000000)",
    path: "M1815,163 L1815,359 L1640,359 L1640,163",
    display: ['none', null, 'block'],
    strokeDasharray: "14, 16"
  }, props)), React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "24 / 29",
    gridRow: "1 / 7",
    pathId: "path-5",
    xRatio: 366,
    yRatio: 217,
    transform: "translate(-918.000000, -161.000000)",
    path: "M1282,163 L1282,259.616381 L1102.59375,259.616381 L1102.59375,376 L920,376 L920,163",
    strokeDashArray: "5, 15",
    display: ['none', null, null, 'block']
  }, props)), React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "1 / -1",
    gridRow: "11 / -1",
    pathId: "path-6",
    xRatio: 2002,
    yRatio: 721,
    strokeDashArray: "6, 15",
    transform: "translate(0.000000, -493.000000)",
    path: "M0,495 L560,495 L560,621.398438 L365.800781,621.398438 L365.800781,746 L731.601563,746 L731.601563,970 L1101,970 L1101,1089 L1282,1089 L1282,970 L1640,970 L1815.3125,970 L1815.3125,859 L2000,859 L2000,1089 L1640,1089 L1640,1213",
    display: ['none', null, 'block'],
    left: "-25%"
  }, props)), React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "44 / 58",
    gridRow: "21 / 31",
    pathId: "path-7",
    xRatio: 368,
    yRatio: 344,
    transform: "matrix(1 0 0 -1 2 344)",
    strokeDashArray: "5, 14",
    path: "m364 0v156.037723h-180.397445v187.962277h-183.602555v-344",
    display: ['none', null, null, 'block']
  }, props)));
};

const MobileSVGPath = _ref2 => {
  let {
    inView
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["inView"]);

  return React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
    pathId: "path-8",
    width: "105%",
    height: "105%",
    position: "relative",
    left: "-10%",
    xRatio: 354,
    yRatio: 352,
    transform: "translate(1 1)",
    path: "m45 0v30.65625h79v32.25h79v-31.453125h121v67.546875h81.4375v36.230469h-45.4375v46.769531h55.242188l.757812 58.5h-92v32.0625h92v38.52007h-135v38.17876h-157v-38.17876h-79l.4570312-70.58257h-45.4570312v-58.5h82.4570312v-53.289063h-34.1847428v-29.710937h-48.2722884v-99z",
    display: ['block', 'none'],
    strokeDashArray: "1,8",
    opacity: "0.8"
  }, props));
};

const TabletSVGPath = _ref3 => {
  let {
    inView
  } = _ref3,
      props = _objectWithoutProperties(_ref3, ["inView"]);

  return React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
    pathId: "path-9",
    width: "120%",
    height: "120%",
    position: "relative",
    left: "-17%",
    xRatio: 1205,
    yRatio: 948,
    transform: "translate(-109.000000, 0.000000)",
    path: "M260.125886,216.803354 L313.651162,216.803354 L313.651162,281.745532 L379.665669,281.745532 L379.665669,398.224785 L110,398.224785 L110,526.094107 L313.651162,526.094107 L313.651162,682.378834 L454.601056,682.378834 L454.601056,747.594238 L595.55095,747.594238 L595.55095,947 L744.285358,947 L744.285358,691.097189 C766.330899,691.097189 784.464015,691.097189 798.684707,691.097189 C812.905398,691.097189 831.388203,691.097189 854.133123,691.097189 L854.133123,947 L947.496116,947 L947.496116,682.378834 L1053.86434,682.378834 L1053.86434,526.094107 L1313,526.094107 L1313,274.971218 L1053.86434,274.971218 L1053.86434,209.800487 L986.239535,209.800487 L986.239535,111.411328 L771.136678,111.411328 L771.136678,-111 L677.321225,-111 L677.321225,111.411328 L379.665669,111.411328 L379.665669,-111 L110,-111 L110,216.803354 L260.125886,216.803354 Z",
    display: ['none', 'block', 'none'],
    strokeDashArray: "1,8"
  }, props));
};

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyLargeDottedPathCta___StyledBox",
  componentId: "sc-4tewtq-0"
})(["text-align:center;"]);

export const PageBodyLargeDottedPathCta = _ref4 => {
  let {
    buttonLink,
    buttonTarget,
    buttonText,
    textHTML
  } = _ref4,
      props = _objectWithoutProperties(_ref4, ["buttonLink", "buttonTarget", "buttonText", "textHTML"]);

  const [ref, inView] = useInView({
    threshold: 0.5
  });
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    position: "relative",
    bg: "teal.40",
    maxWidth: "none",
    pbScale: "0",
    pxScale: "0",
    ptAdd: negateScale(theme.spaceScales.xxl),
    ref: ref,
    overflow: "hidden"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: "repeat(60, 1fr)",
    gridTemplateRows: "repeat(30, 1fr)",
    zIndex: "dottedPathCta",
    maxHeight: "800px"
  }, React.createElement(_StyledBox, {
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
    alignSelf: "center",
    justifySelf: "center"
  }, textHTML && React.createElement(HTMLContent, {
    color: "white",
    html: textHTML,
    mbScale: "m",
    textAlign: "center",
    map: {
      h1: props => React.createElement(Heading, Object.assign({}, props, {
        as: "h3",
        fontSizeScale: "xl",
        maxWidth: ['15ch', '25ch']
      })),
      p: props => React.createElement(Text, Object.assign({}, props, {
        as: "p",
        fontSizeScale: "b",
        mbScale: "s",
        maxWidth: ['55ch', '65ch'],
        lineHeight: "wide"
      }))
    }
  }), React.createElement(Button, {
    mx: "auto",
    textAlign: "center",
    display: "inline-block",
    as: Anchor,
    href: buttonLink,
    target: buttonTarget,
    colorScheme: "white"
  }, React.createElement(Text, {
    pxScale: ['t', 's']
  }, buttonText))), React.createElement(DesktopSVGPathGroup, {
    inView: inView,
    strokeWidth: 2
  }), React.createElement(MobileSVGPath, {
    inView: inView,
    strokeWidth: 2
  }), React.createElement(TabletSVGPath, {
    inView: inView,
    strokeWidth: 2
  })));
};

PageBodyLargeDottedPathCta.mapDataToProps = ({
  data,
  previousContext,
  nextContext,
  previousType
}) => {
  var _data$primary, _data$primary$button_, _data$primary2, _data$primary2$button, _data$primary3, _data$primary4;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    buttonLink: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$button_ = _data$primary.button_link) === null || _data$primary$button_ === void 0 ? void 0 : _data$primary$button_.url,
    buttonTarget: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$button = _data$primary2.button_link) === null || _data$primary2$button === void 0 ? void 0 : _data$primary2$button.target,
    buttonText: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.button_text,
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.text)
  };
};

PageBodyLargeDottedPathCta.mapDataToContext = () => ({
  backgroundColor: 'teal.40',
  headerBg: 'teal.75'
});

export const fragment = "3945759084";