import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { useInView } from 'react-intersection-observer';
import { getRichText } from 'helpers';
import { AnimatedSVGPath } from 'src/components/AnimatedSVGPath';
import { ImageContainer, Image, Flex, Box, Text } from 'system';
import { Anchor } from 'src/components/Anchor';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
import { Button } from 'src/components/Button';
import { Heading } from 'src/components/Heading';

const DesktopCta = _ref => {
  let {
    inView
  } = _ref,
      props = _objectWithoutProperties(_ref, ["inView"]);

  return React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    pathId: "__desktopPath",
    xRatio: 2164,
    yRatio: 524,
    transform: "translate(-257.000000, -142.000000)",
    path: "M1341,144 L1341,222.914062 L619,222.914062 L619,144 L259,144 L259,310 L979,310 L979,396 L619,396 L619,478 L259,478 L259,558 L979,558 L979,478 L1341,478 L1341,310 L1699,310 L1699,396 L2059,396 L2059,222.914062 L1699,222.914062 L1699,144 L2419,144 L2419,558 L2059,558 L2059,478 L1699,478 L1699,598 L1574,598 L1339,598 L1339,668.668183"
  }, props));
};

const MobileCta = _ref2 => {
  let {
    inView
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["inView"]);

  return React.createElement(AnimatedSVGPath, Object.assign({
    inView: inView,
    pathId: "__mobilePath",
    xRatio: 308,
    yRatio: 258,
    strokeWidth: 2,
    strokeDashArray: "2,7",
    animationDuration: 2.75,
    transform: "translate(-29 -78)",
    path: "m132.009426 79.8522903v23.9914597h-102.009426v91.652344h66.9497018v-45.051545h66.8841462v46.055451l171.869414-1.003906v101.960766h-94.394834v-51.977323h-211.308428v51.977323h85.325577v39.54314"
  }, props));
};

export const PageBodyDottedPathCta = _ref3 => {
  let {
    imageURL,
    imageAlt,
    imageFluid,
    buttonLink,
    buttonTarget,
    buttonText,
    textHTML
  } = _ref3,
      props = _objectWithoutProperties(_ref3, ["imageURL", "imageAlt", "imageFluid", "buttonLink", "buttonTarget", "buttonText", "textHTML"]);

  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    position: "relative",
    bg: "gray.90",
    ref: ref
  }, props), React.createElement(Flex, {
    flexDirection: "column",
    alignItems: ['flex-start', 'center'],
    position: "relative",
    zIndex: "dottedPathCta"
  }, textHTML && React.createElement(HTMLContent, {
    color: "white",
    fontSizeScale: "xl",
    html: textHTML,
    textAlign: ['left', 'center'],
    maxWidth: "large+",
    map: {
      h1: props => React.createElement(Heading, Object.assign({}, props, {
        as: "h3",
        fontSizeScale: "xl",
        mbScale: "t"
      })),
      p: props => React.createElement(Text, Object.assign({}, props, {
        as: "p",
        fontSizeScale: "b",
        mbScale: "s",
        maxWidth: "65ch",
        lineHeight: "wide"
      }))
    }
  }), React.createElement(DesktopCta, {
    inView: inView,
    display: ['none', 'block']
  }), React.createElement(MobileCta, {
    inView: inView,
    display: [null, 'none']
  }), React.createElement(Button, {
    as: Anchor,
    href: buttonLink,
    target: buttonTarget,
    colorScheme: "white",
    mr: "auto",
    ml: ['none', 'auto'],
    display: "block"
  }, buttonText)), (imageFluid || imageURL) && React.createElement(ImageContainer, {
    position: "absolute",
    bg: "gray.20",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: "dottedPathImage"
  }, React.createElement(Image, {
    src: imageURL,
    alt: imageAlt,
    fluid: imageFluid
  })), React.createElement(Box, {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: "dottedPathImageGradient",
    bg: "gray.20",
    opacity: 0.6
  }));
};

PageBodyDottedPathCta.mapDataToProps = ({
  data,
  previousContext,
  nextContext,
  previousType
}) => {
  var _data$primary, _data$primary$backgro, _data$primary2, _data$primary2$backgr, _data$primary3, _data$primary3$backgr, _data$primary4, _data$primary4$button, _data$primary5, _data$primary5$button, _data$primary6, _data$primary7;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$backgro = _data$primary.background_image) === null || _data$primary$backgro === void 0 ? void 0 : _data$primary$backgro.url,
    imageAlt: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$backgr = _data$primary2.background_image) === null || _data$primary2$backgr === void 0 ? void 0 : _data$primary2$backgr.alt,
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$backgr = _data$primary3.background_image) === null || _data$primary3$backgr === void 0 ? void 0 : _data$primary3$backgr.fluid,
    buttonLink: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$button = _data$primary4.button_link) === null || _data$primary4$button === void 0 ? void 0 : _data$primary4$button.url,
    buttonTarget: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$button = _data$primary5.button_link) === null || _data$primary5$button === void 0 ? void 0 : _data$primary5$button.target,
    buttonText: data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : _data$primary6.button_text,
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : _data$primary7.text)
  };
};

export const fragment = "2044532171";