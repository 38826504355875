import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { linearScale } from 'styled-system-scale';
import { isInternal } from 'helpers';
import { Flex, Box, Button as ButtonBase, SVG } from 'system';
import { ReactComponent as AssetIconExternalSVG } from 'src/assets/icon-external.svg';
const colorSchemes = {
  yellowFill: {
    color: 'gray.20',
    bg: 'yellow.80',
    borderColor: 'yellow.80',
    hoverBg: 'orange.60',
    hoverColor: 'white',
    hoverBorderColor: 'orange.60',
    disabledColor: 'gray.60',
    disabledBg: 'gray.90',
    disabledBorderColor: 'gray.90',
    disabledHoverBg: 'gray.90',
    disabledHoverColor: 'gray.60',
    disabledHoverBorderColor: 'gray.90'
  },
  orange: {
    color: 'orange.60',
    bg: 'transparent',
    borderColor: 'orange.60',
    hoverBg: 'orange.60',
    hoverColor: 'white',
    hoverBorderColor: 'orange.60',
    disabledColor: 'gray.60',
    disabledBg: 'gray.90',
    disabledBorderColor: 'gray.90',
    disabledHoverBg: 'gray.90',
    disabledHoverColor: 'gray.60',
    disabledHoverBorderColor: 'gray.90'
  },
  white: {
    color: 'white',
    bg: 'transparent',
    borderColor: 'white',
    hoverBg: 'white',
    hoverColor: 'teal.75',
    hoverBorderColor: 'white',
    disabledColor: 'gray.60',
    disabledBg: 'gray.90',
    disabledBorderColor: 'gray.90',
    disabledHoverBg: 'gray.90',
    disabledHoverColor: 'gray.60',
    disabledHoverBorderColor: 'gray.90'
  }
};

var _StyledButtonBase = _styled(ButtonBase).withConfig({
  displayName: "Button___StyledButtonBase",
  componentId: "sc-1678h7d-0"
})(["outline:0;&:hover,&:focus{background-color:", ";border-color:", ";color:", ";}"], p => p._css, p => p._css2, p => p._css3);

export const Button = _ref => {
  let {
    children,
    colorScheme = 'orange',
    target,
    disabled,
    href,
    withBorder = true,
    withHover = true
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "colorScheme", "target", "disabled", "href", "withBorder", "withHover"]);

  const colors = colorSchemes[colorScheme];
  return React.createElement(_StyledButtonBase, Object.assign({
    disabled: disabled,
    bg: disabled ? colors.disabledBg : colors.bg,
    border: [withBorder && '2px solid', null, null, withBorder && '3px solid'],
    borderColor: [disabled ? colors.disabledBorderColor : colors.borderColor, null, null, disabled ? colors.disabledBorderColor : colors.borderColor],
    borderRadius: linearScale('8px', '16px', {
      count: 5
    }),
    color: disabled ? colors.disabledColor : colors.color,
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: "inline-block",
    fontFamily: "sans",
    fontSizeScale: "b",
    fontWeight: "bold",
    href: href,
    lineHeight: "solid",
    pxScale: "s+",
    pyScale: "t",
    target: target,
    textStyle: "trackedCaps",
    transitionProperty: "background-color, color, border-color"
  }, props, {
    _css: withHover && themeGet("colors." + (disabled ? colors.disabledHoverBg : colors.hoverBg)),
    _css2: withHover && themeGet("colors." + (disabled ? colors.disabledHoverBorderColor : colors.hoverBorderColor)),
    _css3: withHover && themeGet("colors." + (disabled ? colors.disabledHoverColor : colors.hoverColor))
  }), React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Box, {
    flexGrow: "1"
  }, children), href && !isInternal(href) && React.createElement(SVG, {
    svg: AssetIconExternalSVG,
    x: 1,
    y: 1,
    mlScale: "t-",
    width: linearScale('0.75rem', '1.15rem', {
      count: 5
    }),
    transform: ['translateY(-5%)', 'none']
  })));
};