import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Flex, Input } from 'system';
import { themeGet } from '@styled-system/theme-get';
import { Subheading } from 'src/components/Subheading';

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "RadioField___StyledFlex",
  componentId: "kz0b5j-0"
})(["&:hover{cursor:pointer;}"]);

var _StyledFlex2 = _styled(Flex).withConfig({
  displayName: "RadioField___StyledFlex2",
  componentId: "kz0b5j-1"
})(["input:checked + &{background-color:", ";border-color:", ";color:white;}font-family:", ";"], p => p._css, p => p._css2, p => p._css3);

export const RadioField = _ref => {
  let {
    label,
    name,
    as = 'input',
    value,
    checked,
    defaultChecked,
    type = 'radio',
    subheadingProps
  } = _ref,
      props = _objectWithoutProperties(_ref, ["label", "name", "as", "value", "checked", "defaultChecked", "type", "subheadingProps"]);

  return React.createElement(_StyledFlex, Object.assign({
    as: "label",
    alignItems: "center",
    cursor: "pointer"
  }, props), React.createElement(Input, {
    as: as,
    display: "none",
    checked: checked,
    name: name,
    type: type,
    value: value || label,
    defaultChecked: defaultChecked
  }), React.createElement(_StyledFlex2, {
    border: "2px solid black",
    alignItems: "center",
    color: "transparent",
    mrScale: "t",
    bg: "transparent",
    fontSize: "1rem",
    justifyContent: "center",
    lineHeight: "1",
    fontWeight: "bold",
    transition: "background-color " + themeGet('transitionDurations.normal'),
    width: "20px",
    height: "20px",
    _css: themeGet('colors.red.40'),
    _css2: themeGet('colors.red.40'),
    _css3: themeGet('fonts.sans')
  }, React.createElement("span", null, "\u2715")), React.createElement(Subheading, Object.assign({
    color: "gray.20",
    fontSizeScale: ['s', null, 'b']
  }, subheadingProps), label));
};