import "core-js/modules/web.dom.iterable";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/web.dom.iterable";
import { replace, camelCase, has } from 'tiny-compose-fns';
import { object as yupObject } from 'yup'; // 1. Import your slice

import { FormBodyCheckboxField } from './FormBodyCheckboxField';
import { FormBodyHelpText } from './FormBodyHelpText';
import { FormBodyRadioField } from './FormBodyRadioField';
import { FormBodySelectField } from './FormBodySelectField';
import { FormBodyTextField } from './FormBodyTextField';
import { FormBodyTextarea } from './FormBodyTextarea';
import { FormBodyFileField } from './FormBodyFileField'; // 2. Add your slice

export const slicesMap = {
  FormBodyCheckboxField,
  FormBodyHelpText,
  FormBodyRadioField,
  FormBodySelectField,
  FormBodyTextField,
  FormBodyTextarea,
  FormBodyFileField
}; // 3. Add your slice fragment

export const fragment = "3901719255"; // Used for react-map-to-components

const mapKeys = Object.keys(slicesMap);
export const mapDataToContext = {};

for (let i = 0; i < mapKeys.length; i++) mapDataToContext[mapKeys[i]] = slicesMap[mapKeys[i]].mapDataToContext;

export const mapDataToProps = {};

for (let i = 0; i < mapKeys.length; i++) mapDataToProps[mapKeys[i]] = slicesMap[mapKeys[i]].mapDataToProps;

export const mapDataToInitialValues = data => {
  const initialValues = data.reduce((acc, data) => {
    var _data$primary, _data$primary$label, _slicesMap$type, _slicesMap$type$initi;

    const type = replace(/^Prismic/, '', data === null || data === void 0 ? void 0 : data.__typename);
    const name = camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$label = _data$primary.label) === null || _data$primary$label === void 0 ? void 0 : _data$primary$label.text);
    if (!has(type, slicesMap)) return acc;
    const currentInitialValues = slicesMap === null || slicesMap === void 0 ? void 0 : (_slicesMap$type = slicesMap[type]) === null || _slicesMap$type === void 0 ? void 0 : (_slicesMap$type$initi = _slicesMap$type.initialValues) === null || _slicesMap$type$initi === void 0 ? void 0 : _slicesMap$type$initi.call(_slicesMap$type, {
      data
    });
    if (currentInitialValues) acc[name] = currentInitialValues;
    return acc;
  }, {});
  return initialValues;
};
export const mapDataToValidationSchema = data => {
  const validationSchema = data.reduce((acc, data) => {
    var _data$primary2, _data$primary2$label, _slicesMap$type2, _slicesMap$type2$vali;

    const type = replace(/^Prismic/, '', data === null || data === void 0 ? void 0 : data.__typename);
    const name = camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$label = _data$primary2.label) === null || _data$primary2$label === void 0 ? void 0 : _data$primary2$label.text);
    if (!has(type, slicesMap)) return acc;
    const currValidationSchema = slicesMap === null || slicesMap === void 0 ? void 0 : (_slicesMap$type2 = slicesMap[type]) === null || _slicesMap$type2 === void 0 ? void 0 : (_slicesMap$type2$vali = _slicesMap$type2.validationSchema) === null || _slicesMap$type2$vali === void 0 ? void 0 : _slicesMap$type2$vali.call(_slicesMap$type2, {
      data
    });
    if (currValidationSchema) acc[name] = currValidationSchema;
    return acc;
  }, {});
  return yupObject().shape(validationSchema);
};