import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { Grid, Button } from 'system';
import { Subheading } from 'src/components/Subheading';
export const Tabs = _ref => {
  var _React$Children$toArr;

  let {
    activeKey,
    children,
    label,
    onTabClick
  } = _ref,
      props = _objectWithoutProperties(_ref, ["activeKey", "children", "label", "onTabClick"]);

  const childrenCount = React.Children.count((_React$Children$toArr = React.Children.toArray(children)) === null || _React$Children$toArr === void 0 ? void 0 : _React$Children$toArr.filter(Boolean));
  return React.createElement(Grid, Object.assign({
    gridTemplateColumns: ['1fr repeat(2, 2fr)', "auto repeat(" + childrenCount + ", minmax(10rem, 20rem))"],
    gridTemplateRows: ['repeat(2, 1fr)', '1fr'],
    gridGapScale: "t",
    alignItems: [null, 'center']
  }, props), label && React.createElement(Subheading, {
    color: "gray.20",
    fontSizeScale: ['s', 'm'],
    pt: [1, 0],
    gridRow: "1 / -1",
    mrScale: [0, 'm']
  }, label), React.Children.map(children, child => child && React.cloneElement(child, {
    isActive: activeKey === child.key,
    onClick: event => onTabClick(child.key, event)
  })));
};

var _StyledButton = _styled(Button).withConfig({
  displayName: "Tabs___StyledButton",
  componentId: "i4yerb-0"
})(["outline:0;&:hover,&:focus{background-color:", ";color:", ";}"], p => p._css, p => p._css2);

Tabs.Tab = _ref2 => {
  let {
    isActive,
    children
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["isActive", "children"]);

  return React.createElement(_StyledButton, Object.assign({
    bg: isActive ? 'red.40' : 'transparent',
    border: ['1px solid', null, '2px solid'],
    borderColor: ['red.40', null, 'red.40'],
    color: isActive ? 'white' : 'red.40',
    fontWeight: "semibold",
    fontSizeScale: "m",
    pxScale: "s",
    pyScale: "t",
    textAlign: "center",
    width: 1,
    transitionProperty: "background-color, color"
  }, props, {
    _css: themeGet('colors.red.40'),
    _css2: themeGet('colors.white')
  }), children);
};