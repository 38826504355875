import { FieldsTable, Field, LinkCard, SliceExamples } from 'reference';
import { PageBodyDocumentsList } from 'src/slices/PageBodyDocumentsList';
import { DocumentCard } from 'src/components/DocumentCard';
import { ProjectExample } from 'src/components/ProjectExample';
import React from 'react';
export default {
  FieldsTable,
  Field,
  LinkCard,
  SliceExamples,
  PageBodyDocumentsList,
  DocumentCard,
  ProjectExample,
  React
};