import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import { Box, Flex, Text, AspectRatio, ImageContainer, Image } from 'system';
import { Anchor } from 'src/components/Anchor';
import { HTMLContent } from 'src/components/HTMLContent';
import { Heading } from 'src/components/Heading';
import { Subheading } from 'src/components/Subheading';

var _StyledBox = _styled(Box).withConfig({
  displayName: "SimplePhotoCard___StyledBox",
  componentId: "sc-554y1d-0"
})(["&:hover{& + .news-card-wrapper{color:", ";}}"], p => p._css);

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "SimplePhotoCard___StyledAnchor",
  componentId: "sc-554y1d-1"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css2);

export const SimplePhotoCard = _ref => {
  let {
    direction = 'column',
    href,
    target,
    imageFluid,
    imageURL,
    imageAlt,
    subheading,
    subheadingColor = 'red.40',
    isSubheadingCaps = true,
    heading,
    description,
    detail,
    showDescriptionOnMobile = true
  } = _ref,
      props = _objectWithoutProperties(_ref, ["direction", "href", "target", "imageFluid", "imageURL", "imageAlt", "subheading", "subheadingColor", "isSubheadingCaps", "heading", "description", "detail", "showDescriptionOnMobile"]);

  const isRow = direction === 'row';
  return React.createElement(Flex, Object.assign({
    flexDirection: ['row', null, direction]
  }, props), (imageURL || imageFluid) && React.createElement(_StyledBox, {
    width: [2 / 6, null, isRow ? 1 / 2 : '100%'],
    alignSelf: ['start', 'stretch'],
    mrScale: ['s+', null, isRow ? null : 0],
    mbScale: [0, null, isRow ? null : 's'],
    _css: themeGet('colors.teal.70')
  }, React.createElement(ConditionalWrap, {
    condition: href,
    wrap: children => React.createElement(Anchor, {
      href: href,
      target: target,
      tabIndex: "-1"
    }, children)
  }, React.createElement(ImageContainer, {
    bg: "gray.20"
  }, React.createElement(AspectRatio, {
    x: [4, 4, 16],
    y: [3, 3, 9]
  }, React.createElement(Image, {
    src: imageURL,
    fluid: imageFluid,
    alt: imageAlt
  }))))), React.createElement(Box, {
    width: [4 / 6, null, isRow ? 1 / 2 : '100%'],
    position: "relative",
    className: "news-card-wrapper",
    color: "gray.20"
  }, subheading && React.createElement(Subheading, {
    mbScale: "t",
    color: subheadingColor,
    textStyle: isSubheadingCaps ? 'trackedCaps' : null
  }, subheading), heading && React.createElement(Heading, {
    fontSizeScale: "m",
    mbScale: "t"
  }, React.createElement(ConditionalWrap, {
    condition: href,
    wrap: children => React.createElement(_StyledAnchor, {
      href: href,
      target: target,
      transitionProperty: "color",
      color: "inherit",
      _css2: themeGet('colors.teal.70')
    }, children)
  }, heading)), description && React.createElement(HTMLContent, {
    display: [showDescriptionOnMobile ? 'block' : 'none', null, 'block'],
    html: description,
    lineHeight: "wide",
    color: "gray.20"
  }), detail && React.createElement(Text, {
    as: "p",
    fontSizeScale: "b",
    fontWeight: "semibold",
    color: "teal.70",
    mtScale: "t"
  }, detail)));
};