import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { camelCase, chunk, compose, map } from 'tiny-compose-fns';
import { Box, Flex, Grid } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { PhotoCard } from 'src/components/PhotoCard';
import { SimplePhotoCard } from 'src/components/SimplePhotoCard';
import { Pagination } from 'src/components/Pagination';
import { propPairsEq } from 'src/helpers';
import { useExperiencesData } from 'src/hooks/useExperiencesData';
import { usePaginationWithUrlParams } from 'src/hooks/usePagination';
import { useMediaIndex } from 'src/hooks/useMediaIndex';
export const PageBodyPastExperiencesList = _ref => {
  let {
    type
  } = _ref,
      props = _objectWithoutProperties(_ref, ["type"]);

  const experiences = useExperiencesData(type);
  const mediaIndex = useMediaIndex();
  const sectionRef = useRef();
  const [currentPageIndex, setCurrentPageIndex] = usePaginationWithUrlParams(0, sectionRef);
  const [sortedPastExperiences] = useState(() => {
    const today = Date.now();

    const getPastExperiences = experiences => experiences.filter(experience => {
      var _experience$data, _experience$data$main, _experience$data$main2, _experience$data2, _experience$data2$mai, _experience$data2$mai2;

      const endDate = dayjs(experience === null || experience === void 0 ? void 0 : (_experience$data = experience.data) === null || _experience$data === void 0 ? void 0 : (_experience$data$main = _experience$data.main[0]) === null || _experience$data$main === void 0 ? void 0 : (_experience$data$main2 = _experience$data$main.primary) === null || _experience$data$main2 === void 0 ? void 0 : _experience$data$main2.end_date).valueOf();
      const startDate = dayjs(experience === null || experience === void 0 ? void 0 : (_experience$data2 = experience.data) === null || _experience$data2 === void 0 ? void 0 : (_experience$data2$mai = _experience$data2.main[0]) === null || _experience$data2$mai === void 0 ? void 0 : (_experience$data2$mai2 = _experience$data2$mai.primary) === null || _experience$data2$mai2 === void 0 ? void 0 : _experience$data2$mai2.start_date).valueOf();
      if (startDate < today && endDate < today) return true;
      if (startDate < today && !endDate) return true;
    });

    const getSortedExperiences = experiences => experiences.sort((experienceA, experienceB) => {
      var _experienceA$data, _experienceA$data$mai, _experienceA$data$mai2, _experienceB$data, _experienceB$data$mai, _experienceB$data$mai2;

      const startDateA = dayjs(experienceA === null || experienceA === void 0 ? void 0 : (_experienceA$data = experienceA.data) === null || _experienceA$data === void 0 ? void 0 : (_experienceA$data$mai = _experienceA$data.main[0]) === null || _experienceA$data$mai === void 0 ? void 0 : (_experienceA$data$mai2 = _experienceA$data$mai.primary) === null || _experienceA$data$mai2 === void 0 ? void 0 : _experienceA$data$mai2.start_date).valueOf();
      const startDateB = dayjs(experienceB === null || experienceB === void 0 ? void 0 : (_experienceB$data = experienceB.data) === null || _experienceB$data === void 0 ? void 0 : (_experienceB$data$mai = _experienceB$data.main[0]) === null || _experienceB$data$mai === void 0 ? void 0 : (_experienceB$data$mai2 = _experienceB$data$mai.primary) === null || _experienceB$data$mai2 === void 0 ? void 0 : _experienceB$data$mai2.start_date).valueOf();
      return startDateA - startDateB;
    });

    const result = compose(experiences => experiences.reverse(), getSortedExperiences, getPastExperiences)(experiences);
    return result;
  });
  const experiencesPerPage = mediaIndex <= 2 ? 4 : 3;
  const [paginatedExperiences, setPaginatedExperiences] = useState(chunk(experiencesPerPage, sortedPastExperiences));
  useEffect(() => {
    setPaginatedExperiences(chunk(experiencesPerPage, sortedPastExperiences));
  }, [sortedPastExperiences, experiencesPerPage]);
  const pluralTypes = {
    event: 'events',
    exhibition: 'exhibitions',
    residency: 'residencies'
  };
  const displayType = pluralTypes[type];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    ref: sectionRef
  }, props), React.createElement(Flex, {
    alignItems: ['flex-start', 'baseline'],
    mbScale: "m-",
    flexDirection: ['column', 'row']
  }, React.createElement(Heading, {
    as: "h3",
    fontSizeScale: "xl",
    lineHeight: "solid",
    mrScale: "m"
  }, "Past ", displayType)), React.createElement(Grid, {
    as: "ul",
    borderTop: ['1px solid', 0],
    borderColor: "gray.90",
    justifyContent: "start",
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', null, 'repeat(3, 1fr)'],
    gridColumnGapScale: [0, 'm', null, 's'],
    gridRowGapScale: [0, 'm']
  }, map(experience => {
    var _experience$data3, _experience$data3$mai, _experience$data4, _experience$data4$tit, _main$featured_image, _main$featured_image2, _main$featured_image3, _experience$data5, _experience$data5$tit, _main$featured_image4, _main$featured_image5, _main$featured_image6;

    const main = experience === null || experience === void 0 ? void 0 : (_experience$data3 = experience.data) === null || _experience$data3 === void 0 ? void 0 : (_experience$data3$mai = _experience$data3.main[0]) === null || _experience$data3$mai === void 0 ? void 0 : _experience$data3$mai.primary;
    const startDate = main === null || main === void 0 ? void 0 : main.start_date;
    const endDate = (main === null || main === void 0 ? void 0 : main.end_date) ? " - " + (main === null || main === void 0 ? void 0 : main.end_date) : '';
    const dateDetail = startDate + endDate;
    return React.createElement(Box, {
      key: experience === null || experience === void 0 ? void 0 : experience.uid,
      as: "li",
      borderBottom: ['1px solid', 0],
      borderColor: "gray.90",
      pyScale: ['m', 0]
    }, React.createElement(SimplePhotoCard, {
      href: experience === null || experience === void 0 ? void 0 : experience.url,
      subheading: type,
      heading: experience === null || experience === void 0 ? void 0 : (_experience$data4 = experience.data) === null || _experience$data4 === void 0 ? void 0 : (_experience$data4$tit = _experience$data4.title) === null || _experience$data4$tit === void 0 ? void 0 : _experience$data4$tit.text,
      description: main === null || main === void 0 ? void 0 : main.description,
      detail: dateDetail,
      imageURL: main === null || main === void 0 ? void 0 : (_main$featured_image = main.featured_image) === null || _main$featured_image === void 0 ? void 0 : _main$featured_image.url,
      imageFluid: main === null || main === void 0 ? void 0 : (_main$featured_image2 = main.featured_image) === null || _main$featured_image2 === void 0 ? void 0 : _main$featured_image2.fluid,
      imageAlt: main === null || main === void 0 ? void 0 : (_main$featured_image3 = main.featured_image) === null || _main$featured_image3 === void 0 ? void 0 : _main$featured_image3.alt,
      display: ['flex', 'none']
    }), React.createElement(PhotoCard, {
      href: experience === null || experience === void 0 ? void 0 : experience.url,
      subheading: type,
      heading: experience === null || experience === void 0 ? void 0 : (_experience$data5 = experience.data) === null || _experience$data5 === void 0 ? void 0 : (_experience$data5$tit = _experience$data5.title) === null || _experience$data5$tit === void 0 ? void 0 : _experience$data5$tit.text,
      description: main === null || main === void 0 ? void 0 : main.description,
      detail: dateDetail,
      imageURL: main === null || main === void 0 ? void 0 : (_main$featured_image4 = main.featured_image) === null || _main$featured_image4 === void 0 ? void 0 : _main$featured_image4.url,
      imageFluid: main === null || main === void 0 ? void 0 : (_main$featured_image5 = main.featured_image) === null || _main$featured_image5 === void 0 ? void 0 : _main$featured_image5.fluid,
      imageAlt: main === null || main === void 0 ? void 0 : (_main$featured_image6 = main.featured_image) === null || _main$featured_image6 === void 0 ? void 0 : _main$featured_image6.alt,
      cardPosition: "bottomLeftYOverhang",
      display: ['none', 'flex']
    }));
  }, paginatedExperiences[currentPageIndex])), React.createElement(Pagination, {
    total: paginatedExperiences === null || paginatedExperiences === void 0 ? void 0 : paginatedExperiences.length,
    current: currentPageIndex,
    handleSet: setCurrentPageIndex
  }));
};

PageBodyPastExperiencesList.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext,
  index
}) => {
  var _data$primary;

  return {
    isFirst: index === 1,
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    type: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.experience_type)
  };
};

PageBodyPastExperiencesList.mapDataToContext = () => ({
  backgroundColor: 'white',
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});

export const fragment = "1152733550";