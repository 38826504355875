import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef, useState, useLayoutEffect } from 'react';
import { useResizeObserver } from '@asyarb/use-resize-observer';
import { Grid, Box } from 'system';

var _StyledGrid = _styled(Grid).withConfig({
  displayName: "GridVerticalRhythm___StyledGrid",
  componentId: "sc-1gnkrwd-0"
})(["grid-auto-rows:", "px;& > li:last-of-type > .photo-card{border-bottom:0;}"], p => p._css);

export const GridVerticalRhythm = _ref => {
  let {
    children,
    childrenAs,
    rowHeight = 20
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "childrenAs", "rowHeight"]);

  const containerRef = useRef();
  const childrenRefs = useRef([]);
  const [heights, setHeights] = useState([]);
  const {
    width,
    height
  } = useResizeObserver({
    ref: containerRef
  });
  useLayoutEffect(() => {
    const newHeights = React.Children.map(children, (_, index) => {
      const ref = childrenRefs.current[index];
      const {
        height
      } = ref.getBoundingClientRect();
      return height;
    });
    setHeights(newHeights);
  }, [width, height, children]);
  return React.createElement(_StyledGrid, Object.assign({
    ref: containerRef
  }, props, {
    _css: rowHeight
  }), React.Children.map(children, (child, index) => {
    const rowSpan = Math.max(1, Math.ceil(heights[index] / rowHeight));

    const setRef = ref => childrenRefs.current[index] = ref;

    return React.createElement(Box, {
      as: childrenAs,
      gridRow: "span " + rowSpan
    }, React.cloneElement(child, {
      ref: setRef
    }));
  }), React.createElement(Box, null));
};