import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { map, compose, join, compact, startCase } from 'tiny-compose-fns';
import { metadataBasename, getPrimaryMetadataType } from 'helpers';
import { SlidingPhotoCards } from 'src/components/SlidingPhotoCards';
export const PageBodyFeaturedExperiences = _ref => {
  let {
    experiences
  } = _ref,
      props = _objectWithoutProperties(_ref, ["experiences"]);

  return React.createElement(SlidingPhotoCards, Object.assign({
    items: experiences
  }, props));
};

PageBodyFeaturedExperiences.mapDataToProps = ({
  data,
  previousType,
  previousContext,
  nextContext
}) => ({
  previousIsHeader: previousType === 'PageBodyHeader',
  previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
  nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
  experiences: map(item => {
    var _item$experience, _doc$data, _doc$data2, _doc$data2$title, _main$primary, _main$primary2, _main$primary3, _main$primary4, _main$primary4$featur, _main$primary5, _main$primary5$featur, _main$primary6, _main$primary6$featur;

    const doc = item === null || item === void 0 ? void 0 : (_item$experience = item.experience) === null || _item$experience === void 0 ? void 0 : _item$experience.document;
    const main = doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : _doc$data.main[0];
    const type = compose(startCase, metadataBasename, getPrimaryMetadataType)(doc);
    return {
      type,
      uid: doc === null || doc === void 0 ? void 0 : doc.uid,
      url: doc === null || doc === void 0 ? void 0 : doc.url,
      title: doc === null || doc === void 0 ? void 0 : (_doc$data2 = doc.data) === null || _doc$data2 === void 0 ? void 0 : (_doc$data2$title = _doc$data2.title) === null || _doc$data2$title === void 0 ? void 0 : _doc$data2$title.text,
      description: main === null || main === void 0 ? void 0 : (_main$primary = main.primary) === null || _main$primary === void 0 ? void 0 : _main$primary.description,
      dates: compose(join(' - '), compact)([main === null || main === void 0 ? void 0 : (_main$primary2 = main.primary) === null || _main$primary2 === void 0 ? void 0 : _main$primary2.start_date, main === null || main === void 0 ? void 0 : (_main$primary3 = main.primary) === null || _main$primary3 === void 0 ? void 0 : _main$primary3.end_date]),
      imageURL: main === null || main === void 0 ? void 0 : (_main$primary4 = main.primary) === null || _main$primary4 === void 0 ? void 0 : (_main$primary4$featur = _main$primary4.featured_image) === null || _main$primary4$featur === void 0 ? void 0 : _main$primary4$featur.url,
      imageAlt: main === null || main === void 0 ? void 0 : (_main$primary5 = main.primary) === null || _main$primary5 === void 0 ? void 0 : (_main$primary5$featur = _main$primary5.featured_image) === null || _main$primary5$featur === void 0 ? void 0 : _main$primary5$featur.alt,
      imageFluid: main === null || main === void 0 ? void 0 : (_main$primary6 = main.primary) === null || _main$primary6 === void 0 ? void 0 : (_main$primary6$featur = _main$primary6.featured_image) === null || _main$primary6$featur === void 0 ? void 0 : _main$primary6$featur.fluid
    };
  }, data === null || data === void 0 ? void 0 : data.items)
});

PageBodyFeaturedExperiences.mapDataToContext = () => ({
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});

export const fragment = "3313327727";