import React from 'react';
import { Heading } from 'system';
export const Subheading = props => React.createElement(Heading, Object.assign({
  as: "h3",
  color: "red.40",
  fontFamily: "sans",
  fontWeight: "semibold",
  fontSizeScale: ['s', null, 'b'],
  lineHeight: "copy",
  textStyle: "trackedCaps"
}, props));