import { FieldsTable, Field, LinkCard, SliceExamples, Example } from 'reference';
import { Layout } from 'gatsby-theme-ww-prismic/src/components/Layout';
import { PageBodyImageCallout } from 'src/slices/PageBodyImageCallout';
import React from 'react';
export default {
  FieldsTable,
  Field,
  LinkCard,
  SliceExamples,
  Example,
  Layout,
  PageBodyImageCallout,
  React
};