import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Box } from 'system';
import { propPairsEq } from 'src/helpers';
import { BoundedBox } from 'src/components/BoundedBox';
import { SimplePhotoCard } from 'src/components/SimplePhotoCard';
import { GridVerticalRhythm } from 'src/components/GridVerticalRhythm';
import { Pagination } from 'src/components/Pagination';
import { usePaginatedCollection } from 'src/hooks/usePagination';
export const PageBodyLatestNewsCards = _ref => {
  let {
    initialPage
  } = _ref,
      props = _objectWithoutProperties(_ref, ["initialPage"]);

  const {
    page,
    goToPage,
    ref
  } = usePaginatedCollection({
    initialPage,
    collectionId: initialPage.collection.id,
    urlKey: 'page'
  });
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    ref: ref
  }, props), React.createElement(GridVerticalRhythm, {
    display: ['block', null, 'grid'],
    as: "ul",
    childrenAs: "li",
    gridTemplateColumns: ['1fr', null, 'repeat(3, 1fr)'],
    gridColumnGapScale: [0, 'm'],
    rowHeight: 8
  }, page.nodes.map(node => {
    return React.createElement(Box, {
      key: node.uid,
      borderBottom: ['1px solid', null, '0'],
      borderColor: ['gray.90'],
      ptScale: ['m-', 'm', 0],
      pbScale: ['m-', 'm'],
      className: "photo-card"
    }, React.createElement(SimplePhotoCard, {
      href: node.url,
      heading: node.title,
      description: node.description,
      subheading: node.date,
      subheadingColor: "teal.70",
      isSubheadingCaps: false,
      imageAlt: node.imageAlt,
      imageFluid: node.imageFluid,
      showDescriptionOnMobile: false
    }));
  })), React.createElement(Pagination, {
    total: page.collection.pageCount,
    current: page.index,
    handleSet: goToPage
  }));
};

PageBodyLatestNewsCards.mapDataToProps = ({
  context,
  previousType,
  previousContext,
  nextContext,
  meta
}) => {
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    initialPage: meta.rootData.paginatedCollectionPage
  };
};

PageBodyLatestNewsCards.mapDataToContext = () => ({
  backgroundColor: 'white',
  headerLogoColor: 'white',
  headerBg: 'teal.75',
  backgroundColor: 'white'
});

export const fragment = "3024223170";