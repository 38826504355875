const {
  linearScale
} = require('styled-system-scale');

const theme = {
  breakpoints: [48, 68, 100, 130].map(x => x + 'rem'),
  colors: {
    gray: {
      20: '#3d3d3d',
      40: '#E8EAE3',
      60: '#979797',
      90: '#e7eae2',
      95: '#f9f9f7'
    },
    teal: {
      40: '#617672',
      70: '#00bfcd',
      75: '#2ecbc8'
    },
    red: {
      40: '#dc0024'
    },
    orange: {
      60: '#ff6b00'
    },
    yellow: {
      80: '#ffda28'
    },
    white: '#fff',
    black: '#000'
  },
  fonts: {
    sans: 'futura-pt, sans-serif',
    serif: '"Cormorant Garamond", serif'
  },
  fontSizes: linearScale('0.8rem', '5rem', {
    difference: 0.1
  }),
  fontSizeScales: {
    t: linearScale('0.7rem', '0.85rem', {
      count: 5
    }),
    s: linearScale('0.875rem', '1rem', {
      count: 5
    }),
    's+': linearScale('0.925rem', '1.125rem', {
      count: 5
    }),
    b: linearScale('1rem', '1.45rem', {
      count: 5
    }),
    m: linearScale('1.125rem', '1.75rem', {
      count: 5
    }),
    l: linearScale('1.35rem', '2.625rem', {
      count: 5
    }),
    xl: linearScale('1.65rem', '3.5rem', {
      count: 5
    }),
    h: linearScale('2.625rem', '6.25rem', {
      count: 5
    })
  },
  spaceScales: {
    0: [0, 0, 0, 0, 0],
    't-': linearScale('4px', '12px', {
      count: 5
    }),
    t: linearScale('8px', '16px', {
      count: 5
    }),
    't+': linearScale('8px', '20px', {
      count: 5
    }),
    's-': linearScale('14px', '22px', {
      count: 5
    }),
    s: linearScale('16px', '27px', {
      count: 5
    }),
    's+': linearScale('20px', '33px', {
      count: 5
    }),
    'm-': linearScale('20px', '44px', {
      count: 5
    }),
    m: linearScale('25px', '55px', {
      count: 5
    }),
    l: linearScale('30px', '70px', {
      count: 5
    }),
    xl: linearScale('40px', '100px', {
      count: 5
    }),
    xxl: linearScale('50px', '120px', {
      count: 5
    }),
    '2xxl': linearScale('100px', '240px', {
      count: 5
    })
  },
  lineHeights: {
    solid: '1',
    title: '1.15',
    copy: '1.4',
    wide: '1.5'
  },
  sizes: {
    medium: '48rem',
    large: '60rem',
    xlarge: '100rem',
    xxlarge: '130rem'
  },
  sizes: {
    small: '32rem',
    medium: '48rem',
    'medium+': '54rem',
    large: '68rem',
    'large+': '80rem',
    xlarge: '100rem',
    xxlarge: '120rem'
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800
  },
  transitionDurations: {
    slow: '300ms',
    normal: '200ms',
    fast: '100ms'
  },
  boxStyles: {
    lastNoMargin: {
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0
      }
    },
    firstLastNoMargin: {
      '&:first-child': {
        marginTop: 0,
        marginLeft: 0
      },
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0
      }
    }
  },
  textStyles: {
    caps: {
      textTransform: 'uppercase'
    },
    trackedCaps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    }
  },
  zIndices: {
    speedBumpModal: 7,
    speedBumpOverlay: 6,
    header: 5,
    slidingMenu: 4,
    dottedPathCta: 3,
    footerFlakeTeal: 2,
    dottedPathImage: 1,
    dottedPathImageGradient: 1,
    footerContent: 1,
    heroSliceImage: 1,
    infoCards: 1,
    footerFlakeTransparent: 0,
    footerTealBox: -1
  }
};
theme.fontSizes.normalMedium = '1.2rem';
theme.fontSizes.medium = '1.4rem';
theme.fontSizes.large = '1.6rem';
theme.fontSizes.xlarge = '2.1rem';
theme.fontSizes.xxlarge = '2.5rem';
theme.fontSizes.xxxlarge = '3.25rem';
module.exports = {
  theme
};