import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.search";
import { useState, useLayoutEffect, useRef, useCallback } from 'react';
import { isBrowser } from 'helpers';
export const usePagination = (initialVal, sectionRef) => {
  const isInitialRender = useRef(true);
  const [page, set] = useState(initialVal);
  useLayoutEffect(() => {
    if (!(sectionRef === null || sectionRef === void 0 ? void 0 : sectionRef.current)) return;

    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    sectionRef.current.scrollIntoView();
  }, [sectionRef, page]);
  return [page, set];
};
export const usePaginationWithUrlParams = (initialVal, sectionRef) => {
  const [page, set] = useState(() => {
    if (!isBrowser) return initialVal;
    const searchParams = new URLSearchParams(location.search);
    const currPage = searchParams.get('page');
    if (currPage) return currPage - 1;
    return initialVal;
  });

  const setPage = idx => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', idx + 1);
    history.replaceState(null, '', window.location.pathname + "?" + searchParams.toString());
    set(idx);
  };

  useLayoutEffect(() => {
    if (!sectionRef) return;
    const sectionNode = sectionRef.current;
    const searchParams = new URLSearchParams(location.search);
    const currPage = searchParams.get('page');
    if (sectionNode && currPage) sectionNode.scrollIntoView();
  }, [sectionRef, page]);
  return [page, setPage];
};
export const usePaginatedCollection = ({
  collectionId,
  initialPage,
  urlKey
}) => {
  const ref = useRef();
  const [page, setPage] = useState(initialPage);
  const fetchPage = useCallback(async idx => {
    const result = await fetch("/paginated-data/" + collectionId + "/" + idx + ".json");
    const page = await result.json();
    return page;
  }, [collectionId]);
  const scrollRefIntoView = useCallback(() => {
    if (!(ref === null || ref === void 0 ? void 0 : ref.current)) return;
    ref.current.scrollIntoView();
  }, [ref]);

  const goToPage = async idx => {
    if (typeof idx !== 'number') return;

    if (urlKey) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(urlKey, idx + 1);
      history.pushState(null, '', window.location.pathname + "?" + searchParams.toString());
    }

    setPage((await fetchPage(idx)));
    scrollRefIntoView();
  };

  useLayoutEffect(() => {
    if (!urlKey) return;

    const loadPageFromUrl = async () => {
      const searchParams = new URLSearchParams(location.search);
      const currPageIdx = searchParams.get(urlKey);
      if (!currPageIdx) return;
      scrollRefIntoView();
      setPage((await fetchPage(currPageIdx - 1)));
    };

    loadPageFromUrl();
    window.addEventListener('popstate', loadPageFromUrl);
    return () => {
      if (!urlKey) return;
      window.removeEventListener('popstate', loadPageFromUrl);
    };
  }, [fetchPage, scrollRefIntoView, urlKey]);
  return {
    page,
    goToPage,
    ref
  };
};