import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { useSpeedBump, useSpeedBumpSettings } from 'src/hooks/useSpeedBump';
import { Link } from 'system';
import { isInternal } from 'helpers';
export const Anchor = _ref => {
  let {
    href = '',
    withExternalCheck = true
  } = _ref,
      props = _objectWithoutProperties(_ref, ["href", "withExternalCheck"]);

  const {
    setHref,
    showSpeedBump
  } = useSpeedBump();
  const {
    allowList,
    blockList
  } = useSpeedBumpSettings();

  const handleClick = event => {
    // If we opt out of checking, navigate as normal.
    if (!withExternalCheck) return;
    const isHrefInternal = isInternal(href); // Internal href's can be something like: "/about", which will
    // throw an error if provided to the URL constructor, so let's ensure that it's safe:

    const safeHref = isHrefInternal ? location.origin + href : href;
    const host = new URL(safeHref).host; // If the allowList specifies this host, navigate as normal.

    if (allowList.includes(host)) return; // If this host is not on the block list and it's an internal link, navigate as normal.

    if (!blockList.includes(host) && isHrefInternal) return; // Otherwise, we show the speedbump popup.

    event.preventDefault();
    setHref(href);
    showSpeedBump();
  };

  return React.createElement(Link, Object.assign({
    href: href,
    onClick: handleClick
  }, props));
};