import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import React, { useState } from 'react';
import { getRichText } from 'helpers';
import { camelCase, map, forEach, keys, values, compact, compose, size } from 'tiny-compose-fns';
import { object as yupObject, boolean as yupBoolean } from 'yup';
import { FastField as FastFormikField } from 'formik';
import { Box } from 'system';
import { HTMLContent } from 'src/components/HTMLContent';
import { Subheading } from 'src/components/Subheading';
import { RadioField } from 'src/components/RadioField';
import { FormikError } from 'src/components/FormikError';

const getInitialCheckboxMap = options => {
  const initialState = {};
  forEach(option => initialState[option] = false, options);
  return initialState;
};

export const FormBodyCheckboxField = ({
  width,
  label,
  name,
  helpTextHTML,
  options,
  setFieldTouched,
  setFieldValue
}) => {
  const isFullWidth = width === 'full';
  const [state, setState] = useState(() => getInitialCheckboxMap(options));

  const handleChange = ({
    event,
    option
  }) => {
    setState(state => {
      const newState = _objectSpread({}, state, {
        [option]: event.target.checked
      });

      setFieldValue(name, newState);
      setFieldTouched(name, true);
      return newState;
    });
  };

  return React.createElement(Box, {
    gridColumn: [1, isFullWidth ? 'span 2' : 'span 1'],
    boxStyle: "lastNoMargin"
  }, React.createElement(Box, {
    as: "fieldset",
    border: "none"
  }, label && React.createElement(Subheading, {
    as: "legend",
    color: "teal.40",
    mbScale: "t"
  }, label), helpTextHTML && React.createElement(HTMLContent, {
    html: helpTextHTML,
    mbScale: "s"
  }), map(option => React.createElement(RadioField, {
    key: option,
    as: FastFormikField,
    name: name,
    label: option,
    type: "checkbox",
    onChange: event => handleChange({
      event,
      option
    }),
    subheadingProps: {
      as: 'p',
      textStyle: 'normal',
      fontWeight: 'normal'
    },
    mbScale: "t"
  }), keys(state))), React.createElement(FormikError, {
    name: name
  }));
};

FormBodyCheckboxField.mapDataToProps = ({
  data,
  meta
}) => {
  var _data$primary, _data$primary2, _data$primary2$label, _data$primary3, _data$primary3$label, _data$primary4;

  return {
    width: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.width),
    label: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$label = _data$primary2.label) === null || _data$primary2$label === void 0 ? void 0 : _data$primary2$label.text,
    name: camelCase(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$label = _data$primary3.label) === null || _data$primary3$label === void 0 ? void 0 : _data$primary3$label.text),
    helpTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.help_text),
    options: map(item => {
      var _item$option;

      return item === null || item === void 0 ? void 0 : (_item$option = item.option) === null || _item$option === void 0 ? void 0 : _item$option.text;
    }, data === null || data === void 0 ? void 0 : data.items),
    setFieldValue: meta === null || meta === void 0 ? void 0 : meta.setFieldValue,
    setFieldTouched: meta === null || meta === void 0 ? void 0 : meta.setFieldTouched
  };
};

FormBodyCheckboxField.validationSchema = ({
  data
}) => {
  var _data$primary5, _data$primary6;

  const minimumChecks = parseInt(data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : _data$primary5.minimum_checks);
  const maximumChecks = parseInt(data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : _data$primary6.maximum_checks);
  const hasSameConstraints = minimumChecks === maximumChecks;
  const options = map(item => {
    var _item$option2;

    return item === null || item === void 0 ? void 0 : (_item$option2 = item.option) === null || _item$option2 === void 0 ? void 0 : _item$option2.text;
  }, data === null || data === void 0 ? void 0 : data.items);
  const shape = {};
  forEach(option => shape[option] = yupBoolean(), options);
  const schema = yupObject().shape(shape).test('meetsMinimum', hasSameConstraints ? "Please choose " + minimumChecks + " boxes." : "Please check at least " + minimumChecks + " boxes.", checkboxes => compose(size, compact, values)(checkboxes) >= minimumChecks).test('meetsMaximum', hasSameConstraints ? "Please choose " + minimumChecks + " boxes." : "Please check less than " + maximumChecks + " boxes.", checkboxes => compose(size, compact, values)(checkboxes) <= maximumChecks);
  return schema;
};

FormBodyCheckboxField.initialValues = ({
  data
}) => getInitialCheckboxMap(map(item => {
  var _item$option3;

  return item === null || item === void 0 ? void 0 : (_item$option3 = item.option) === null || _item$option3 === void 0 ? void 0 : _item$option3.text;
}, data === null || data === void 0 ? void 0 : data.items));

export const fragment = "1244375249";