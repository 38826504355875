import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import css from '@styled-system/css';
import { propPairsEq } from 'src/helpers';
import { Flex, Box, Text } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Subheading } from 'src/components/Subheading';
import AssetTealDashedBorder from 'src/assets/teal-dashed-border.png';

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyPullQuote___StyledBox",
  componentId: "y1qka3-0"
})(["", ""], p => p._css);

var _StyledBox2 = _styled(Box).withConfig({
  displayName: "PageBodyPullQuote___StyledBox2",
  componentId: "y1qka3-1"
})(["", ""], p => p._css2);

var _StyledBox3 = _styled(Box).withConfig({
  displayName: "PageBodyPullQuote___StyledBox3",
  componentId: "y1qka3-2"
})(["", ""], p => p._css3);

var _StyledBox4 = _styled(Box).withConfig({
  displayName: "PageBodyPullQuote___StyledBox4",
  componentId: "y1qka3-3"
})(["", ""], p => p._css4);

export const PageBodyPullQuote = _ref => {
  let {
    quoteText,
    quoteeName
  } = _ref,
      props = _objectWithoutProperties(_ref, ["quoteText", "quoteeName"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    maxWidth: "xlarge",
    plScale: ['0', 'l'],
    prScale: ['0', 'l']
  }, props), React.createElement(Flex, {
    justifyContent: "center"
  }, React.createElement(_StyledBox, {
    width: 10 / 12,
    pyScale: "s",
    borderStyle: "solid",
    borderWidth: "4px",
    _css: css({
      borderBottomWidth: '0px',
      borderImage: "url(" + AssetTealDashedBorder + ") 70 repeat"
    })
  })), React.createElement(Flex, null, React.createElement(_StyledBox2, {
    width: 1 / 12,
    borderStyle: "solid",
    borderWidth: "4px",
    _css2: css({
      borderLeftWidth: ['0px', '4px'],
      borderRightWidth: '0px',
      borderImage: "url(" + AssetTealDashedBorder + ") 70 repeat"
    })
  }), React.createElement(Text, {
    pyScale: "m",
    width: 10 / 12,
    textAlign: "center"
  }, React.createElement(Text, {
    color: "teal.70",
    fontFamily: "serif",
    fontSizeScale: "l",
    fontStyle: "italic",
    lineHeight: "copy",
    mbScale: "s+",
    boxStyle: "lastNoMargin"
  }, "\u201C", quoteText, "\u201D"), quoteeName && React.createElement(Subheading, {
    as: "p",
    color: "gray.20",
    fontSizeScale: ['s', null, 'm']
  }, "\u2014 ", quoteeName)), React.createElement(_StyledBox3, {
    width: 1 / 12,
    borderStyle: "solid",
    borderWidth: "4px",
    _css3: css({
      borderLeftWidth: '0px',
      borderRightWidth: ['0px', '4px'],
      borderImage: "url(" + AssetTealDashedBorder + ") 70 repeat"
    })
  })), React.createElement(Flex, {
    justifyContent: "center"
  }, React.createElement(_StyledBox4, {
    width: 10 / 12,
    pyScale: "s",
    borderStyle: "solid",
    borderWidth: "4px",
    borderTop: 0,
    _css4: css({
      borderTopWidth: '0px',
      borderImage: "url(" + AssetTealDashedBorder + ") 70 repeat"
    })
  })));
};

PageBodyPullQuote.mapDataToProps = ({
  previousContext,
  nextContext,
  context,
  previousType,
  data
}) => {
  var _data$primary, _data$primary$text, _data$primary2;

  return {
    quoteText: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$text = _data$primary.text) === null || _data$primary$text === void 0 ? void 0 : _data$primary$text.text,
    quoteeName: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.quotee_name,
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousIsHeader: previousType === 'PageBodyHeader'
  };
};

PageBodyPullQuote.mapDataToContext = () => ({
  backgroundColor: 'white'
});

export const fragment = "4081943808";