import _styled from "styled-components";
import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { getRichText, notEmpty } from 'helpers';
import { string as yupString } from 'yup';
import { boolean } from 'boolean';
import { FastField as FastFormikField } from 'formik';
import { camelCase } from 'tiny-compose-fns';
import { Box, Input } from 'system';
import { FormikError } from 'src/components/FormikError';
import { Subheading } from 'src/components/Subheading';
import { HTMLContent } from 'src/components/HTMLContent';
const inputTypes = {
  email: 'email',
  phoneNumber: 'tel',
  text: 'text'
};

var _StyledInput = _styled(Input).withConfig({
  displayName: "FormBodyTextField___StyledInput",
  componentId: "cpxi0f-0"
})(["&::placeholder{color:", ";}"], p => p._css);

export const FormBodyTextField = ({
  isRequired,
  type = 'text',
  width,
  placeholderText,
  label,
  name,
  helpTextHTML
}) => {
  const isFullWidth = width === 'full';
  const inputType = inputTypes[type];
  return React.createElement(Box, {
    gridColumn: [1, isFullWidth ? 'span 2' : 'span 1'],
    boxStyle: "lastNoMargin"
  }, React.createElement(Box, null, label && React.createElement(Subheading, {
    as: "label",
    htmlFor: name,
    color: "teal.40",
    mbScale: "t"
  }, label), helpTextHTML && React.createElement(HTMLContent, {
    html: helpTextHTML,
    mbScale: "s"
  }), React.createElement(_StyledInput, {
    as: FastFormikField,
    type: inputType,
    name: name,
    id: name,
    placeholder: placeholderText,
    required: isRequired,
    pyScale: "t+",
    pxScale: "s",
    border: ['1px solid', '2px solid'],
    borderColor: ['gray.90', 'gray.90'],
    borderRadius: "8px",
    fontSizeScale: "m",
    width: "100%",
    _css: themeGet('colors.gray.90')
  }), React.createElement(FormikError, {
    name: name
  })));
};

FormBodyTextField.mapDataToProps = ({
  data
}) => {
  var _data$primary, _data$primary2, _data$primary3, _data$primary4, _data$primary4$placeh, _data$primary5, _data$primary5$label, _data$primary6, _data$primary6$label, _data$primary7;

  return {
    isRequired: boolean(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.required),
    type: notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.type)),
    width: camelCase(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.width),
    placeholderText: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$placeh = _data$primary4.placeholder_text) === null || _data$primary4$placeh === void 0 ? void 0 : _data$primary4$placeh.text,
    label: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$label = _data$primary5.label) === null || _data$primary5$label === void 0 ? void 0 : _data$primary5$label.text,
    name: camelCase(data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : (_data$primary6$label = _data$primary6.label) === null || _data$primary6$label === void 0 ? void 0 : _data$primary6$label.text),
    helpTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : _data$primary7.help_text)
  };
};

FormBodyTextField.validationSchema = ({
  data
}) => {
  var _data$primary8, _data$primary9, _data$primary10, _data$primary10$label;

  const isRequired = boolean(data === null || data === void 0 ? void 0 : (_data$primary8 = data.primary) === null || _data$primary8 === void 0 ? void 0 : _data$primary8.required);
  const type = camelCase(data === null || data === void 0 ? void 0 : (_data$primary9 = data.primary) === null || _data$primary9 === void 0 ? void 0 : _data$primary9.type);
  const name = data === null || data === void 0 ? void 0 : (_data$primary10 = data.primary) === null || _data$primary10 === void 0 ? void 0 : (_data$primary10$label = _data$primary10.label) === null || _data$primary10$label === void 0 ? void 0 : _data$primary10$label.text;
  let schema = yupString();
  if (isRequired) schema = schema.required(name + " is required.");else schema = schema.notRequired();
  if (type === 'email') schema = schema.email('Please provide a valid email address.');
  if (type === 'phoneNumber') schema = schema.matches(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/, 'Please provide a valid phone number.');
  return schema;
};

FormBodyTextField.initialValues = () => '';

export const fragment = "1179357959";