import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { addScales, subtractScales } from 'styled-system-scale';
import { firstLeft } from 'src/helpers';
import { useMediaIndex } from 'src/hooks/useMediaIndex';
import { theme } from 'src/theme';
import { HEADER_HEIGHT_SCALE } from 'src/constants';
import { BoundedBox as BoundedBoxBase } from 'system';
export const BoundedBox = React.forwardRef((_ref, ref) => {
  let {
    previousOverhangs = false,
    previousIsHeader = false,
    nextOverhangs = false,
    nextSharesBackground = false,
    previousIsArticleTypeWithSameBackground = false,
    ptAdd,
    pbAdd,
    prAdd,
    plAdd,
    pyAdd,
    pxAdd,
    pAdd,
    pt,
    pb,
    pr,
    pl,
    ptScale,
    pbScale,
    plScale,
    prScale,
    pyScale = 'xxl',
    pxScale = 'l'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["previousOverhangs", "previousIsHeader", "nextOverhangs", "nextSharesBackground", "previousIsArticleTypeWithSameBackground", "ptAdd", "pbAdd", "prAdd", "plAdd", "pyAdd", "pxAdd", "pAdd", "pt", "pb", "pr", "pl", "ptScale", "pbScale", "plScale", "prScale", "pyScale", "pxScale"]);

  const mediaIndex = useMediaIndex();
  pb = pb === undefined ? theme.spaceScales[firstLeft(mediaIndex, pbScale) || firstLeft(mediaIndex, pyScale)] : pb;
  if (firstLeft(mediaIndex, nextOverhangs)) pb = addScales(pb, theme.spaceScales.xxl);
  if (firstLeft(mediaIndex, nextSharesBackground)) pb = subtractScales(pb, pb);
  if (pbAdd || pyAdd || pAdd) pb = addScales(pb, pbAdd || pyAdd || pAdd);
  pt = pt === undefined ? theme.spaceScales[firstLeft(mediaIndex, ptScale) || firstLeft(mediaIndex, pyScale)] : pt;
  if (firstLeft(mediaIndex, previousOverhangs)) pt = addScales(pt, theme.spaceScales.xxl);
  if (firstLeft(mediaIndex, previousIsHeader)) pt = addScales(pt, HEADER_HEIGHT_SCALE);
  if (firstLeft(mediaIndex, previousIsArticleTypeWithSameBackground)) pt = subtractScales(pt, theme.spaceScales.m);
  if (ptAdd || pyAdd || pAdd) pt = addScales(pt, ptAdd || pyAdd || pAdd);
  pl = pl === undefined ? theme.spaceScales[firstLeft(mediaIndex, plScale) || firstLeft(mediaIndex, pxScale)] : pl;
  if (plAdd || pxAdd || pAdd) pl = addScales(pl, plAdd || pxAdd || pAdd);
  pr = pr === undefined ? theme.spaceScales[firstLeft(mediaIndex, prScale) || firstLeft(mediaIndex, pxScale)] : pr;
  if (prAdd || pxAdd || pAdd) pr = addScales(pl, prAdd || pxAdd || pAdd);
  return React.createElement(BoundedBoxBase, Object.assign({
    ref: ref,
    maxWidth: "xxlarge",
    pb: pb,
    pl: pl,
    pr: pr,
    pt: pt
  }, props));
});