import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { motion } from 'framer-motion';
import { getRichText } from 'helpers';
import { isEmpty } from 'tiny-compose-fns';
import { firstLeft, responsiveProp } from 'src/helpers';
import { useMediaIndex } from 'src/hooks/useMediaIndex';
import { theme } from 'src/theme';
import { Box, Flex } from 'system';
import { Anchor } from 'src/components/Anchor';
import { Card } from 'src/components/Card';
import { HTMLContent } from 'src/components/HTMLContent';
import { Heading } from 'src/components/Heading';
import { OutlinedBoxes } from 'src/components/OutlinedBoxes';
const colorSchemes = {
  white: {
    bg: 'white',
    color: 'gray.20',
    headingColor: 'gray.20',
    headingSecondary: 'gray.20',
    text: 'gray.20',
    link: 'orange.60',
    linkHover: 'teal.70',
    buttonColorScheme: 'orange'
  },
  teal: {
    bg: 'teal.75',
    color: 'gray.20',
    headingColor: 'white',
    headingSecondary: 'gray.20',
    text: 'gray.20',
    link: 'white',
    linkHover: 'gray.20',
    buttonColorScheme: 'white'
  }
};

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "InfoCard___StyledAnchor",
  componentId: "l87r3y-0"
})(["&:hover,&:focus{color:", ";}"], p => p._css);

export const InfoCard = _ref => {
  let {
    buttonHref,
    buttonTarget,
    buttonText,
    children,
    colorScheme = 'white',
    withTab = true,
    tabPosition = 'top',
    contentAnimate,
    textHTML,
    highlights,
    footnoteHTML
  } = _ref,
      props = _objectWithoutProperties(_ref, ["buttonHref", "buttonTarget", "buttonText", "children", "colorScheme", "withTab", "tabPosition", "contentAnimate", "textHTML", "highlights", "footnoteHTML"]);

  const mediaIndex = useMediaIndex();
  const colors = colorSchemes[colorScheme];
  const tabPositionForMedia = firstLeft(mediaIndex, tabPosition);
  return React.createElement(Flex, Object.assign({
    flexDirection: "column"
  }, props), withTab && React.createElement(Box, {
    bg: colors.bg,
    height: theme.spaceScales.l,
    width: [3 / 4, null, null, 2 / 3],
    order: responsiveProp(x => x === 'top' ? 0 : 1, tabPosition)
  }), React.createElement(Box, {
    bg: colors.bg,
    flex: "1 1 auto",
    overflow: "hidden"
  }, React.createElement(Box, {
    as: motion.div,
    initial: false,
    animate: contentAnimate
  }, React.createElement(Card, {
    bg: "transparent",
    color: colors.color,
    buttonText: buttonText,
    buttonTarget: buttonTarget,
    buttonHref: buttonHref,
    buttonColorScheme: colors.buttonColorScheme,
    innerProps: {
      ptScale: [withTab && tabPositionForMedia === 'top' ? 's' : 'xl', 's'],
      pbScale: [withTab && tabPositionForMedia === 'bottom' ? 's' : 'xl', 'xl']
    }
  }, children || React.createElement(React.Fragment, null, textHTML && React.createElement(HTMLContent, {
    html: textHTML,
    map: {
      h1: props => React.createElement(Heading, Object.assign({
        as: "h3",
        mtScale: "s+",
        mbScale: "s",
        boxStyle: "firstLastNoMargin",
        color: colors.headingColor
      }, props)),
      h2: props => React.createElement(Heading, Object.assign({
        as: "h4",
        mtScale: "s+",
        mbScale: "t-",
        boxStyle: "firstLastNoMargin",
        fontSizeScale: "b",
        color: colors.headingSecondary
      }, props)),
      a: props => React.createElement(_StyledAnchor, Object.assign({
        transitionProperty: "color",
        color: colors.link
      }, props, {
        _css: themeGet("colors." + colors.linkHover)
      }))
    },
    lineHeight: "wide"
  }), !isEmpty(highlights) && React.createElement(OutlinedBoxes, {
    myScale: "m",
    boxStyle: "lastNoMargin"
  }, highlights === null || highlights === void 0 ? void 0 : highlights.map((highlight, i) => React.createElement(OutlinedBoxes.Box, {
    key: i,
    textHTML: getRichText(highlight === null || highlight === void 0 ? void 0 : highlight.description)
  }))), footnoteHTML && React.createElement(HTMLContent, {
    html: footnoteHTML,
    fontSizeScale: "s",
    mtScale: "s+",
    width: [null, 3 / 4]
  }))))));
};