import React from 'react';
import { getRichText } from 'helpers';
import { string as yupString } from 'yup';
import { FastField as FastFormikField } from 'formik';
import { boolean } from 'boolean';
import { camelCase, map, isEmpty } from 'tiny-compose-fns';
import { Box, Input, SVG } from 'system';
import { ReactComponent as AssetIconChevronDownSVG } from 'src/assets/icon-chevron-down.svg';
import { Subheading } from 'src/components/Subheading';
import { HTMLContent } from 'src/components/HTMLContent';
export const FormBodySelectField = ({
  isRequired,
  defaultOption,
  width,
  label,
  name,
  helpTextHTML,
  options
}) => {
  const isFullWidth = width === 'full';
  return React.createElement(Box, {
    gridColumn: [1, isFullWidth ? 'span 2' : 'span 1'],
    boxStyle: "lastNoMargin"
  }, React.createElement(Box, null, label && React.createElement(Subheading, {
    as: "label",
    htmlFor: name,
    color: "teal.40",
    mbScale: "t"
  }, label), helpTextHTML && React.createElement(HTMLContent, {
    html: helpTextHTML,
    mbScale: "s"
  }), React.createElement(Box, {
    position: "relative",
    width: "min-content"
  }, React.createElement(Input, {
    as: FastFormikField,
    name: name,
    component: "select",
    id: name,
    border: ['1px solid', '2px solid'],
    borderColor: ['gray.90', 'gray.90'],
    borderRadius: "8px",
    pxScale: "m",
    pyScale: "t+",
    required: isRequired
  }, map(option => React.createElement("option", {
    key: option,
    selected: defaultOption === option
  }, option), options)), React.createElement(SVG, {
    svg: AssetIconChevronDownSVG,
    width: "12px",
    x: 17,
    y: 12,
    position: "absolute",
    right: 0,
    mrScale: "t",
    top: "50%",
    transform: "translateY(-50%)"
  }))));
};

FormBodySelectField.mapDataToProps = ({
  data
}) => {
  var _data$primary, _data$primary2, _data$primary2$defaul, _data$primary3, _data$primary4, _data$primary4$label, _data$primary5, _data$primary5$label, _data$primary6;

  return {
    isRequired: boolean(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.required),
    defaultOption: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$defaul = _data$primary2.default_option) === null || _data$primary2$defaul === void 0 ? void 0 : _data$primary2$defaul.text,
    width: camelCase(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.width),
    label: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$label = _data$primary4.label) === null || _data$primary4$label === void 0 ? void 0 : _data$primary4$label.text,
    name: camelCase(data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$label = _data$primary5.label) === null || _data$primary5$label === void 0 ? void 0 : _data$primary5$label.text),
    helpTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : _data$primary6.help_text),
    options: map(item => {
      var _item$option;

      return item === null || item === void 0 ? void 0 : (_item$option = item.option) === null || _item$option === void 0 ? void 0 : _item$option.text;
    }, data === null || data === void 0 ? void 0 : data.items)
  };
};

FormBodySelectField.validationSchema = ({
  data
}) => {
  var _data$primary7, _data$primary8, _data$primary8$defaul, _data$primary9, _data$primary9$label;

  const isRequired = boolean(data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : _data$primary7.required);
  const defaultOption = data === null || data === void 0 ? void 0 : (_data$primary8 = data.primary) === null || _data$primary8 === void 0 ? void 0 : (_data$primary8$defaul = _data$primary8.default_option) === null || _data$primary8$defaul === void 0 ? void 0 : _data$primary8$defaul.text;
  const name = data === null || data === void 0 ? void 0 : (_data$primary9 = data.primary) === null || _data$primary9 === void 0 ? void 0 : (_data$primary9$label = _data$primary9.label) === null || _data$primary9$label === void 0 ? void 0 : _data$primary9$label.text;
  let schema = yupString();
  if (isRequired) schema = schema.required(name + " is required.");else schema = schema.notRequired();
  if (!isEmpty(defaultOption)) schema = schema.default(defaultOption);
  return schema;
};

FormBodySelectField.initialValues = ({
  data
}) => {
  var _data$primary10, _data$primary10$defau;

  return data === null || data === void 0 ? void 0 : (_data$primary10 = data.primary) === null || _data$primary10 === void 0 ? void 0 : (_data$primary10$defau = _data$primary10.default_option) === null || _data$primary10$defau === void 0 ? void 0 : _data$primary10$defau.text;
};

export const fragment = "552417672";