import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import { ImageContainer, Image, Box, Flex, AspectRatio } from 'system';
import { HTMLContent } from 'src/components/HTMLContent';
import { Subheading } from 'src/components/Subheading';
import { Anchor } from 'src/components/Anchor';

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "AvatarCard___StyledAnchor",
  componentId: "sc-72hssh-0"
})(["&:hover,&:focus{color:", ";}"], p => p._css);

export const AvatarCard = _ref => {
  let {
    name,
    descriptionHTML,
    imageFluid,
    imageURL,
    imageAlt,
    href,
    target
  } = _ref,
      props = _objectWithoutProperties(_ref, ["name", "descriptionHTML", "imageFluid", "imageURL", "imageAlt", "href", "target"]);

  return React.createElement(Flex, Object.assign({
    alignItems: "flex-start"
  }, props), (imageFluid || imageURL) && React.createElement(ImageContainer, {
    border: ['3px solid', null, null, '5px solid'],
    borderColor: ['yellow.80', null, null, 'yellow.80'],
    borderRadius: "50%",
    maxWidth: ['8rem', null, null, '10rem'],
    mrScale: "m-",
    overflow: "hidden",
    bg: "gray.20",
    width: 1 / 4
  }, React.createElement(ConditionalWrap, {
    condition: href,
    wrap: children => React.createElement(Anchor, {
      href: href,
      target: target,
      tabIndex: "-1"
    }, children)
  }, React.createElement(AspectRatio, {
    x: 1,
    y: 1
  }, React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  })))), React.createElement(Box, {
    width: 3 / 4,
    flex: "1 1 auto"
  }, name && React.createElement(Subheading, {
    color: "teal.40",
    mbScale: "t"
  }, React.createElement(ConditionalWrap, {
    condition: href,
    wrap: children => React.createElement(_StyledAnchor, {
      href: href,
      target: target,
      transitionProperty: "color",
      _css: themeGet('colors.teal.70')
    }, children)
  }, name)), descriptionHTML && React.createElement(HTMLContent, {
    html: descriptionHTML
  })));
};