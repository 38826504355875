import { FieldsTable, Field, LinkCard, SliceExamples, Example } from 'reference';
import { Layout } from 'gatsby-theme-ww-prismic/src/components/Layout';
import { PageBodyInfoCards } from 'src/slices/PageBodyInfoCards';
import { InfoCard } from 'src/components/InfoCard';
import React from 'react';
export default {
  FieldsTable,
  Field,
  LinkCard,
  SliceExamples,
  Example,
  Layout,
  PageBodyInfoCards,
  InfoCard,
  React
};