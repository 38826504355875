import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState, useEffect } from 'react';
import { css, keyframes } from 'styled-components';
import { AspectRatio } from 'system';
const dash = keyframes(["to{stroke-dashoffset:0;}"]);

const usePathLength = pathId => {
  const [pathLength, setPathLength] = useState(null);
  useEffect(() => {
    const path = document.querySelector(pathId);
    setPathLength(path ? path.getTotalLength() : 0);
  }, [pathId]);
  return pathLength;
};

var _StyledAspectRatio = _styled(AspectRatio).withConfig({
  displayName: "AnimatedSVGPath___StyledAspectRatio",
  componentId: "u1zbe1-0"
})(["pointer-events:none;.paths{fill:none;}.mask{stroke-dasharray:", ";stroke-dashoffset:", ";animation:", ";}"], p => p._css, p => p._css2, p => p._css3);

export const AnimatedSVGPath = _ref => {
  let {
    pathId,
    xRatio,
    yRatio,
    inView,
    transform,
    strokeWidth = 5,
    strokeDashArray = '3,15',
    animationDuration = 4,
    path
  } = _ref,
      props = _objectWithoutProperties(_ref, ["pathId", "xRatio", "yRatio", "inView", "transform", "strokeWidth", "strokeDashArray", "animationDuration", "path"]);

  const pathLength = usePathLength("#" + pathId);
  return React.createElement(_StyledAspectRatio, Object.assign({
    x: xRatio,
    y: yRatio,
    width: "100%"
  }, props, {
    _css: pathLength,
    _css2: pathLength,
    _css3: inView ? css(["", " ", "s ease-out forwards"], dash, animationDuration) : ''
  }), React.createElement("svg", {
    viewBox: "0 0 " + xRatio + " " + yRatio,
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    height: "100%"
  }, React.createElement("g", {
    id: "404",
    fill: "none",
    fillRule: "evenodd",
    strokeLinejoin: "round",
    stroke: "#FFF",
    strokeWidth: strokeWidth,
    strokeDasharray: strokeDashArray,
    strokeLinecap: "round"
  }, React.createElement("g", {
    id: "404-Desktop",
    transform: transform,
    stroke: "#FFFFFF",
    strokeWidth: strokeWidth,
    strokeLinecap: "round"
  }, React.createElement("defs", null, React.createElement("path", {
    d: path,
    id: pathId
  }), React.createElement("mask", {
    id: pathId + "-mask"
  }, React.createElement("use", {
    className: "mask",
    xlinkHref: "#" + pathId
  }))), React.createElement("use", {
    className: "paths",
    xlinkHref: "#" + pathId,
    mask: "url(#" + pathId + "-mask)"
  })))));
};