import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { getMetadata } from 'helpers';
import { propPairsEq } from 'src/helpers';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Grid, Box, Text } from 'system';
import { Anchor } from 'src/components/Anchor';
import { Subheading } from 'src/components/Subheading';
import { Heading } from 'src/components/Heading';
import { BoundedBox } from 'src/components/BoundedBox';
export const PageBodyCareerOpenings = _ref => {
  let {
    heading,
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    maxWidth: ARTICLE_MAX_WIDTH,
    pyScale: "xl"
  }, props), heading && React.createElement(Heading, {
    as: "h3",
    mbScale: "m-"
  }, heading), React.createElement(Grid, {
    as: Text,
    display: ['none', null, 'grid'],
    gridTemplateColumns: ['1fr', null, '1fr .35fr .2fr'],
    gridColumnGapScale: "l",
    color: "teal.40",
    mbScale: "t"
  }, React.createElement(Subheading, {
    as: "div",
    color: "inherit"
  }, "Position"), React.createElement(Subheading, {
    as: "div",
    color: "inherit"
  }, "Date Posted")), React.createElement(Box, {
    as: "ul"
  }, children));
};

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "PageBodyCareerOpenings___StyledAnchor",
  componentId: "sc-8a5i2a-0"
})(["&:hover,&:focus{color:", ";}"], p => p._css);

var _StyledAnchor2 = _styled(Anchor).withConfig({
  displayName: "PageBodyCareerOpenings___StyledAnchor2",
  componentId: "sc-8a5i2a-1"
})(["&:hover,&:focus{color:", ";}"], p => p._css2);

PageBodyCareerOpenings.Opening = _ref2 => {
  let {
    jobTitle,
    postedDate,
    linkHref,
    linkTarget
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["jobTitle", "postedDate", "linkHref", "linkTarget"]);

  return React.createElement(Grid, Object.assign({
    as: "li",
    pyScale: "s",
    borderTop: ['1px solid', '2px solid'],
    borderColor: ['gray.90', 'gray.90'],
    gridTemplateColumns: ['1fr', null, '1fr .35fr .2fr'],
    gridColumnGapScale: "l",
    alignItems: "baseline"
  }, props), React.createElement(Heading, {
    as: "h4",
    mbScale: "t",
    fontSizeScale: "m"
  }, React.createElement(_StyledAnchor, {
    href: linkHref,
    target: linkTarget,
    transitionProperty: "color",
    _css: themeGet('colors.teal.75')
  }, jobTitle)), React.createElement(Subheading, {
    as: "div",
    mbScale: "t",
    color: "gray.20"
  }, React.createElement(Box, {
    display: ['inline', null, 'none']
  }, "Posted"), " ", postedDate), React.createElement(Subheading, {
    as: "p",
    color: "inherit",
    justifySelf: [null, null, 'end']
  }, React.createElement(_StyledAnchor2, {
    href: linkHref,
    target: linkTarget,
    color: "orange.60",
    transitionProperty: "color",
    _css2: themeGet('colors.teal.75')
  }, React.createElement(Text, {
    display: ['inline', null, 'none']
  }, "View"), " Details")));
};

PageBodyCareerOpenings.mapDataToProps = ({
  data,
  context,
  previousType,
  previousContext,
  nextContext
}) => {
  var _data$items, _data$primary, _data$primary$heading;

  const careerOpenings = data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.map(item => {
    var _item$career_opening, _doc$data, _doc$data$title, _metadata$primary, _item$career_opening2;

    const doc = item === null || item === void 0 ? void 0 : (_item$career_opening = item.career_opening) === null || _item$career_opening === void 0 ? void 0 : _item$career_opening.document;
    const metadata = getMetadata('PrismicPageMainCareerOpening', doc);
    return {
      title: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : (_doc$data$title = _doc$data.title) === null || _doc$data$title === void 0 ? void 0 : _doc$data$title.text,
      availableFrom: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : _metadata$primary.available_from,
      href: item === null || item === void 0 ? void 0 : (_item$career_opening2 = item.career_opening) === null || _item$career_opening2 === void 0 ? void 0 : _item$career_opening2.url
    };
  });
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    heading: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$heading = _data$primary.heading) === null || _data$primary$heading === void 0 ? void 0 : _data$primary$heading.text,
    children: careerOpenings === null || careerOpenings === void 0 ? void 0 : careerOpenings.map(careerOpening => React.createElement(PageBodyCareerOpenings.Opening, {
      jobTitle: careerOpening === null || careerOpening === void 0 ? void 0 : careerOpening.title,
      postedDate: careerOpening === null || careerOpening === void 0 ? void 0 : careerOpening.availableFrom,
      linkHref: careerOpening === null || careerOpening === void 0 ? void 0 : careerOpening.href
    }))
  };
};

PageBodyCareerOpenings.mapDataToContext = () => ({
  headerLogoColor: 'white',
  headerBg: 'teal.75',
  backgroundColor: 'white'
});

export const fragment = "1630130343";