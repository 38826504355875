import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import React, { createElement } from "react";
import { publicLoader } from "./loader";
import { apiRunner } from "./api-runner-browser"; // Renders page

class PageRenderer extends React.Component {
  render() {
    const props = _objectSpread({}, this.props, {
      pathContext: this.props.pageContext
    });

    const [replacementElement] = apiRunner("replaceComponentRenderer", {
      props: this.props,
      loader: publicLoader
    });
    const pageElement = replacementElement || createElement(this.props.pageResources.component, _objectSpread({}, props, {
      key: this.props.path || this.props.pageResources.page.path
    }));
    const wrappedPage = apiRunner("wrapPageElement", {
      element: pageElement,
      props
    }, pageElement, ({
      result
    }) => {
      return {
        element: result,
        props
      };
    }).pop();
    return wrappedPage;
  }

}

export default PageRenderer;