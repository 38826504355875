import React from 'react';
import { ThemeProvider, Text } from 'system';
import { theme } from 'src/theme';
import { MediaProvider } from 'src/hooks/useMediaIndex';
import { SpeedBumpProvider } from 'src/hooks/useSpeedBump';
export const PageWrapper = ({
  children
}) => {
  return React.createElement(ThemeProvider, {
    theme: theme
  }, React.createElement(MediaProvider, null, React.createElement(SpeedBumpProvider, null, React.createElement(Text, {
    fontFamily: "sans",
    fontSizeScale: "b",
    color: "gray.20",
    lineHeight: "copy"
  }, children))));
};