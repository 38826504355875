import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import { linearScale } from 'styled-system-scale';
import { Box, Link, SVG } from 'system';
import { Subheading } from 'src/components/Subheading';
import { ReactComponent as AssetIconChevronRight } from 'src/assets/icon-chevron-right.svg';

const Divider = props => React.createElement(Box, Object.assign({
  as: "li",
  display: "inline-block",
  "aria-hidden": true,
  flex: "0 0 auto",
  mxScale: "t"
}, props), React.createElement(SVG, {
  svg: AssetIconChevronRight,
  x: 15,
  y: 20,
  color: "gray.90",
  width: linearScale('0.5rem', '0.9rem', {
    count: 5
  })
}));

export const Breadcrumbs = _ref => {
  let {
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children"]);

  const childrenWithoutHome = React.Children.toArray(children).filter(child => child.props.href !== '');
  const count = React.Children.count(childrenWithoutHome);
  return count > 0 && React.createElement(Box, Object.assign({
    as: "ul",
    color: "gray.90"
  }, props), React.Children.map(childrenWithoutHome, (child, i) => React.createElement(React.Fragment, null, React.cloneElement(child, {
    href: i < count - 1 ? child.props.href : undefined
  }), i < count - 1 && React.createElement(Divider, null))));
};

var _StyledLink = _styled(Link).withConfig({
  displayName: "Breadcrumbs___StyledLink",
  componentId: "sc-1rhp6g0-0"
})(["&:hover,&:focus{color:", ";}"], p => p._css);

Breadcrumbs.Item = _ref2 => {
  let {
    children,
    href,
    target
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["children", "href", "target"]);

  return React.createElement(Box, Object.assign({
    as: "li",
    display: "inline-block"
  }, props), React.createElement(Subheading, {
    as: "span",
    color: "inherit",
    fontSizeScale: "m"
  }, React.createElement(ConditionalWrap, {
    condition: href,
    wrap: children => React.createElement(_StyledLink, {
      href: href,
      target: target,
      transitionProperty: "color",
      _css: themeGet('colors.yellow.80')
    }, children)
  }, children)));
};