module.exports = [{
  plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
  options: {
    "plugins": [],
    "name": "Shangri La Museum of Islamic Art, Culture, & Design",
    "short_name": "Shangri La",
    "start_url": "/",
    "background_color": "#000000",
    "theme_color": "#ffffff",
    "display": "minimal-ui",
    "icon": "/opt/build/repo/src/assets/manifest-icon.png"
  }
}, {
  plugin: require('../node_modules/@walltowall/gatsby-theme-ww-base/gatsby-browser.js'),
  options: {
    "plugins": [],
    "root": "/opt/build/repo",
    "siteTitle": "Shangri La Museum of Islamic Art, Culture, & Design",
    "siteTitleShort": "Shangri La",
    "siteDescription": "Shangri La is a museum for learning about the global cultures of Islamic art and design through exhibitions, digital and educational initiatives, public tours and programs, and community partnerships.",
    "siteUrl": "https://www.shangrilahawaii.org",
    "withNetlify": true,
    "withAxe": false
  }
}, {
  plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
  options: {
    "plugins": [],
    "repositoryName": "shangrilahawaii",
    "accessToken": "MC5YVDlvVEJFQUFDUUFBb192.77-9Bu-_vUtgJzoM77-977-977-9XWEX77-977-977-977-977-9Me-_vRJj77-977-9HEl-77-9HO-_vQA",
    "fetchLinks": ["page.parent"],
    "schemas": {
      "collection_object": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "object_number": {
            "type": "Text",
            "config": {
              "label": "Object Number"
            }
          },
          "description": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Description"
            }
          },
          "emuseum_link": {
            "type": "Link",
            "config": {
              "allowTargetBlank": true,
              "label": "eMuseum Link"
            }
          },
          "metadata": {
            "type": "Group",
            "config": {
              "fields": {
                "name": {
                  "type": "Text",
                  "config": {
                    "label": "Name"
                  }
                },
                "content": {
                  "type": "StructuredText",
                  "config": {
                    "multi": "paragraph, strong, em, list-item, o-list-item",
                    "allowTargetBlank": true,
                    "label": "Content"
                  }
                }
              },
              "label": "Metadata"
            }
          },
          "image": {
            "type": "Image",
            "config": {
              "constraint": {},
              "thumbnails": [],
              "label": "Image"
            }
          }
        }
      },
      "info_card": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "text": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, heading1, heading2, strong, em, hyperlink, list-item, o-list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Text"
            }
          },
          "highlights": {
            "type": "Group",
            "config": {
              "fields": {
                "description": {
                  "type": "StructuredText",
                  "config": {
                    "multi": "paragraph, heading1, strong, em",
                    "allowTargetBlank": true,
                    "label": "Description"
                  }
                }
              },
              "label": "Highlights"
            }
          },
          "footnote": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Footnote"
            }
          },
          "button_text": {
            "type": "Text",
            "config": {
              "label": "Button Text"
            }
          },
          "button_link": {
            "type": "Link",
            "config": {
              "allowTargetBlank": true,
              "label": "Button Link"
            }
          }
        }
      },
      "navigation": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "primary": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "nav_item": {
                  "type": "Slice",
                  "fieldset": "Nav Item",
                  "description": "Nav Item with Sub Navigation",
                  "icon": "insert_link",
                  "display": "list",
                  "non-repeat": {
                    "name": {
                      "type": "Text",
                      "config": {
                        "label": "Name"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link"
                      }
                    }
                  },
                  "repeat": {
                    "name": {
                      "type": "Text",
                      "config": {
                        "label": "Name"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "page": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "meta_title": {
            "type": "Text",
            "config": {
              "label": "Meta Title"
            }
          },
          "meta_description": {
            "type": "Text",
            "config": {
              "label": "Meta Description"
            }
          },
          "parent": {
            "type": "Link",
            "config": {
              "select": "document",
              "customtypes": ["page"],
              "label": "Parent"
            }
          },
          "main": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "exhibition": {
                  "type": "Slice",
                  "fieldset": "Exhibition",
                  "description": "Exhibition information",
                  "icon": "widgets",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description"
                      }
                    },
                    "start_date": {
                      "type": "Date",
                      "config": {
                        "label": "Start Date"
                      }
                    },
                    "end_date": {
                      "type": "Date",
                      "config": {
                        "label": "End Date"
                      }
                    },
                    "by_resident": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Residency"],
                        "label": "By Resident"
                      }
                    },
                    "featured_image_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Tall"],
                        "default_value": "Normal",
                        "label": "Featured Image Size"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "event": {
                  "type": "Slice",
                  "fieldset": "Event",
                  "description": "Event information",
                  "icon": "event_note",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description"
                      }
                    },
                    "start_date": {
                      "type": "Date",
                      "config": {
                        "label": "Start Date"
                      }
                    },
                    "end_date": {
                      "type": "Date",
                      "config": {
                        "label": "End Date"
                      }
                    },
                    "location": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Location"
                      }
                    },
                    "time": {
                      "type": "Text",
                      "config": {
                        "label": "Time"
                      }
                    },
                    "admission": {
                      "type": "Text",
                      "config": {
                        "label": "Admission"
                      }
                    },
                    "tickets_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Tickets Link",
                        "select": null
                      }
                    },
                    "by_resident": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Residency"],
                        "label": "By Resident"
                      }
                    },
                    "featured_image_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Tall"],
                        "default_value": "Normal",
                        "label": "Featured Image Size"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "residency": {
                  "type": "Slice",
                  "fieldset": "Residency",
                  "description": "Residency information",
                  "icon": "face",
                  "display": "list",
                  "non-repeat": {
                    "resident_type": {
                      "type": "Select",
                      "config": {
                        "options": ["Artist", "Scholar"],
                        "default_value": "Artist",
                        "label": "Resident Type"
                      }
                    },
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description",
                        "placeholder": "100-150 characters, including spaces, recommended"
                      }
                    },
                    "start_date": {
                      "type": "Date",
                      "config": {
                        "label": "Start Date"
                      }
                    },
                    "end_date": {
                      "type": "Date",
                      "config": {
                        "label": "End Date"
                      }
                    },
                    "featured_image_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Tall"],
                        "default_value": "Normal",
                        "label": "Featured Image Size"
                      }
                    },
                    "profile_picture": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Profile Picture"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "news": {
                  "type": "Slice",
                  "fieldset": "News",
                  "description": "News information",
                  "icon": "fiber_new",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description"
                      }
                    },
                    "date": {
                      "type": "Date",
                      "config": {
                        "label": "Date"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "career_opening": {
                  "type": "Slice",
                  "fieldset": "Career Opening",
                  "description": "Career opening information",
                  "icon": "person_add",
                  "display": "list",
                  "non-repeat": {
                    "available_from": {
                      "type": "Date",
                      "config": {
                        "label": "Available From"
                      }
                    },
                    "location": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Location"
                      }
                    },
                    "reports_to": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Reports To"
                      }
                    },
                    "status": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Status"
                      }
                    },
                    "apply_link": {
                      "type": "Link",
                      "config": {
                        "label": "Apply Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        },
        "Body": {
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "anchor": []
              },
              "choices": {
                "experience_cards": {
                  "type": "Slice",
                  "fieldset": "Experience Cards",
                  "description": "Cards linking to events, exhibitions, etc.",
                  "icon": "view_compact",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    }
                  },
                  "repeat": {
                    "item": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Item"
                      }
                    },
                    "display_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Large"],
                        "default_value": "Normal",
                        "label": "Display Size"
                      }
                    }
                  }
                },
                "featured_experiences": {
                  "type": "Slice",
                  "fieldset": "Featured Experiences",
                  "description": "Large slides linking to events, exhibitions, etc.",
                  "icon": "chrome_reader_mode",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    }
                  }
                },
                "hero_image": {
                  "type": "Slice",
                  "fieldset": "Hero Image",
                  "description": "Large image and text to introduce content.",
                  "icon": "photo_size_select_actual",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Teal", "Dark Teal"],
                        "default_value": "Teal",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "image_callout": {
                  "type": "Slice",
                  "fieldset": "Image Callout",
                  "description": "Image with supporting text.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "image_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Image Side"
                      }
                    },
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light Gray", "White"],
                        "default_value": "Light Gray",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "info_cards": {
                  "type": "Slice",
                  "fieldset": "Info Cards",
                  "description": "Boxed information with optional CTAs.",
                  "icon": "library_books",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Teal"],
                        "default_value": "White",
                        "label": "Color Scheme"
                      }
                    },
                    "info_card": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["info_card"],
                        "label": "Info Card"
                      }
                    }
                  }
                },
                "large_text": {
                  "type": "Slice",
                  "fieldset": "Large Text",
                  "description": "Large stylized text block.",
                  "icon": "format_color_text",
                  "display": "list",
                  "non-repeat": {
                    "alignment": {
                      "type": "Select",
                      "config": {
                        "options": ["Center", "Left"],
                        "default_value": "Center",
                        "label": "Alignment"
                      }
                    },
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light Gray", "White"],
                        "default_value": "Light Gray",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,hyperlink",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "section_header": {
                  "type": "Slice",
                  "fieldset": "Section Header",
                  "description": "Text introducing the next section of content.",
                  "icon": "format_align_left",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Teal", "Dark Teal"],
                        "default_value": "Teal",
                        "label": "Color Scheme"
                      }
                    },
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "filterable_experiences": {
                  "type": "Slice",
                  "fieldset": "Filterable Experiences",
                  "description": "Group of experiences with filter bar ",
                  "icon": "subtitles",
                  "display": "list",
                  "non-repeat": {
                    "experience_type": {
                      "type": "Select",
                      "config": {
                        "options": ["Exhibition", "Event", "Residency"],
                        "default_value": "Exhibition",
                        "label": "Experience Type"
                      }
                    }
                  },
                  "repeat": {}
                },
                "featured_objects": {
                  "type": "Slice",
                  "fieldset": "Featured Objects",
                  "description": "Large slides displaying collection objects.",
                  "icon": "chrome_reader_mode",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "collection_object": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["collection_object"],
                        "label": "Collection Object"
                      }
                    }
                  }
                },
                "dotted_path_cta": {
                  "type": "Slice",
                  "fieldset": "Dotted Path CTA",
                  "description": "Call to action with dotted path animation.",
                  "icon": "border_clear",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1",
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    },
                    "background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Background Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "grouped_experiences": {
                  "type": "Slice",
                  "fieldset": "Grouped Experiences",
                  "description": "Grouped experiences with descriptions.",
                  "icon": "dashboard",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    },
                    "column_1_heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading2",
                        "label": "Column 1 Heading"
                      }
                    },
                    "column_1_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 1 Button Text"
                      }
                    },
                    "column_1_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 1 Button Link",
                        "select": null
                      }
                    },
                    "column_1_empty_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading2",
                        "label": "Column 1 Empty Text"
                      }
                    },
                    "column_1_empty_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 1 Empty Button Text"
                      }
                    },
                    "column_1_empty_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 1 Empty Button Link",
                        "select": null
                      }
                    },
                    "column_2_heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading2",
                        "label": "Column 2 Heading"
                      }
                    },
                    "column_2_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 2 Button Text"
                      }
                    },
                    "column_2_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 2 Button Link",
                        "select": null
                      }
                    },
                    "column_2_empty_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading2",
                        "label": "Column 2 Empty Text"
                      }
                    },
                    "column_2_empty_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 2 Empty Button Text"
                      }
                    },
                    "column_2_empty_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 2 Empty Button Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    },
                    "column": {
                      "type": "Select",
                      "config": {
                        "options": ["Column 1", "Column 2"],
                        "default_value": "Column 1",
                        "label": "Column"
                      }
                    },
                    "display_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Large"],
                        "default_value": "Normal",
                        "label": "Display Size"
                      }
                    }
                  }
                },
                "featured_experience": {
                  "type": "Slice",
                  "fieldset": "Featured Experience",
                  "description": "Large block linking to events, exhibitions, etc.",
                  "icon": "chrome_reader_mode",
                  "display": "list",
                  "non-repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    }
                  },
                  "repeat": {}
                },
                "images": {
                  "type": "Slice",
                  "fieldset": "Images",
                  "description": "Images with optional captions.",
                  "icon": "image",
                  "display": "grid",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    }
                  },
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,strong,em,hyperlink",
                        "allowTargetBlank": true,
                        "label": "Caption"
                      }
                    }
                  }
                },
                "experience": {
                  "type": "Slice",
                  "fieldset": "Experience",
                  "description": "Large block with all details of an experience.",
                  "icon": "subtitles",
                  "display": "grid",
                  "non-repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience",
                        "placeholder": "Select a link (default: this page)"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "about": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,strong,em,hyperlink,image,embed,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "About"
                      }
                    }
                  },
                  "repeat": {
                    "related_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Related Link",
                        "select": null
                      }
                    },
                    "name": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Name"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    }
                  }
                },
                "call_to_action": {
                  "type": "Slice",
                  "fieldset": "Call To Action",
                  "description": "Large block with image and button.",
                  "icon": "vignette",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Dark Teal", "Light Gray"],
                        "default_value": "Dark Teal",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {}
                },
                "text": {
                  "type": "Slice",
                  "fieldset": "Text",
                  "description": "Flexible text in one or two column layout",
                  "icon": "format_align_center",
                  "display": "list",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    },
                    "left_column_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,embed,list-item,o-list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Left Column Text"
                      }
                    },
                    "right_column_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,embed,list-item,o-list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Right Column Text",
                        "placeholder": "Leave this blank for single column layout."
                      }
                    }
                  },
                  "repeat": {}
                },
                "note": {
                  "type": "Slice",
                  "fieldset": "Note",
                  "description": "Highlighted text for important notes.",
                  "icon": "chat",
                  "display": "list",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,strong,em,hyperlink,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "page_header": {
                  "type": "Slice",
                  "fieldset": "Page Header",
                  "description": "Block for introducing pages with text and an image.",
                  "icon": "web",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "pull_quote": {
                  "type": "Slice",
                  "fieldset": "Pull Quote",
                  "description": "Stylish quote block",
                  "icon": "format_quote",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Text"
                      }
                    },
                    "quotee_name": {
                      "type": "Text",
                      "config": {
                        "label": "Quotee Name"
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_experience_cards": {
                  "type": "Slice",
                  "fieldset": "Small Experience Cards",
                  "description": "Small cards linking to events, exhibitions, etc.",
                  "icon": "view_stream",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    }
                  },
                  "repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    }
                  }
                },
                "career_openings": {
                  "type": "Slice",
                  "fieldset": "Career Openings",
                  "description": "Block for displaying current career openings.",
                  "icon": "person_add",
                  "display": "list",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    }
                  },
                  "repeat": {
                    "career_opening": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Career Opening"],
                        "label": "Career Opening"
                      }
                    }
                  }
                },
                "latest_news_cards": {
                  "type": "Slice",
                  "fieldset": "Latest News Cards",
                  "description": "Large block that displays the latest news.",
                  "icon": "insert_drive_file",
                  "display": "list",
                  "non-repeat": {},
                  "repeat": {}
                },
                "documents_list": {
                  "type": "Slice",
                  "fieldset": "Documents List",
                  "description": "List of linked documents.",
                  "icon": "storage",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "icon": {
                      "type": "Select",
                      "config": {
                        "options": ["Document", "Jali flake", "Job magnifying glass", "Media eye"],
                        "default_value": "Document",
                        "label": "Icon",
                        "placeholder": "Icon displayed with link name and description"
                      }
                    },
                    "document_link": {
                      "type": "Link",
                      "config": {
                        "label": "Document Link",
                        "select": null
                      }
                    },
                    "name": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Name"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    }
                  }
                },
                "past_experiences_list": {
                  "type": "Slice",
                  "fieldset": "Past Experiences List",
                  "description": "List of past experiences with filter and pagination",
                  "icon": "camera_roll",
                  "display": "list",
                  "non-repeat": {
                    "experience_type": {
                      "type": "Select",
                      "config": {
                        "options": ["Exhibition", "Event", "Residency"],
                        "default_value": "Exhibition",
                        "label": "Experience Type"
                      }
                    }
                  },
                  "repeat": {}
                },
                "form": {
                  "type": "Slice",
                  "fieldset": "Form",
                  "description": "Large block that displays a customizeable form.",
                  "icon": "description",
                  "display": "list",
                  "non-repeat": {
                    "form": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["form"],
                        "label": "Form"
                      }
                    }
                  },
                  "repeat": {}
                },
                "large_dotted_path_cta": {
                  "type": "Slice",
                  "fieldset": "Large Dotted Path CTA",
                  "description": "CTA with large dotted path layout",
                  "icon": "blur_linear",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {}
                },
                "staff_members": {
                  "type": "Slice",
                  "fieldset": "Staff Members",
                  "description": "Large block that displays staff members.",
                  "icon": "account_box",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {}
                },
                "logos": {
                  "type": "Slice",
                  "fieldset": "Logos",
                  "description": "List of logos with optional links.",
                  "icon": "wb_auto",
                  "display": "grid",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    }
                  },
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link",
                        "placeholder": "Optional",
                        "select": null
                      }
                    }
                  }
                },
                "text_with_image": {
                  "type": "Slice",
                  "fieldset": "Text with Image",
                  "description": "Text with image to the left or right.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    },
                    "text_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Text Side"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "large_text_on_image": {
                  "type": "Slice",
                  "fieldset": "Large Text on Image",
                  "description": "Large block containing text over an image.",
                  "icon": "featured_play_list",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,strong,em",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "anchor": {
                  "type": "Slice",
                  "fieldset": "Anchor",
                  "description": "Place an anchor link at this section of the page.",
                  "icon": "insert_link",
                  "display": "list",
                  "non-repeat": {
                    "anchor_link": {
                      "type": "Text",
                      "config": {
                        "label": "Anchor Link",
                        "placeholder": "Text to use for the anchor link."
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "settings": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "site_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Site Name",
              "placeholder": "Name of the site"
            }
          },
          "site_description": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Description",
              "placeholder": "Description of the site provided to search engines"
            }
          },
          "tickets_message": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, heading1, strong, em, hyperlink",
              "allowTargetBlank": true,
              "label": "Tickets Message",
              "placeholder": "Text displayed in desktop navigation"
            }
          },
          "tickets_link": {
            "type": "Link",
            "config": {
              "allowTargetBlank": true,
              "label": "Tickets Link"
            }
          },
          "facebook_handle": {
            "type": "Text",
            "config": {
              "label": "Facebook Handle"
            }
          },
          "twitter_handle": {
            "type": "Text",
            "config": {
              "label": "Twitter Handle"
            }
          },
          "instagram_handle": {
            "type": "Text",
            "config": {
              "label": "Instagram Handle"
            }
          },
          "address": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph, hyperlink",
              "allowTargetBlank": true,
              "label": "Address"
            }
          },
          "copyright": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Copyright"
            }
          },
          "career_openings_footnote": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Career Openings Footnote"
            }
          },
          "preview_map": {
            "type": "Group",
            "config": {
              "fields": {
                "api_id": {
                  "type": "Text",
                  "config": {
                    "label": "API ID"
                  }
                },
                "page": {
                  "type": "Link",
                  "config": {
                    "label": "Page"
                  }
                }
              },
              "label": "Preview Map"
            }
          }
        },
        "Speedbump": {
          "allow_list": {
            "type": "Group",
            "config": {
              "fields": {
                "link_url": {
                  "type": "Text",
                  "config": {
                    "label": "Link URL"
                  }
                }
              },
              "label": "Allow List"
            }
          },
          "block_list": {
            "type": "Group",
            "config": {
              "fields": {
                "link_url": {
                  "type": "Text",
                  "config": {
                    "label": "Link URL"
                  }
                }
              },
              "label": "Block List"
            }
          }
        },
        "Redirects": {
          "redirects": {
            "type": "Group",
            "config": {
              "fields": {
                "from_url": {
                  "type": "Text",
                  "config": {
                    "label": "From URL"
                  }
                },
                "to_url": {
                  "type": "Text",
                  "config": {
                    "label": "To URL"
                  }
                },
                "type": {
                  "type": "Select",
                  "config": {
                    "options": ["Temporary", "Permanent"],
                    "default_value": "Temporary",
                    "label": "Type"
                  }
                }
              },
              "label": "Redirects"
            }
          }
        }
      },
      "form": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "post_submission_text": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, heading1, heading2, strong, em, hyperlink, list-item, o-list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Post Submission Text"
            }
          },
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "text_field": {
                  "type": "Slice",
                  "fieldset": "Text Field",
                  "description": "Add a text field to your form.",
                  "icon": "text_fields",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "type": {
                      "type": "Select",
                      "config": {
                        "options": ["Plain Text", "Email", "Phone Number"],
                        "default_value": "Plain Text",
                        "label": "Type"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "checkbox_field": {
                  "type": "Slice",
                  "fieldset": "Checkbox Field",
                  "description": "Add one or more checkboxes to your form.",
                  "icon": "check_box",
                  "display": "list",
                  "non-repeat": {
                    "minimum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Minimum Checks"
                      }
                    },
                    "maximum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Maximum Checks"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "select_field": {
                  "type": "Slice",
                  "fieldset": "Select Field",
                  "description": "Add a dropdown select field to your form.",
                  "icon": "arrow_drop_down_circle",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "radio_field": {
                  "type": "Slice",
                  "fieldset": "Radio Field",
                  "description": "Add one or more radio fields to your form.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "textarea": {
                  "type": "Slice",
                  "fieldset": "Textarea",
                  "description": "Add a large textarea field to your form.",
                  "icon": "wrap_text",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "help_text": {
                  "type": "Slice",
                  "fieldset": "Help Text",
                  "description": "Add helper text to help users.",
                  "icon": "help",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "file_field": {
                  "type": "Slice",
                  "fieldset": "File Field",
                  "description": "Add a file upload field to your form.",
                  "icon": "attach_file",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "maximum_file_size": {
                      "type": "Number",
                      "config": {
                        "label": "Maximum File Size",
                        "placeholder": "Default: 250 (KB)"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "staff_member": {
        "Main": {
          "first_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading2",
              "label": "First Name"
            }
          },
          "last_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading2",
              "label": "Last Name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "image": {
            "type": "Image",
            "config": {
              "constraint": {},
              "thumbnails": [],
              "label": "Image"
            }
          },
          "position_title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Position Title"
            }
          }
        }
      }
    }
  }
}, {
  plugin: require('../node_modules/@walltowall/gatsby-theme-ww-prismic/gatsby-browser.js'),
  options: {
    "plugins": [],
    "root": "/opt/build/repo",
    "repositoryName": "shangrilahawaii",
    "accessToken": "MC5YVDlvVEJFQUFDUUFBb192.77-9Bu-_vUtgJzoM77-977-977-9XWEX77-977-977-977-977-9Me-_vRJj77-977-9HEl-77-9HO-_vQA",
    "schemas": {
      "collection_object": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "object_number": {
            "type": "Text",
            "config": {
              "label": "Object Number"
            }
          },
          "description": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Description"
            }
          },
          "emuseum_link": {
            "type": "Link",
            "config": {
              "allowTargetBlank": true,
              "label": "eMuseum Link"
            }
          },
          "metadata": {
            "type": "Group",
            "config": {
              "fields": {
                "name": {
                  "type": "Text",
                  "config": {
                    "label": "Name"
                  }
                },
                "content": {
                  "type": "StructuredText",
                  "config": {
                    "multi": "paragraph, strong, em, list-item, o-list-item",
                    "allowTargetBlank": true,
                    "label": "Content"
                  }
                }
              },
              "label": "Metadata"
            }
          },
          "image": {
            "type": "Image",
            "config": {
              "constraint": {},
              "thumbnails": [],
              "label": "Image"
            }
          }
        }
      },
      "info_card": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "text": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, heading1, heading2, strong, em, hyperlink, list-item, o-list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Text"
            }
          },
          "highlights": {
            "type": "Group",
            "config": {
              "fields": {
                "description": {
                  "type": "StructuredText",
                  "config": {
                    "multi": "paragraph, heading1, strong, em",
                    "allowTargetBlank": true,
                    "label": "Description"
                  }
                }
              },
              "label": "Highlights"
            }
          },
          "footnote": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Footnote"
            }
          },
          "button_text": {
            "type": "Text",
            "config": {
              "label": "Button Text"
            }
          },
          "button_link": {
            "type": "Link",
            "config": {
              "allowTargetBlank": true,
              "label": "Button Link"
            }
          }
        }
      },
      "navigation": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "primary": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "nav_item": {
                  "type": "Slice",
                  "fieldset": "Nav Item",
                  "description": "Nav Item with Sub Navigation",
                  "icon": "insert_link",
                  "display": "list",
                  "non-repeat": {
                    "name": {
                      "type": "Text",
                      "config": {
                        "label": "Name"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link"
                      }
                    }
                  },
                  "repeat": {
                    "name": {
                      "type": "Text",
                      "config": {
                        "label": "Name"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "page": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "meta_title": {
            "type": "Text",
            "config": {
              "label": "Meta Title"
            }
          },
          "meta_description": {
            "type": "Text",
            "config": {
              "label": "Meta Description"
            }
          },
          "parent": {
            "type": "Link",
            "config": {
              "select": "document",
              "customtypes": ["page"],
              "label": "Parent"
            }
          },
          "main": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "exhibition": {
                  "type": "Slice",
                  "fieldset": "Exhibition",
                  "description": "Exhibition information",
                  "icon": "widgets",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description"
                      }
                    },
                    "start_date": {
                      "type": "Date",
                      "config": {
                        "label": "Start Date"
                      }
                    },
                    "end_date": {
                      "type": "Date",
                      "config": {
                        "label": "End Date"
                      }
                    },
                    "by_resident": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Residency"],
                        "label": "By Resident"
                      }
                    },
                    "featured_image_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Tall"],
                        "default_value": "Normal",
                        "label": "Featured Image Size"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "event": {
                  "type": "Slice",
                  "fieldset": "Event",
                  "description": "Event information",
                  "icon": "event_note",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description"
                      }
                    },
                    "start_date": {
                      "type": "Date",
                      "config": {
                        "label": "Start Date"
                      }
                    },
                    "end_date": {
                      "type": "Date",
                      "config": {
                        "label": "End Date"
                      }
                    },
                    "location": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Location"
                      }
                    },
                    "time": {
                      "type": "Text",
                      "config": {
                        "label": "Time"
                      }
                    },
                    "admission": {
                      "type": "Text",
                      "config": {
                        "label": "Admission"
                      }
                    },
                    "tickets_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Tickets Link",
                        "select": null
                      }
                    },
                    "by_resident": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Residency"],
                        "label": "By Resident"
                      }
                    },
                    "featured_image_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Tall"],
                        "default_value": "Normal",
                        "label": "Featured Image Size"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "residency": {
                  "type": "Slice",
                  "fieldset": "Residency",
                  "description": "Residency information",
                  "icon": "face",
                  "display": "list",
                  "non-repeat": {
                    "resident_type": {
                      "type": "Select",
                      "config": {
                        "options": ["Artist", "Scholar"],
                        "default_value": "Artist",
                        "label": "Resident Type"
                      }
                    },
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description",
                        "placeholder": "100-150 characters, including spaces, recommended"
                      }
                    },
                    "start_date": {
                      "type": "Date",
                      "config": {
                        "label": "Start Date"
                      }
                    },
                    "end_date": {
                      "type": "Date",
                      "config": {
                        "label": "End Date"
                      }
                    },
                    "featured_image_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Tall"],
                        "default_value": "Normal",
                        "label": "Featured Image Size"
                      }
                    },
                    "profile_picture": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Profile Picture"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "news": {
                  "type": "Slice",
                  "fieldset": "News",
                  "description": "News information",
                  "icon": "fiber_new",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "Text",
                      "config": {
                        "label": "Description"
                      }
                    },
                    "date": {
                      "type": "Date",
                      "config": {
                        "label": "Date"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "career_opening": {
                  "type": "Slice",
                  "fieldset": "Career Opening",
                  "description": "Career opening information",
                  "icon": "person_add",
                  "display": "list",
                  "non-repeat": {
                    "available_from": {
                      "type": "Date",
                      "config": {
                        "label": "Available From"
                      }
                    },
                    "location": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Location"
                      }
                    },
                    "reports_to": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Reports To"
                      }
                    },
                    "status": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Status"
                      }
                    },
                    "apply_link": {
                      "type": "Link",
                      "config": {
                        "label": "Apply Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        },
        "Body": {
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "anchor": []
              },
              "choices": {
                "experience_cards": {
                  "type": "Slice",
                  "fieldset": "Experience Cards",
                  "description": "Cards linking to events, exhibitions, etc.",
                  "icon": "view_compact",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    }
                  },
                  "repeat": {
                    "item": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Item"
                      }
                    },
                    "display_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Large"],
                        "default_value": "Normal",
                        "label": "Display Size"
                      }
                    }
                  }
                },
                "featured_experiences": {
                  "type": "Slice",
                  "fieldset": "Featured Experiences",
                  "description": "Large slides linking to events, exhibitions, etc.",
                  "icon": "chrome_reader_mode",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    }
                  }
                },
                "hero_image": {
                  "type": "Slice",
                  "fieldset": "Hero Image",
                  "description": "Large image and text to introduce content.",
                  "icon": "photo_size_select_actual",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Teal", "Dark Teal"],
                        "default_value": "Teal",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "image_callout": {
                  "type": "Slice",
                  "fieldset": "Image Callout",
                  "description": "Image with supporting text.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "image_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Image Side"
                      }
                    },
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light Gray", "White"],
                        "default_value": "Light Gray",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "info_cards": {
                  "type": "Slice",
                  "fieldset": "Info Cards",
                  "description": "Boxed information with optional CTAs.",
                  "icon": "library_books",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Teal"],
                        "default_value": "White",
                        "label": "Color Scheme"
                      }
                    },
                    "info_card": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["info_card"],
                        "label": "Info Card"
                      }
                    }
                  }
                },
                "large_text": {
                  "type": "Slice",
                  "fieldset": "Large Text",
                  "description": "Large stylized text block.",
                  "icon": "format_color_text",
                  "display": "list",
                  "non-repeat": {
                    "alignment": {
                      "type": "Select",
                      "config": {
                        "options": ["Center", "Left"],
                        "default_value": "Center",
                        "label": "Alignment"
                      }
                    },
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light Gray", "White"],
                        "default_value": "Light Gray",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,hyperlink",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "section_header": {
                  "type": "Slice",
                  "fieldset": "Section Header",
                  "description": "Text introducing the next section of content.",
                  "icon": "format_align_left",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Teal", "Dark Teal"],
                        "default_value": "Teal",
                        "label": "Color Scheme"
                      }
                    },
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "filterable_experiences": {
                  "type": "Slice",
                  "fieldset": "Filterable Experiences",
                  "description": "Group of experiences with filter bar ",
                  "icon": "subtitles",
                  "display": "list",
                  "non-repeat": {
                    "experience_type": {
                      "type": "Select",
                      "config": {
                        "options": ["Exhibition", "Event", "Residency"],
                        "default_value": "Exhibition",
                        "label": "Experience Type"
                      }
                    }
                  },
                  "repeat": {}
                },
                "featured_objects": {
                  "type": "Slice",
                  "fieldset": "Featured Objects",
                  "description": "Large slides displaying collection objects.",
                  "icon": "chrome_reader_mode",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "collection_object": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["collection_object"],
                        "label": "Collection Object"
                      }
                    }
                  }
                },
                "dotted_path_cta": {
                  "type": "Slice",
                  "fieldset": "Dotted Path CTA",
                  "description": "Call to action with dotted path animation.",
                  "icon": "border_clear",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1",
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    },
                    "background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Background Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "grouped_experiences": {
                  "type": "Slice",
                  "fieldset": "Grouped Experiences",
                  "description": "Grouped experiences with descriptions.",
                  "icon": "dashboard",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    },
                    "column_1_heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading2",
                        "label": "Column 1 Heading"
                      }
                    },
                    "column_1_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 1 Button Text"
                      }
                    },
                    "column_1_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 1 Button Link",
                        "select": null
                      }
                    },
                    "column_1_empty_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading2",
                        "label": "Column 1 Empty Text"
                      }
                    },
                    "column_1_empty_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 1 Empty Button Text"
                      }
                    },
                    "column_1_empty_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 1 Empty Button Link",
                        "select": null
                      }
                    },
                    "column_2_heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading2",
                        "label": "Column 2 Heading"
                      }
                    },
                    "column_2_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 2 Button Text"
                      }
                    },
                    "column_2_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 2 Button Link",
                        "select": null
                      }
                    },
                    "column_2_empty_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading2",
                        "label": "Column 2 Empty Text"
                      }
                    },
                    "column_2_empty_button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Column 2 Empty Button Text"
                      }
                    },
                    "column_2_empty_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Column 2 Empty Button Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    },
                    "column": {
                      "type": "Select",
                      "config": {
                        "options": ["Column 1", "Column 2"],
                        "default_value": "Column 1",
                        "label": "Column"
                      }
                    },
                    "display_size": {
                      "type": "Select",
                      "config": {
                        "options": ["Normal", "Large"],
                        "default_value": "Normal",
                        "label": "Display Size"
                      }
                    }
                  }
                },
                "featured_experience": {
                  "type": "Slice",
                  "fieldset": "Featured Experience",
                  "description": "Large block linking to events, exhibitions, etc.",
                  "icon": "chrome_reader_mode",
                  "display": "list",
                  "non-repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    }
                  },
                  "repeat": {}
                },
                "images": {
                  "type": "Slice",
                  "fieldset": "Images",
                  "description": "Images with optional captions.",
                  "icon": "image",
                  "display": "grid",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    }
                  },
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,strong,em,hyperlink",
                        "allowTargetBlank": true,
                        "label": "Caption"
                      }
                    }
                  }
                },
                "experience": {
                  "type": "Slice",
                  "fieldset": "Experience",
                  "description": "Large block with all details of an experience.",
                  "icon": "subtitles",
                  "display": "grid",
                  "non-repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience",
                        "placeholder": "Select a link (default: this page)"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "about": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,strong,em,hyperlink,image,embed,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "About"
                      }
                    }
                  },
                  "repeat": {
                    "related_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Related Link",
                        "select": null
                      }
                    },
                    "name": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Name"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    }
                  }
                },
                "call_to_action": {
                  "type": "Slice",
                  "fieldset": "Call To Action",
                  "description": "Large block with image and button.",
                  "icon": "vignette",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Dark Teal", "Light Gray"],
                        "default_value": "Dark Teal",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {}
                },
                "text": {
                  "type": "Slice",
                  "fieldset": "Text",
                  "description": "Flexible text in one or two column layout",
                  "icon": "format_align_center",
                  "display": "list",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    },
                    "left_column_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,embed,list-item,o-list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Left Column Text"
                      }
                    },
                    "right_column_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,embed,list-item,o-list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Right Column Text",
                        "placeholder": "Leave this blank for single column layout."
                      }
                    }
                  },
                  "repeat": {}
                },
                "note": {
                  "type": "Slice",
                  "fieldset": "Note",
                  "description": "Highlighted text for important notes.",
                  "icon": "chat",
                  "display": "list",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,strong,em,hyperlink,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "page_header": {
                  "type": "Slice",
                  "fieldset": "Page Header",
                  "description": "Block for introducing pages with text and an image.",
                  "icon": "web",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "pull_quote": {
                  "type": "Slice",
                  "fieldset": "Pull Quote",
                  "description": "Stylish quote block",
                  "icon": "format_quote",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Text"
                      }
                    },
                    "quotee_name": {
                      "type": "Text",
                      "config": {
                        "label": "Quotee Name"
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_experience_cards": {
                  "type": "Slice",
                  "fieldset": "Small Experience Cards",
                  "description": "Small cards linking to events, exhibitions, etc.",
                  "icon": "view_stream",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    }
                  },
                  "repeat": {
                    "experience": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Experience"
                      }
                    }
                  }
                },
                "career_openings": {
                  "type": "Slice",
                  "fieldset": "Career Openings",
                  "description": "Block for displaying current career openings.",
                  "icon": "person_add",
                  "display": "list",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading"
                      }
                    }
                  },
                  "repeat": {
                    "career_opening": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Career Opening"],
                        "label": "Career Opening"
                      }
                    }
                  }
                },
                "latest_news_cards": {
                  "type": "Slice",
                  "fieldset": "Latest News Cards",
                  "description": "Large block that displays the latest news.",
                  "icon": "insert_drive_file",
                  "display": "list",
                  "non-repeat": {},
                  "repeat": {}
                },
                "documents_list": {
                  "type": "Slice",
                  "fieldset": "Documents List",
                  "description": "List of linked documents.",
                  "icon": "storage",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "icon": {
                      "type": "Select",
                      "config": {
                        "options": ["Document", "Jali flake", "Job magnifying glass", "Media eye"],
                        "default_value": "Document",
                        "label": "Icon",
                        "placeholder": "Icon displayed with link name and description"
                      }
                    },
                    "document_link": {
                      "type": "Link",
                      "config": {
                        "label": "Document Link",
                        "select": null
                      }
                    },
                    "name": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Name"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    }
                  }
                },
                "past_experiences_list": {
                  "type": "Slice",
                  "fieldset": "Past Experiences List",
                  "description": "List of past experiences with filter and pagination",
                  "icon": "camera_roll",
                  "display": "list",
                  "non-repeat": {
                    "experience_type": {
                      "type": "Select",
                      "config": {
                        "options": ["Exhibition", "Event", "Residency"],
                        "default_value": "Exhibition",
                        "label": "Experience Type"
                      }
                    }
                  },
                  "repeat": {}
                },
                "form": {
                  "type": "Slice",
                  "fieldset": "Form",
                  "description": "Large block that displays a customizeable form.",
                  "icon": "description",
                  "display": "list",
                  "non-repeat": {
                    "form": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["form"],
                        "label": "Form"
                      }
                    }
                  },
                  "repeat": {}
                },
                "large_dotted_path_cta": {
                  "type": "Slice",
                  "fieldset": "Large Dotted Path CTA",
                  "description": "CTA with large dotted path layout",
                  "icon": "blur_linear",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    }
                  },
                  "repeat": {}
                },
                "staff_members": {
                  "type": "Slice",
                  "fieldset": "Staff Members",
                  "description": "Large block that displays staff members.",
                  "icon": "account_box",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {}
                },
                "logos": {
                  "type": "Slice",
                  "fieldset": "Logos",
                  "description": "List of logos with optional links.",
                  "icon": "wb_auto",
                  "display": "grid",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    }
                  },
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link",
                        "placeholder": "Optional",
                        "select": null
                      }
                    }
                  }
                },
                "text_with_image": {
                  "type": "Slice",
                  "fieldset": "Text with Image",
                  "description": "Text with image to the left or right.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "background_color": {
                      "type": "Select",
                      "config": {
                        "options": ["White", "Light Gray"],
                        "default_value": "White",
                        "label": "Background Color"
                      }
                    },
                    "text_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Text Side"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,heading2,strong,em,hyperlink,list-item,o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "large_text_on_image": {
                  "type": "Slice",
                  "fieldset": "Large Text on Image",
                  "description": "Large block containing text over an image.",
                  "icon": "featured_play_list",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph,heading1,strong,em",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    },
                    "button_text": {
                      "type": "Text",
                      "config": {
                        "label": "Button Text"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "select": null
                      }
                    },
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {},
                        "thumbnails": [],
                        "label": "Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "anchor": {
                  "type": "Slice",
                  "fieldset": "Anchor",
                  "description": "Place an anchor link at this section of the page.",
                  "icon": "insert_link",
                  "display": "list",
                  "non-repeat": {
                    "anchor_link": {
                      "type": "Text",
                      "config": {
                        "label": "Anchor Link",
                        "placeholder": "Text to use for the anchor link."
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "settings": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "site_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Site Name",
              "placeholder": "Name of the site"
            }
          },
          "site_description": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Description",
              "placeholder": "Description of the site provided to search engines"
            }
          },
          "tickets_message": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, heading1, strong, em, hyperlink",
              "allowTargetBlank": true,
              "label": "Tickets Message",
              "placeholder": "Text displayed in desktop navigation"
            }
          },
          "tickets_link": {
            "type": "Link",
            "config": {
              "allowTargetBlank": true,
              "label": "Tickets Link"
            }
          },
          "facebook_handle": {
            "type": "Text",
            "config": {
              "label": "Facebook Handle"
            }
          },
          "twitter_handle": {
            "type": "Text",
            "config": {
              "label": "Twitter Handle"
            }
          },
          "instagram_handle": {
            "type": "Text",
            "config": {
              "label": "Instagram Handle"
            }
          },
          "address": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph, hyperlink",
              "allowTargetBlank": true,
              "label": "Address"
            }
          },
          "copyright": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Copyright"
            }
          },
          "career_openings_footnote": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Career Openings Footnote"
            }
          },
          "preview_map": {
            "type": "Group",
            "config": {
              "fields": {
                "api_id": {
                  "type": "Text",
                  "config": {
                    "label": "API ID"
                  }
                },
                "page": {
                  "type": "Link",
                  "config": {
                    "label": "Page"
                  }
                }
              },
              "label": "Preview Map"
            }
          }
        },
        "Speedbump": {
          "allow_list": {
            "type": "Group",
            "config": {
              "fields": {
                "link_url": {
                  "type": "Text",
                  "config": {
                    "label": "Link URL"
                  }
                }
              },
              "label": "Allow List"
            }
          },
          "block_list": {
            "type": "Group",
            "config": {
              "fields": {
                "link_url": {
                  "type": "Text",
                  "config": {
                    "label": "Link URL"
                  }
                }
              },
              "label": "Block List"
            }
          }
        },
        "Redirects": {
          "redirects": {
            "type": "Group",
            "config": {
              "fields": {
                "from_url": {
                  "type": "Text",
                  "config": {
                    "label": "From URL"
                  }
                },
                "to_url": {
                  "type": "Text",
                  "config": {
                    "label": "To URL"
                  }
                },
                "type": {
                  "type": "Select",
                  "config": {
                    "options": ["Temporary", "Permanent"],
                    "default_value": "Temporary",
                    "label": "Type"
                  }
                }
              },
              "label": "Redirects"
            }
          }
        }
      },
      "form": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "post_submission_text": {
            "type": "StructuredText",
            "config": {
              "multi": "paragraph, heading1, heading2, strong, em, hyperlink, list-item, o-list-item, o-list-item",
              "allowTargetBlank": true,
              "label": "Post Submission Text"
            }
          },
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "text_field": {
                  "type": "Slice",
                  "fieldset": "Text Field",
                  "description": "Add a text field to your form.",
                  "icon": "text_fields",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "type": {
                      "type": "Select",
                      "config": {
                        "options": ["Plain Text", "Email", "Phone Number"],
                        "default_value": "Plain Text",
                        "label": "Type"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "checkbox_field": {
                  "type": "Slice",
                  "fieldset": "Checkbox Field",
                  "description": "Add one or more checkboxes to your form.",
                  "icon": "check_box",
                  "display": "list",
                  "non-repeat": {
                    "minimum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Minimum Checks"
                      }
                    },
                    "maximum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Maximum Checks"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "select_field": {
                  "type": "Slice",
                  "fieldset": "Select Field",
                  "description": "Add a dropdown select field to your form.",
                  "icon": "arrow_drop_down_circle",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "radio_field": {
                  "type": "Slice",
                  "fieldset": "Radio Field",
                  "description": "Add one or more radio fields to your form.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "textarea": {
                  "type": "Slice",
                  "fieldset": "Textarea",
                  "description": "Add a large textarea field to your form.",
                  "icon": "wrap_text",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "help_text": {
                  "type": "Slice",
                  "fieldset": "Help Text",
                  "description": "Add helper text to help users.",
                  "icon": "help",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "file_field": {
                  "type": "Slice",
                  "fieldset": "File Field",
                  "description": "Add a file upload field to your form.",
                  "icon": "attach_file",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "maximum_file_size": {
                      "type": "Number",
                      "config": {
                        "label": "Maximum File Size",
                        "placeholder": "Default: 250 (KB)"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "staff_member": {
        "Main": {
          "first_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading2",
              "label": "First Name"
            }
          },
          "last_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading2",
              "label": "Last Name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "image": {
            "type": "Image",
            "config": {
              "constraint": {},
              "thumbnails": [],
              "label": "Image"
            }
          },
          "position_title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Position Title"
            }
          }
        }
      }
    },
    "defaultTemplate": "page",
    "templates": {
      "news": {
        "metadataType": "PrismicPageMainNews",
        "tag": "News"
      },
      "career": {
        "metadataType": "PrismicPageMainCareerOpening",
        "tag": "Career Opening"
      }
    }
  }
}, {
  plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [{
      "resolve": "/opt/build/repo/node_modules/gatsby-remark-images",
      "id": "3626c0e0-58d4-5046-b0e5-cb7731a052f2",
      "name": "gatsby-remark-images",
      "version": "3.1.49",
      "pluginOptions": {
        "plugins": []
      },
      "nodeAPIs": [],
      "browserAPIs": ["onRouteUpdate"],
      "ssrAPIs": []
    }],
    "gatsbyRemarkPlugins": [{
      "resolve": "gatsby-remark-images",
      "options": {
        "maxWidth": 800,
        "quality": 75,
        "showCaptions": true,
        "linkImagesToOriginal": false
      }
    }, {
      "resolve": "gatsby-remark-autolink-headers",
      "options": {
        "icon": false
      }
    }, {
      "resolve": "gatsby-remark-find-replace",
      "options": {
        "replacements": {
          "SITE_TITLE": "Shangri La Museum of Islamic Art, Culture, & Design",
          "SITE_URL": "https://www.shangrilahawaii.org"
        }
      }
    }]
  }
}, {
  plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../node_modules/@walltowall/gatsby-theme-ww-reference/gatsby-browser.js'),
  options: {
    "plugins": [],
    "root": "/opt/build/repo",
    "siteTitle": "Shangri La Museum of Islamic Art, Culture, & Design",
    "siteUrl": "https://www.shangrilahawaii.org",
    "theme": {
      "breakpoints": ["48rem", "68rem", "100rem", "130rem"],
      "colors": {
        "gray": {
          "20": "#3d3d3d",
          "40": "#E8EAE3",
          "60": "#979797",
          "90": "#e7eae2",
          "95": "#f9f9f7"
        },
        "teal": {
          "40": "#617672",
          "70": "#00bfcd",
          "75": "#2ecbc8"
        },
        "red": {
          "40": "#dc0024"
        },
        "orange": {
          "60": "#ff6b00"
        },
        "yellow": {
          "80": "#ffda28"
        },
        "white": "#fff",
        "black": "#000"
      },
      "fonts": {
        "sans": "futura-pt, sans-serif",
        "serif": "\"Cormorant Garamond\", serif"
      },
      "fontSizes": ["0.8rem", "0.9rem", "1rem", "1.1rem", "1.2000000000000002rem", "1.3rem", "1.4rem", "1.5rem", "1.6rem", "1.7000000000000002rem", "1.8rem", "1.9000000000000001rem", "2rem", "2.1rem", "2.2rem", "2.3rem", "2.4000000000000004rem", "2.5rem", "2.6rem", "2.7rem", "2.8rem", "2.9000000000000004rem", "3rem", "3.0999999999999996rem", "3.2rem", "3.3rem", "3.4000000000000004rem", "3.5rem", "3.5999999999999996rem", "3.7rem", "3.8rem", "3.9000000000000004rem", "4rem", "4.1rem", "4.2rem", "4.3rem", "4.4rem", "4.5rem", "4.6rem", "4.7rem", "4.8rem", "4.8999999999999995rem", "5rem"],
      "fontSizeScales": {
        "t": ["0.7rem", "0.74rem", "0.7799999999999999rem", "0.8099999999999999rem", "0.85rem"],
        "s": ["0.875rem", "0.905rem", "0.935rem", "0.965rem", "1rem"],
        "s+": ["0.925rem", "0.9750000000000001rem", "1.0250000000000001rem", "1.075rem", "1.125rem"],
        "b": ["1rem", "1.11rem", "1.22rem", "1.34rem", "1.45rem"],
        "m": ["1.125rem", "1.285rem", "1.435rem", "1.595rem", "1.75rem"],
        "l": ["1.35rem", "1.6700000000000002rem", "1.9900000000000002rem", "2.31rem", "2.62rem"],
        "xl": ["1.65rem", "2.11rem", "2.58rem", "3.04rem", "3.5rem"],
        "h": ["2.625rem", "3.535rem", "4.4350000000000005rem", "5.345000000000001rem", "6.25rem"]
      },
      "spaceScales": {
        "0": [0, 0, 0, 0, 0],
        "t-": ["4px", "6px", "8px", "10px", "12px"],
        "t": ["8px", "10px", "12px", "14px", "16px"],
        "t+": ["8px", "11px", "14px", "17px", "20px"],
        "s-": ["14px", "16px", "18px", "20px", "22px"],
        "s": ["16px", "18.75px", "21.5px", "24.25px", "27px"],
        "s+": ["20px", "23.25px", "26.5px", "29.75px", "33px"],
        "m-": ["20px", "26px", "32px", "38px", "44px"],
        "m": ["25px", "32.5px", "40px", "47.5px", "55px"],
        "l": ["30px", "40px", "50px", "60px", "70px"],
        "xl": ["40px", "55px", "70px", "85px", "100px"],
        "xxl": ["50px", "67.5px", "85px", "102.5px", "120px"],
        "2xxl": ["100px", "135px", "170px", "205px", "240px"]
      },
      "lineHeights": {
        "solid": "1",
        "title": "1.15",
        "copy": "1.4",
        "wide": "1.5"
      },
      "sizes": {
        "small": "32rem",
        "medium": "48rem",
        "medium+": "54rem",
        "large": "68rem",
        "large+": "80rem",
        "xlarge": "100rem",
        "xxlarge": "120rem"
      },
      "fontWeights": {
        "light": 300,
        "normal": 400,
        "medium": 500,
        "semibold": 600,
        "bold": 700,
        "heavy": 800
      },
      "transitionDurations": {
        "slow": "300ms",
        "normal": "200ms",
        "fast": "100ms"
      },
      "boxStyles": {
        "lastNoMargin": {
          "&:last-child": {
            "marginBottom": 0,
            "marginRight": 0
          }
        },
        "firstLastNoMargin": {
          "&:first-child": {
            "marginTop": 0,
            "marginLeft": 0
          },
          "&:last-child": {
            "marginBottom": 0,
            "marginRight": 0
          }
        }
      },
      "textStyles": {
        "caps": {
          "textTransform": "uppercase"
        },
        "trackedCaps": {
          "textTransform": "uppercase",
          "letterSpacing": "0.1em"
        }
      },
      "zIndices": {
        "speedBumpModal": 7,
        "speedBumpOverlay": 6,
        "header": 5,
        "slidingMenu": 4,
        "dottedPathCta": 3,
        "footerFlakeTeal": 2,
        "dottedPathImage": 1,
        "dottedPathImageGradient": 1,
        "footerContent": 1,
        "heroSliceImage": 1,
        "infoCards": 1,
        "footerFlakeTransparent": 0,
        "footerTealBox": -1
      }
    }
  }
}, {
  plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-12125430-1"
  }
}, {
  plugin: require('../gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];