import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText } from 'helpers';
import { HEADER_HEIGHT_SCALE } from 'src/constants';
import { propPairsEq } from 'src/helpers';
import { Box, Flex, Image } from 'system';
import { Anchor } from 'src/components/Anchor';
import { BoundedBox } from 'src/components/BoundedBox';
import { Button } from 'src/components/Button';
import { HTMLContent } from 'src/components/HTMLContent';
export const PageBodyLargeTextOnImage = _ref => {
  let {
    previousIsHeader,
    buttonText,
    buttonHref,
    buttonTarget,
    textHTML,
    imageFluid,
    imageURL,
    imageAlt
  } = _ref,
      props = _objectWithoutProperties(_ref, ["previousIsHeader", "buttonText", "buttonHref", "buttonTarget", "textHTML", "imageFluid", "imageURL", "imageAlt"]);

  return React.createElement(Box, Object.assign({
    as: "section",
    bg: "gray.20",
    color: "white",
    position: "relative",
    pt: previousIsHeader ? HEADER_HEIGHT_SCALE : 0,
    maxHeight: "85vh",
    overflow: "hidden"
  }, props), (imageFluid || imageURL) && React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt,
    height: "auto",
    maxHeight: "100%"
  }), React.createElement(BoundedBox, {
    previousIsHeader: previousIsHeader,
    maxWidth: "xlarge",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }, React.createElement(Flex, {
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column"
  }, React.createElement(HTMLContent, {
    html: textHTML,
    mbScale: "m-",
    width: [1 / 2, 1 / 3]
  }), buttonHref && React.createElement(Button, {
    as: Anchor,
    href: buttonHref,
    target: buttonTarget,
    colorScheme: "white"
  }, buttonText))));
};

PageBodyLargeTextOnImage.mapDataToProps = ({
  previousContext,
  nextContext,
  context,
  previousType,
  data
}) => {
  var _data$primary, _data$primary2, _data$primary2$image, _data$primary3, _data$primary3$image, _data$primary4, _data$primary4$image, _data$primary5, _data$primary6, _data$primary6$button, _data$primary7, _data$primary7$button;

  return {
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    previousIsHeader: previousType === 'PageBodyHeader',
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.text),
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$image = _data$primary2.image) === null || _data$primary2$image === void 0 ? void 0 : _data$primary2$image.fluid,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$image = _data$primary3.image) === null || _data$primary3$image === void 0 ? void 0 : _data$primary3$image.url,
    imageAlt: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$image = _data$primary4.image) === null || _data$primary4$image === void 0 ? void 0 : _data$primary4$image.alt,
    buttonText: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : _data$primary5.button_text,
    buttonHref: data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : (_data$primary6$button = _data$primary6.button_link) === null || _data$primary6$button === void 0 ? void 0 : _data$primary6$button.url,
    buttonTarget: data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : (_data$primary7$button = _data$primary7.button_link) === null || _data$primary7$button === void 0 ? void 0 : _data$primary7$button.target
  };
};

PageBodyLargeTextOnImage.mapDataToContext = () => ({
  backgroundColor: 'gray.20',
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});

export const fragment = "3034836449";