import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { negateScale } from 'styled-system-scale';
import { isEmpty, map } from 'tiny-compose-fns';
import { propPairsEq, nodesPathFor } from 'src/helpers';
import { theme } from 'src/theme';
import { Box, Flex, Image, ImageContainer, AspectRatio } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
export const PageBodyPageHeader = _ref => {
  let {
    heading,
    imageURL,
    imageAlt,
    imageFluid,
    nodesPath = []
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "imageURL", "imageAlt", "imageFluid", "nodesPath"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "teal.40"
  }, props), React.createElement(Flex, {
    flexDirection: ['column', 'row-reverse'],
    alignItems: [null, 'center']
  }, (imageURL || imageFluid) && React.createElement(ImageContainer, {
    alignSelf: "stretch",
    bg: "gray.20",
    mbScale: ['l', '-xxl'],
    mlScale: ['-l', 'xxl'],
    mr: [negateScale(theme.spaceScales.l)[0], 'calc(-1 * (100vw - 100%) / 2)'],
    mtScale: "-xxl",
    width: [null, 'calc((100vw - 100%) / 2 + 4 / 6 * 100%)']
  }, React.createElement(AspectRatio, {
    x: 16,
    y: 8,
    minHeight: "100%"
  }, React.createElement(Image, {
    src: imageURL,
    alt: imageAlt,
    fluid: imageFluid
  }))), React.createElement(Box, {
    width: [null, 2 / 6],
    mr: "auto"
  }, !isEmpty(nodesPath) && React.createElement(Breadcrumbs, {
    mbScale: "s+"
  }, map(node => {
    var _node$data, _node$data$title;

    return React.createElement(Breadcrumbs.Item, {
      href: node === null || node === void 0 ? void 0 : node.url
    }, node === null || node === void 0 ? void 0 : (_node$data = node.data) === null || _node$data === void 0 ? void 0 : (_node$data$title = _node$data.title) === null || _node$data$title === void 0 ? void 0 : _node$data$title.text);
  }, nodesPath)), heading && React.createElement(Heading, {
    as: "h2",
    color: "white",
    lineHeight: "copy",
    fontSizeScale: "xl"
  }, heading))));
};

PageBodyPageHeader.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext,
  previousType,
  meta
}) => {
  var _data$primary, _data$primary$text, _data$primary2, _data$primary2$image, _data$primary3, _data$primary3$image, _data$primary4, _data$primary4$image;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    heading: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$text = _data$primary.text) === null || _data$primary$text === void 0 ? void 0 : _data$primary$text.text,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$image = _data$primary2.image) === null || _data$primary2$image === void 0 ? void 0 : _data$primary2$image.url,
    imageAlt: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$image = _data$primary3.image) === null || _data$primary3$image === void 0 ? void 0 : _data$primary3$image.alt,
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$image = _data$primary4.image) === null || _data$primary4$image === void 0 ? void 0 : _data$primary4$image.fluid,
    nodesPath: nodesPathFor(meta === null || meta === void 0 ? void 0 : meta.page)
  };
};

PageBodyPageHeader.mapDataToContext = () => ({
  headerLogoColor: 'white',
  headerBg: 'teal.75',
  backgroundColor: 'teal.40'
});

export const fragment = "1030031939";