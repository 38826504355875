import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { camelCase } from 'tiny-compose-fns';
import { notEmpty } from 'helpers';
import { Image, ImageContainer, Grid, Box, SVG, AspectRatio } from 'system';
import { propPairsEq } from 'src/helpers';
import { Anchor } from 'src/components/Anchor';
import { BoundedBox } from 'src/components/BoundedBox';
import { Button } from 'src/components/Button';
import { Heading } from 'src/components/Heading';
import { ReactComponent as DottedLineBGSVG } from 'src/assets/cta-dots-bg.svg';
const colorSchemes = {
  darkTeal: {
    buttonColorScheme: 'white',
    textColor: 'white',
    withDottedBG: true,
    bg: 'teal.40'
  },
  lightGray: {
    buttonColorScheme: 'orange',
    textColor: 'gray.20',
    withDottedBG: false,
    bg: 'gray.90'
  }
};
export const PageBodyCallToAction = _ref => {
  let {
    buttonText,
    buttonURL,
    buttonTarget,
    colorScheme = 'darkTeal',
    imageURL,
    imageAlt,
    imageFluid,
    text
  } = _ref,
      props = _objectWithoutProperties(_ref, ["buttonText", "buttonURL", "buttonTarget", "colorScheme", "imageURL", "imageAlt", "imageFluid", "text"]);

  const colors = colorSchemes[colorScheme];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: colors.bg,
    pyScale: "xl",
    maxWidth: "xlarge",
    overflow: "hidden",
    position: "relative"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, '1fr 1fr'],
    alignItems: "center",
    justifyItems: [null, null, 'center']
  }, (imageURL || imageFluid) && React.createElement(ImageContainer, {
    mbScale: ['t', 's', 0],
    mrScale: [0, null, 'm'],
    justifySelf: "stretch",
    bg: "gray.20"
  }, React.createElement(AspectRatio, {
    minHeight: "100%",
    x: 16,
    y: 8
  }, React.createElement(Image, {
    src: imageURL,
    alt: imageAlt,
    fluid: imageFluid
  }))), React.createElement(Box, null, text && React.createElement(Heading, {
    as: "h3",
    color: colors.textColor,
    fontSizeScale: "xl",
    mbScale: ['xl', null, 's'],
    maxWidth: [null, null, '18ch'],
    fontWeight: "semibold"
  }, text), buttonURL && React.createElement(Button, {
    as: Anchor,
    href: buttonURL,
    target: buttonTarget,
    colorScheme: colors.buttonColorScheme,
    fontSizeScale: "b",
    justifySelf: "start"
  }, buttonText))), colors.withDottedBG && React.createElement(SVG, {
    position: "absolute",
    x: 628,
    y: 496,
    display: ['none', null, null, null, 'block'],
    top: 0,
    bottom: 0,
    right: "-10%",
    width: "45rem",
    svg: DottedLineBGSVG
  }));
};

PageBodyCallToAction.mapDataToProps = ({
  data,
  context,
  previousType,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$primary2, _data$primary2$button, _data$primary3, _data$primary3$button, _data$primary4, _data$primary5, _data$primary5$image, _data$primary6, _data$primary7, _data$primary7$text, _data$primary8, _data$primary8$image;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    buttonText: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.button_text,
    buttonURL: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$button = _data$primary2.button_link) === null || _data$primary2$button === void 0 ? void 0 : _data$primary2$button.url,
    buttonTarget: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$button = _data$primary3.button_link) === null || _data$primary3$button === void 0 ? void 0 : _data$primary3$button.target,
    colorScheme: notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.color_scheme)),
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$image = _data$primary5.image) === null || _data$primary5$image === void 0 ? void 0 : _data$primary5$image.url,
    imageAlt: data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : _data$primary6.image.alt,
    text: data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : (_data$primary7$text = _data$primary7.text) === null || _data$primary7$text === void 0 ? void 0 : _data$primary7$text.text,
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary8 = data.primary) === null || _data$primary8 === void 0 ? void 0 : (_data$primary8$image = _data$primary8.image) === null || _data$primary8$image === void 0 ? void 0 : _data$primary8$image.fluid
  };
};

PageBodyCallToAction.mapDataToContext = ({
  data
}) => {
  var _data$primary9;

  const colorScheme = camelCase(data === null || data === void 0 ? void 0 : (_data$primary9 = data.primary) === null || _data$primary9 === void 0 ? void 0 : _data$primary9.color_scheme);
  const colors = colorSchemes[colorScheme];
  return {
    backgroundColor: colors.bg,
    headerLogoColor: 'white',
    headerBg: 'teal.75'
  };
};

export const fragment = "2621128237";