import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty, metadataBasename, getPrimaryMetadataType } from 'helpers';
import { compose, startCase } from 'tiny-compose-fns';
import { getPrimaryMetadata, propPairsEq } from 'src/helpers';
import { Box, Flex } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { PhotoCard } from 'src/components/PhotoCard';
import { SimplePhotoCard } from 'src/components/SimplePhotoCard';
export const PageBodySmallExperienceCards = _ref => {
  let {
    heading,
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    color: "gray.20"
  }, props), heading && React.createElement(Heading, {
    fontSizeScale: "xl",
    textAlign: [null, 'center'],
    mbScale: "l"
  }, heading), React.createElement(Flex, {
    as: "ul",
    flexWrap: "wrap",
    mrScale: [0, '-m', null, '-s'],
    mbScale: [0, '-m'],
    borderTop: ['1px solid', 0],
    borderColor: "gray.90"
  }, React.Children.map(children, child => React.createElement(Box, {
    as: "li",
    borderBottom: ['1px solid', 0],
    borderColor: "gray.90",
    pyScale: ['m', 0],
    prScale: [0, 'm', null, 's'],
    mbScale: [0, 'm'],
    width: [1, 1 / 2, null, 1 / 3]
  }, React.cloneElement(child, {
    height: '100%'
  })))));
};

PageBodySmallExperienceCards.ExperienceCard = _ref2 => {
  let {
    href,
    type,
    title,
    description,
    dates,
    imageURL,
    imageFluid,
    imageAlt
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["href", "type", "title", "description", "dates", "imageURL", "imageFluid", "imageAlt"]);

  return React.createElement(Box, props, React.createElement(SimplePhotoCard, {
    href: href,
    subheading: type,
    heading: title,
    description: description,
    detail: dates,
    imageURL: imageURL,
    imageFluid: imageFluid,
    imageAlt: imageAlt,
    display: ['flex', 'none']
  }), React.createElement(PhotoCard, {
    href: href,
    subheading: type,
    heading: title,
    description: description,
    detail: dates,
    imageURL: imageURL,
    imageFluid: imageFluid,
    imageAlt: imageAlt,
    cardPosition: "bottomLeftYOverhang",
    display: ['none', 'flex']
  }));
};

PageBodySmallExperienceCards.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$primary$heading, _data$items;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    heading: notEmpty(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$heading = _data$primary.heading) === null || _data$primary$heading === void 0 ? void 0 : _data$primary$heading.text),
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.filter(x => {
      var _x$experience;

      return x === null || x === void 0 ? void 0 : (_x$experience = x.experience) === null || _x$experience === void 0 ? void 0 : _x$experience.document;
    }).map(item => {
      var _item$experience, _metadata$primary, _metadata$primary2, _doc$data, _doc$data$title, _metadata$primary3, _metadata$primary4, _metadata$primary4$fe, _metadata$primary5, _metadata$primary5$fe, _metadata$primary6, _metadata$primary6$fe;

      const doc = item === null || item === void 0 ? void 0 : (_item$experience = item.experience) === null || _item$experience === void 0 ? void 0 : _item$experience.document;
      const metadata = getPrimaryMetadata(doc);
      const type = compose(startCase, metadataBasename, getPrimaryMetadataType)(doc);
      const dates = [metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : _metadata$primary.start_date, metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.end_date].filter(Boolean).join(' - ');
      return React.createElement(PageBodySmallExperienceCards.ExperienceCard, {
        key: doc === null || doc === void 0 ? void 0 : doc.uid,
        href: doc === null || doc === void 0 ? void 0 : doc.url,
        type: type,
        title: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : (_doc$data$title = _doc$data.title) === null || _doc$data$title === void 0 ? void 0 : _doc$data$title.text,
        description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : _metadata$primary3.description,
        dates: dates,
        imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$fe = _metadata$primary4.featured_image) === null || _metadata$primary4$fe === void 0 ? void 0 : _metadata$primary4$fe.url,
        imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$fe = _metadata$primary5.featured_image) === null || _metadata$primary5$fe === void 0 ? void 0 : _metadata$primary5$fe.fluid,
        imageAlt: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$fe = _metadata$primary6.featured_image) === null || _metadata$primary6$fe === void 0 ? void 0 : _metadata$primary6$fe.alt
      });
    })
  };
};

PageBodySmallExperienceCards.mapDataToContext = () => ({
  backgroundColor: 'white'
});

export const fragment = "3406858454";