import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import staticQueryData from "../../public/static/d/2049091603.json";
import React from 'react';
import { map } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { Grid } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { StaffMemberCard } from 'src/components/StaffMemberCard';

const useAllStaffMembers = () => {
  const queryData = staticQueryData.data;
  const staffMembers = queryData.allPrismicStaffMember.nodes;
  return staffMembers.map(staffMember => {
    var _staffMember$data, _staffMember$data$fir, _staffMember$data2, _staffMember$data2$la, _staffMember$data3, _staffMember$data3$im, _staffMember$data4, _staffMember$data4$im, _staffMember$data5, _staffMember$data5$po;

    return {
      firstName: (_staffMember$data = staffMember.data) === null || _staffMember$data === void 0 ? void 0 : (_staffMember$data$fir = _staffMember$data.first_name) === null || _staffMember$data$fir === void 0 ? void 0 : _staffMember$data$fir.text,
      lastName: (_staffMember$data2 = staffMember.data) === null || _staffMember$data2 === void 0 ? void 0 : (_staffMember$data2$la = _staffMember$data2.last_name) === null || _staffMember$data2$la === void 0 ? void 0 : _staffMember$data2$la.text,
      imageFluid: (_staffMember$data3 = staffMember.data) === null || _staffMember$data3 === void 0 ? void 0 : (_staffMember$data3$im = _staffMember$data3.image) === null || _staffMember$data3$im === void 0 ? void 0 : _staffMember$data3$im.fluid,
      imageURL: (_staffMember$data4 = staffMember.data) === null || _staffMember$data4 === void 0 ? void 0 : (_staffMember$data4$im = _staffMember$data4.image) === null || _staffMember$data4$im === void 0 ? void 0 : _staffMember$data4$im.url,
      positionTitle: (_staffMember$data5 = staffMember.data) === null || _staffMember$data5 === void 0 ? void 0 : (_staffMember$data5$po = _staffMember$data5.position_title) === null || _staffMember$data5$po === void 0 ? void 0 : _staffMember$data5$po.text
    };
  });
};

export const PageBodyStaffMembers = _ref => {
  let {
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    pyScale: "xl",
    maxWidth: "90rem"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr'],
    gridGap: ['1.5rem', '2.25rem', '2.75rem']
  }, children));
};

PageBodyStaffMembers.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext,
  previousType
}) => ({
  previousIsHeader: previousType === 'PageBodyHeader',
  previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
  nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
  nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
  children: map(data => {
    return React.createElement(StaffMemberCard, {
      key: data.lastName,
      name: data.firstName + " " + data.lastName,
      imageFluid: data.imageFluid,
      imageURL: data.imageURL,
      positionTitle: data.positionTitle
    }); // Map data to props always runs within a component
    // eslint-disable-next-line react-hooks/rules-of-hooks
  }, useAllStaffMembers())
});

PageBodyStaffMembers.mapDataToContext = () => ({
  headerLogoColor: 'gray.20',
  backgroundColor: 'white'
});