import { FieldsTable, Field, LinkCard, SliceExamples } from 'reference';
import { PageBodyCallToAction } from 'src/slices/PageBodyCallToAction';
import { ProjectExample } from 'src/components/ProjectExample';
import React from 'react';
export default {
  FieldsTable,
  Field,
  LinkCard,
  SliceExamples,
  PageBodyCallToAction,
  ProjectExample,
  React
};