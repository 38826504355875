import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty, metadataBasename, getPrimaryMetadataType } from 'helpers';
import { camelCase, compose, startCase } from 'tiny-compose-fns';
import { getPrimaryMetadata, propPairsEq } from 'src/helpers';
import { Box, Flex } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { PhotoCard } from 'src/components/PhotoCard';
export const PageBodyExperienceCards = _ref => {
  let {
    heading,
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    color: "gray.20"
  }, props), heading && React.createElement(Heading, {
    fontSizeScale: "xl",
    textAlign: [null, 'center'],
    mbScale: "m"
  }, heading), React.createElement(Flex, {
    as: "ul",
    flexWrap: "wrap",
    mrScale: [0, '-l'],
    mbScale: "-l"
  }, React.Children.map(children, child => {
    const isWide = /wide$/i.test(child.props.cardPosition);
    return React.createElement(Box, {
      as: "li",
      width: [1, isWide ? 1 : 1 / 2],
      prScale: [0, 'l'],
      mbScale: "m"
    }, React.cloneElement(child, {
      height: '100%'
    }));
  })));
};

PageBodyExperienceCards.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext,
  previousType
}) => {
  var _data$items, _data$items$filter;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    heading: notEmpty(data.primary.heading.text),
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : (_data$items$filter = _data$items.filter(item => {
      var _item$item;

      return item === null || item === void 0 ? void 0 : (_item$item = item.item) === null || _item$item === void 0 ? void 0 : _item$item.document;
    })) === null || _data$items$filter === void 0 ? void 0 : _data$items$filter.map(item => {
      var _item$item2, _metadata$primary, _metadata$primary2, _doc$data, _doc$data$title, _metadata$primary3, _metadata$primary4, _metadata$primary4$fe, _metadata$primary5, _metadata$primary5$fe, _metadata$primary6, _metadata$primary6$fe;

      const doc = item === null || item === void 0 ? void 0 : (_item$item2 = item.item) === null || _item$item2 === void 0 ? void 0 : _item$item2.document;
      const metadata = getPrimaryMetadata(doc);
      const displaySize = camelCase(item === null || item === void 0 ? void 0 : item.display_size);
      const type = compose(startCase, metadataBasename, getPrimaryMetadataType)(doc);
      const overhangOptions = {
        normal: 'bottomRightXOverhang',
        large: 'bottomLeftYOverhangWide'
      };
      const cardPosition = overhangOptions[displaySize];
      const dates = [metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : _metadata$primary.start_date, metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.end_date].filter(Boolean).join(' - ');
      return React.createElement(PhotoCard, {
        key: doc === null || doc === void 0 ? void 0 : doc.uid,
        href: doc === null || doc === void 0 ? void 0 : doc.url,
        subheading: displaySize === 'large' ? "Featured " + type : type,
        heading: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : (_doc$data$title = _doc$data.title) === null || _doc$data$title === void 0 ? void 0 : _doc$data$title.text,
        description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : _metadata$primary3.description,
        detail: dates,
        imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$fe = _metadata$primary4.featured_image) === null || _metadata$primary4$fe === void 0 ? void 0 : _metadata$primary4$fe.url,
        imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$fe = _metadata$primary5.featured_image) === null || _metadata$primary5$fe === void 0 ? void 0 : _metadata$primary5$fe.fluid,
        imageAlt: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$fe = _metadata$primary6.featured_image) === null || _metadata$primary6$fe === void 0 ? void 0 : _metadata$primary6$fe.alt,
        cardPosition: cardPosition
      });
    })
  };
};

PageBodyExperienceCards.mapDataToContext = () => ({
  backgroundColor: 'white'
});

export const fragment = "1259711334";