import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { camelCase } from 'tiny-compose-fns';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Grid, Image } from 'system';
import { getRichText, notEmpty } from 'helpers';
import { propPairsEq } from 'src/helpers';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
const colorSchemes = {
  white: {
    bg: 'white'
  },
  lightGray: {
    bg: 'gray.90'
  }
};
export const PageBodyTextWithImage = _ref => {
  let {
    backgroundColor,
    textSide = 'left',
    textHTML,
    imageFluid,
    imageURL,
    imageAlt
  } = _ref,
      props = _objectWithoutProperties(_ref, ["backgroundColor", "textSide", "textHTML", "imageFluid", "imageURL", "imageAlt"]);

  const color = colorSchemes[backgroundColor];
  const textSideIsLeft = textSide === 'left';
  return React.createElement(BoundedBox, Object.assign({
    maxWidth: ARTICLE_MAX_WIDTH,
    as: "section",
    bg: color.bg
  }, props), React.createElement(Grid, {
    gridGapScale: "m",
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)']
  }, React.createElement(HTMLContent, {
    html: textHTML,
    gridColumn: ['1 / -1', (textSideIsLeft ? 1 : 2) + " / span 1"],
    gridRow: [null, '1']
  }), (imageFluid || imageURL) && React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt,
    gridColumn: ['1 / -1', (textSideIsLeft ? 2 : 1) + " / span 1"],
    gridRow: [null, '1'],
    height: "auto"
  })));
};

PageBodyTextWithImage.mapDataToProps = ({
  previousContext,
  nextContext,
  context,
  previousType,
  data
}) => {
  var _data$primary, _data$primary2, _data$primary3, _data$primary4, _data$primary4$image, _data$primary5, _data$primary5$image, _data$primary6, _data$primary6$image, _data$primary7;

  return {
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    previousIsHeader: previousType === 'PageBodyHeader',
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    backgroundColor: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.background_color),
    textSide: camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.text_side),
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.text),
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$image = _data$primary4.image) === null || _data$primary4$image === void 0 ? void 0 : _data$primary4$image.fluid,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : (_data$primary5$image = _data$primary5.image) === null || _data$primary5$image === void 0 ? void 0 : _data$primary5$image.url,
    imageAlt: data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : (_data$primary6$image = _data$primary6.image) === null || _data$primary6$image === void 0 ? void 0 : _data$primary6$image.alt,
    leftColumnText: getRichText(data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : _data$primary7.left_column_text)
  };
};

PageBodyTextWithImage.mapDataToContext = ({
  data
}) => {
  var _data$primary8;

  const backgroundColor = notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary8 = data.primary) === null || _data$primary8 === void 0 ? void 0 : _data$primary8.background_color));
  const colors = colorSchemes[backgroundColor];
  return {
    backgroundColor: colors.bg,
    isArticleType: true,
    headerLogoColor: 'white',
    headerBg: 'teal.75'
  };
};

export const fragment = "4043311889";