import { getAllMetadata, flatten } from 'helpers'; // Ensures value is an array.

export const castArray = x => Array.isArray(x) ? x : [x]; // Returns the first metadata for a Prismic node.

export const getPrimaryMetadata = node => getAllMetadata(node)[0]; // Returns the first non-null value in an array given an index searching
// downward. Returns undefined if no non-null value can be found.
//
// e.g. firstLeft(2, ['foo', 'bar', null, 'baz'])
//      => 'bar'

export const firstLeft = (idx, arr) => {
  arr = castArray(arr);
  if (idx >= arr.length) idx = arr.length - 1;

  while (idx > -1 && (arr[idx] === null || arr[idx] === undefined)) idx--;

  return arr[idx];
};

const zipWith = (fn, a, b) => {
  const result = [];
  const length = Math.max(a.length, b.length);

  for (let i = 0; i < length; i++) result[i] = fn(firstLeft(i, a), firstLeft(i, b));

  return result;
};

const pairsEq = (a, b) => zipWith((a, b) => a === b, castArray(a), castArray(b));

export const propPairsEq = (key, a, b) => pairsEq(a === null || a === void 0 ? void 0 : a[key], b === null || b === void 0 ? void 0 : b[key]); // Converts props into arrays suitable for styled-system styling props.

export const responsiveProp = (fn = x => x, prop = []) => {
  prop = Array.isArray(prop) ? prop : [prop];
  return prop.map(fn);
}; // Returns true if the props of two objects are equal, false otherwise.

export const propsEq = (key, a, b) => a[key] === b[key];
export const nodesPathFor = node => {
  var _node$data, _node$data$parent;

  const parent = node === null || node === void 0 ? void 0 : (_node$data = node.data) === null || _node$data === void 0 ? void 0 : (_node$data$parent = _node$data.parent) === null || _node$data$parent === void 0 ? void 0 : _node$data$parent.document;
  if (parent) return flatten([nodesPathFor(parent), node]);
  return [node];
};