import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { Box, SVG, Flex } from 'system';
import { ReactComponent as AssetIconChevronLeft } from 'src/assets/icon-chevron-left.svg';
import { ReactComponent as AssetIconChevronRight } from 'src/assets/icon-chevron-right.svg';
const sizes = {
  normal: {
    width: linearScale('1.25rem', '2rem', {
      count: 5
    })
  },
  large: {
    width: linearScale('1.75rem', '2.75rem', {
      count: 5
    })
  }
};
export const ArrowCircle = _ref => {
  var _sizes$size;

  let {
    direction = 'left',
    size = 'normal'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["direction", "size"]);

  const width = sizes === null || sizes === void 0 ? void 0 : (_sizes$size = sizes[size]) === null || _sizes$size === void 0 ? void 0 : _sizes$size.width;
  return React.createElement(Box, Object.assign({
    borderRadius: "50%",
    bg: "currentColor",
    width: width,
    height: width,
    transitionProperty: "background-color"
  }, props), React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  }, React.createElement(SVG, {
    svg: direction === 'left' ? AssetIconChevronLeft : AssetIconChevronRight,
    x: 15,
    y: 20,
    color: "white",
    width: "40%",
    flex: "0 0 auto",
    transform: direction === 'left' ? 'translateX(-10%)' : 'translateX(10%)'
  })));
};